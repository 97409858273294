import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
  },
  category: {
    flex: 1,
    overflowY: 'auto',
  },
}));

export default styles;
