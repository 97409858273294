import {
  FAQ,
  FAQRevamp,
  GetFaqByCategoryIdRequest,
  GetFaqByIdRequest,
} from 'types/faq';
import { apiRoutesEnum } from './../enums/routes';
import { AXIOS_REVAMP } from './axios';

async function GetFaqsByCategory(
  params: GetFaqByCategoryIdRequest,
): Promise<FAQRevamp> {
  const pagesUrl = window.location.pathname.split('/');

  return AXIOS_REVAMP.get(`${apiRoutesEnum.ARTICLES_REVAMP}`, {
    params: {
      categoryId: params.categoryId,
      pageUrl: pagesUrl[1],
    },
  });
}

async function GetArticleDetail(
  params: GetFaqByIdRequest,
): Promise<{ data: FAQ }> {
  const pagesUrl = window.location.pathname.split('/');
  return AXIOS_REVAMP.get(`${apiRoutesEnum.GET_ARTICLE_DETAIL}`, {
    params: {
      articleId: params.articleId,
      pageUrl: pagesUrl[1],
    },
  });
}

export default {
  GetFaqsByCategory,
  GetArticleDetail,
};
