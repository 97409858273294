import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: 5,
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '60px',
  },
  root: {
    height: 30,
    position: 'static',
    width: '100%',
    border: '1px solid rgba(34, 36, 38, 0.15)',
    backgroundColor: 'white',
    '& .MuiSelect-root': {
      padding: 0,
      boxSizing: 'border-box',
      paddingLeft: 10,
      display: 'flex',
      fontSize: 11,
      color: theme.colors.darkGray,
      height: '100%',
      alignItems: 'center',
    },
    '& fieldset': {
      border: 'none',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      // backgroundColor: `${theme.colors.inputBackground}`,
    },
  },
  option: {
    fontSize: 11,
  },
  error: {
    background: '#fff6f6',
    border: '1px solid #f44336',
  },
}));

export default styles;
