import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import styles from './styles';
import { FaqCategory } from 'types/category';

interface IProps {
  categories: FaqCategory[];
}

const CategoryList = ({ categories }: IProps) => {
  const classes = styles();
  const match = useRouteMatch<{ enterprise: string }>();

  return (
    <div className={classes.sidebar}>
      <div className="pl5">
        {categories
          ?.sort((a, b) => a.position - b.position)
          ?.map((category) => {
            return (
              <NavLink
                key={category.categoryId}
                to={`/${match.params.enterprise}/faq/${category.categoryId}`}
                activeClassName={classes.activeStyle}
                className={classes.navlink}
              >
                {category.categoryName}
              </NavLink>
            );
          })}
      </div>
    </div>
  );
};

export default CategoryList;
