import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import IconButton from 'components/IconButton';
import CloseIcon from 'icons/CloseIcon';
import clsx from 'clsx';
import useStyles from './styles';

interface IProps {
  closeShouldAdd: () => void;
}

const AddCaseHeader = ({ closeShouldAdd }: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={clsx('space-between', classes.header)}>
      <Typography className={classes.title}>Form</Typography>
      {!matches && <IconButton onClick={closeShouldAdd} Icon={<CloseIcon />} />}
    </Box>
  );
};

export default AddCaseHeader;
