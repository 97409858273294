import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles';
import clsx from 'clsx';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children?: React.ReactNode;
}

const CommonModal = ({ isOpen, onClose, children, className }: ModalProps) => {
  const classes = styles();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <>
      {ReactDOM.createPortal(
        <Box className={classes.modalOverlay} onClick={onClose}></Box>,
        document.getElementById('backdrop-root') as HTMLElement,
      )}
      {ReactDOM.createPortal(
        <Box
          className={clsx(classes.modalContent, className)}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </Box>,
        document.getElementById('overlay-root') as HTMLElement,
      )}
    </>
  );
};

export default CommonModal;
