import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { getSnackbarState } from 'store/selectors';
import { DispatchType } from 'types/store';
import { updateAppStateAction } from 'store/actions/appActions';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomizedSnackbars() {
  const snackbarState = useSelector(getSnackbarState);
  const dispatch: DispatchType = useDispatch();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(
      updateAppStateAction({
        snackbar: {
          open: false,
          message: snackbarState.message,
          severity: snackbarState.severity,
        },
      }),
    );
  };

  return (
    <Snackbar
      open={snackbarState.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={snackbarState.severity}>
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
}
