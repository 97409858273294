import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface IProps {
  className?: string;
  onClick?: () => void;
  label: string;
  disableRipple?: boolean;
  disabled?: boolean;
}

const SecondaryTextButton = ({
  className,
  onClick,
  disableRipple,
  disabled,
  label,
}: IProps) => {
  const classes = useStyles();
  return (
    <Button
      className={clsx(classes.root, className)}
      onClick={onClick}
      disableRipple={disableRipple}
      disabled={disabled}
      classes={{
        disabled: classes.disabled,
      }}
    >
      {label}
    </Button>
  );
};

export default SecondaryTextButton;
const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 11,
    backgroundColor: theme.colors.pureWhite,
    color: theme.colors.black1,
    padding: '4px 16px',
    borderRadius: 5,
    border: `1px solid ${theme.colors.black1}`,
    '&:hover': {
      backgroundColor: theme.colors.white1,
      color: theme.colors.whiteText,
    },
  },
  disabled: {
    backgroundColor: theme.colors.pureWhite,
    color: theme.colors.gray,
    border: `1px solid ${theme.colors.gray}`,
  },
}));
