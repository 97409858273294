import { Box, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React, { MouseEventHandler, useRef } from 'react';
import useStyles from './styles';
import parse from 'html-react-parser';
import { Case } from 'types/cases';

interface IProps {
  item: Case;
  onClick: (item: Case) => MouseEventHandler<HTMLElement> | void;
  openDetail: boolean;
}

const CaseItem = ({ item, onClick, openDetail }: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const ref = useRef<any>();

  return (
    <Box
      // border-bottom
      className={classes.container}
      onClick={() => onClick(item)}
      style={{ cursor: 'pointer' }}
    >
      <Typography
        style={{ overflow: 'hidden' }}
        className="font-weight-600"
        variant="subtitle1"
      >
        {parse(item.description)}
      </Typography>
      <Box className="space-between mt2">
        <Box width="50%">
          <div className={classes.field} ref={ref}>
            <Typography className={classes.typography} variant="body2">
              Created
            </Typography>
            <Typography
              className={clsx('ml2 ', classes.typography)}
              variant="h4"
            >
              {new Date(item.createdAt).toDateString()}
            </Typography>
          </div>
          <Box className={classes.field}>
            <Typography className={classes.typography} variant="body2">
              Last message
            </Typography>
            <Typography
              className={clsx('ml2 ', classes.typography)}
              variant="h4"
            >
              {new Date(item.lastTimelineAt).toDateString()}
            </Typography>
          </Box>
        </Box>
        <Box width="50%">
          <Box className={classes.field}>
            <Typography className={classes.typography} variant="body2">
              Stage
            </Typography>
            <Typography
              className={clsx('ml2 ', classes.typography)}
              variant="h4"
            >
              {item.workFollowId}
            </Typography>
          </Box>
          <Box className={classes.field}>
            <Typography className={classes.typography} variant="body2">
              Next action
            </Typography>
            <Typography
              className={clsx('ml2 ', classes.typography)}
              variant="h4"
            >
              {item.priority ?? 'Last message'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseItem;
