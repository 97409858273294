import { MenuActionTypes } from './../../types/menu';
import { Menu } from 'types/menu';
import { MenuStateEnum } from 'enums/actions';

export const initialMenuState: Menu = {
  uuid: '',
  faq: '',
  myCase: '',
  myInformation: '',
  pageName: '',
  pageUrl: '',
  supportedLanguageList: [],
};

export const menuReducer = (
  state = initialMenuState,
  action: MenuActionTypes,
) => {
  switch (action.type) {
    case MenuStateEnum.GET_MENU:
      return {
        ...state,
        uuid: action.payload.uuid,
        pageName: action.payload.pageName,
        myInformation: action.payload.myInformation,
        pageUrl: action.payload.pageUrl,
        myCase: action.payload.myCase,
        faq: action.payload.faq,
        supportedLanguageList: action.payload.supportedLanguageList,
      };
    default:
      return state;
  }
};
