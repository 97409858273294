import React from 'react';

const DropdownIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1317_1014)">
        <mask
          id="mask0_1317_1014"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path d="M20 0H0V20H20V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1317_1014)">
          <path
            d="M6.175 7.15845L10 10.9751L13.825 7.15845L15 8.33346L10 13.3335L5 8.33346L6.175 7.15845Z"
            fill="#222426"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1317_1014">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DropdownIcon;
