import React from 'react';

const AddIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.82031 6.68555H11.1803"
        stroke="white"
        strokeWidth="1.67143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 11.3284V2.04272"
        stroke="white"
        strokeWidth="1.67143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddIcon;
