import React from 'react';

interface IProps {
  className?: string;
}

const LockIcon = ({ className }: IProps) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.33332V6.66666C5 3.90832 5.83333 1.66666 10 1.66666C14.1667 1.66666 15 3.90832 15 6.66666V8.33332"
        stroke="#ff7b00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99984 15.4167C11.1504 15.4167 12.0832 14.4839 12.0832 13.3333C12.0832 12.1827 11.1504 11.25 9.99984 11.25C8.84924 11.25 7.9165 12.1827 7.9165 13.3333C7.9165 14.4839 8.84924 15.4167 9.99984 15.4167Z"
        stroke="#ff7b00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1665 18.3333H5.83317C2.49984 18.3333 1.6665 17.5 1.6665 14.1667V12.5C1.6665 9.16668 2.49984 8.33334 5.83317 8.33334H14.1665C17.4998 8.33334 18.3332 9.16668 18.3332 12.5V14.1667C18.3332 17.5 17.4998 18.3333 14.1665 18.3333Z"
        stroke="#ff7b00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockIcon;
