import React from 'react';
import { Typography } from '@material-ui/core';

const RequiredAsterisk = () => {
  return (
    <Typography
      style={{
        margin: '0px 4px',
        color: 'red',
        fontWeight: 700,
      }}
    >
      *
    </Typography>
  );
};

export default RequiredAsterisk;
