import { CaseActionTypeEnum } from 'enums/actions';
import { CaseReducerState, CasesActionTypes } from 'types/cases';

export const initialCasesState: CaseReducerState = {
  ticketDTOList: {
    data: [],
    page: 0,
    size: 10,
    total: 0,
  },
  fields: [],
  forms: [],
  selectedForm: '',
  messages: [],
  documents: [],
  photos: [],
  timeline: [],
};

export const caseReducer = (
  state = initialCasesState,
  action: CasesActionTypes,
) => {
  switch (action.type) {
    case CaseActionTypeEnum.GET_ALL_CASES: {
      const { data, nextKey } = action.payload;
      let newData = data;
      const total = state.ticketDTOList.total;
      if (total === 0) {
        newData = [];
      } else if (total > 0) {
        if (nextKey === null && state.ticketDTOList.data.length === total) {
          newData = data;
        } else {
          newData = [...state.ticketDTOList.data, ...data];
        }
      }
      return {
        ...state,
        ticketDTOList: {
          ...state.ticketDTOList,
          data: newData,
          nextKey: nextKey,
        },
      };
    }
    case CaseActionTypeEnum.GET_CASES_TOTAL_NUMBER:
      return {
        ...state,
        ticketDTOList: {
          ...state.ticketDTOList,
          total: action.payload,
        },
      };
    case CaseActionTypeEnum.GET_CASE_DETAIL_BY_ID:
      return {
        ...state,
        selectedCase: action.payload,
      };
    case CaseActionTypeEnum.GET_PHOTOS_BY_ID:
      return {
        ...state,
        photos: action.payload,
      };
    case CaseActionTypeEnum.GET_DOCUMENTS_BY_ID:
      return {
        ...state,
        documents: action.payload,
      };
    case CaseActionTypeEnum.GET_MESSAGES_BY_ID:
      return {
        ...state,
        messages: action.payload,
      };
    case CaseActionTypeEnum.GET_LIST_FIELD_REVAMP:
      return {
        ...state,
        fields: action.payload,
      };
    case CaseActionTypeEnum.ADD_NEW_CASE:
      state.ticketDTOList.data.unshift(action.payload);
      return {
        ...state,
        ticketDTOList: {
          ...state.ticketDTOList,
          data: [...state.ticketDTOList.data],
        },
        newCreatedCaseId: action.payload.id,
      };
    case CaseActionTypeEnum.GET_LIST_FORM:
      return {
        ...state,
        forms: action.payload,
      };
    case CaseActionTypeEnum.SELECT_FORM:
      return {
        ...state,
        selectedForm: action.payload,
      };
    case CaseActionTypeEnum.POST_MESSAGE: {
      const newState = { ...state };
      newState.messages?.unshift(action.payload);
      return {
        ...state,
        messages: {
          ...newState.messages,
          countRow: newState.messages.length + 1,
        },
      };
    }
    case CaseActionTypeEnum.POST_DOCUMENT: {
      const newState = { ...state };
      newState.documents.push({
        ...action.payload,
        url: action.payload.fileUrl,
      });
      return {
        ...state,
        documents: [...newState.documents],
      };
    }
    case CaseActionTypeEnum.POST_PHOTO: {
      const newState = { ...state };
      newState.photos.push({ ...action.payload, url: action.payload.fileUrl });
      return {
        ...state,
        photos: [...newState.photos],
      };
    }
    case CaseActionTypeEnum.GET_TIMELINE_REVAMP:
      return {
        ...state,
        timeline: action.payload,
      };
    case CaseActionTypeEnum.SAVE_DOCUMENT: {
      return {
        ...state,
        documentFile: action.payload,
      };
    }
    case CaseActionTypeEnum.SAVE_PHOTO: {
      return {
        ...state,
        photoFile: action.payload,
      };
    }
    case CaseActionTypeEnum.CLEAN_UP_CASE_DATA: {
      return {
        ...state,
        ticketDTOList: {
          ...state.ticketDTOList,
          data: [],
          total: 0,
        },
      };
    }
    default:
      return state;
  }
};
