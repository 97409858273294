import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Box, Typography } from '@material-ui/core';
import colors from 'material/colors';

interface IProps {
  accept?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  name?: string;
  className?: string;
  style?: any;
  Icon?: any;
  id?: string;
}

const FileInput = ({
  accept,
  onChange,
  label,
  name,
  className,
  style,
  Icon,
  id,
}: IProps) => {
  return (
    <label
      className={className}
      style={{
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
    >
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={onChange}
        accept={accept}
        name={name}
        id={id}
      />
      <Box
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!Icon && (
          <AddIcon
            style={{
              fontSize: 13,
              color: colors.black1,
              fontWeight: 700,
            }}
          />
        )}
        <Typography
          style={{
            marginLeft: 4,
            color: colors.black1,
            fontSize: 13,
            marginRight: 12,
          }}
        >
          {label}
        </Typography>
        {Icon}
      </Box>
    </label>
  );
};

export default FileInput;
