import React from 'react';
const GalleryIcon = () => {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0177 24.988H16.6963C22.2617 24.988 24.4879 22.7618 24.4879 17.1964V10.5178C24.4879 4.95236 22.2617 2.72618 16.6963 2.72618H10.0177C4.45226 2.72618 2.22607 4.95236 2.22607 10.5178V17.1964C2.22607 22.7618 4.45226 24.988 10.0177 24.988Z"
        stroke="#292D32"
        strokeWidth="1.66964"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0179 11.6309C11.2474 11.6309 12.2441 10.6342 12.2441 9.40473C12.2441 8.17525 11.2474 7.17855 10.0179 7.17855C8.78844 7.17855 7.79175 8.17525 7.79175 9.40473C7.79175 10.6342 8.78844 11.6309 10.0179 11.6309Z"
        stroke="#292D32"
        strokeWidth="1.66964"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.97192 21.5931L8.45947 17.9088C9.33881 17.3188 10.6077 17.3856 11.398 18.0646L11.7653 18.3874C12.6336 19.1332 14.0361 19.1332 14.9043 18.3874L19.5347 14.4136C20.4029 13.6679 21.8054 13.6679 22.6737 14.4136L24.488 15.972"
        stroke="#292D32"
        strokeWidth="1.66964"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default GalleryIcon;
