import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ArrowTop from 'icons/ArrowTopIcon';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { getFaqsState } from 'store/selectors';
import { FaqCategory } from 'types/category';
import FaqMobile from '../Faq/FaqMobile';
import styles from './styles';

interface IProps {
  category: FaqCategory;
  shouldShowDetail: boolean;
}

const CategoryItem = ({ category, shouldShowDetail }: IProps) => {
  const classes = styles();
  const faqs = useSelector(getFaqsState);
  const { enterprise } = useParams<any>();

  return (
    <Box>
      <NavLink
        key={category.categoryId}
        to={
          shouldShowDetail
            ? `/${enterprise}/faq`
            : `/${enterprise}/faq/${category.categoryId}`
        }
        activeClassName={clsx({ [classes.activeStyle]: shouldShowDetail })}
        className={classes.navlinkMobile}
      >
        <Typography className={classes.labelMobile}>
          {category.categoryName}
        </Typography>
        <ArrowTop />
      </NavLink>
      {shouldShowDetail &&
        faqs[0]?.categoryId === category.categoryId &&
        faqs
          ?.sort((a, b) => a.position - b.position)
          .map((f) => <FaqMobile key={f.articleId} faq={f} />)}
    </Box>
  );
};

export default CategoryItem;
