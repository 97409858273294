export enum TimelineEnum {
  CONTACT_EMAIL_RECEIVER = 'CONTACT_EMAIL_RECEIVER',
  CONTACT_EMAIL_SENT = 'CONTACT_EMAIL_SENT',
  USER_EMAIL_RECEIVER = 'USER_EMAIL_RECEIVER',
  USER_EMAIL_SENT = 'USER_EMAIL_SENT',
  MASS_EMAILS = 'MASS_EMAILS',
  CASE_ADDED = 'CASE_ADDED',
  CASE_MOVED_TO_PROCESS = 'CASE_MOVED_TO_PROCESS',
  CASE_MOVED_IN_PROCESS = 'CASE_MOVED_IN_PROCESS',
  NOTE_ADDED = 'NOTE_ADDED',
  MEETING_ADDED = 'MEETING_ADDED',
  MEETING_DONE = 'MEETING_DONE',
  USER_DOCUMENT_ADDED = 'USER_DOCUMENT_ADDED',
  CUSTOMER_DOCUMENT_ADDED = 'CUSTOMER_DOCUMENT_ADDED',
  MERGE_TICKET = 'MERGE_TICKET',
  MERGE_CASE = 'MERGE_CASE',
  TICKET_ADDED = 'TICKET_ADDED',
  USER_PHOTO_ADDED = 'USER_PHOTO_ADDED',
  CUSTOMER_PHOTO_ADDED = 'CUSTOMER_PHOTO_ADDED',
}
