import MessageQuestion from 'icons/MessageQuestion';
import RowVertical from 'icons/RowVertical';
import UserEditIcon from 'icons/UserEditIcon';

const notLogginLinks = [
  {
    logo: MessageQuestion,
    label: 'Submit a ticket',
    path: '/submit-ticket',
    exact: true,
    key: '',
  },
  {
    logo: RowVertical,
    label: 'FAQ',
    path: '/faq',
    exact: false,
    key: 'faq',
  },
];

const logginLinks = [
  {
    logo: UserEditIcon,
    label: 'My information',
    path: '/my-information',
    exact: true,
    key: 'myInformation',
  },
  {
    logo: MessageQuestion,
    label: 'My cases',
    path: '/',
    exact: true,
    key: 'myCase',
  },
  {
    logo: RowVertical,
    label: 'FAQ',
    path: '/faq',
    exact: false,
    key: 'faq',
  },
];

export { notLogginLinks, logginLinks };
