import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  header: {
    height: '90px',
    display: 'flex',
    borderBottom: '1px solid #f1f1f1',
    paddingLeft: 24,
    alignItems: 'end',
  },
  navlink_div: {
    height: 'fit-content',
  },
  textButton: {
    '&:hover': {
      backgroundColor: theme.colors.gray3,
    },
  },
}));
