import { RootStateType as IStore } from '../../types/store';

// AppReducer
export const sTaskStatus = (key: string) => (store: IStore) =>
  store.asyncTaskReducer.status[key];

export const getAppState = (store: IStore) => store.appStateReducer;
export const authenticated = (store: IStore) => store.loginReducer.status;
export const getCategoryState = (store: IStore) => store.categoryReducer;
export const getFaqsState = (store: IStore) => store.faqReducer.faqs;

export const getLogoState = (store: IStore) => store.logoReducer.file;
export const getMenuState = (store: IStore) => store.menuReducer;
export const getUserState = (store: IStore) => store.loginReducer.fields;
export const getPasswordState = (store: IStore) => store.loginReducer.password;
export const getAvatarState = (store: IStore) => store.loginReducer.avatar;

// CaseOpenReducer
export const getCaseOpenFormState = (store: IStore) =>
  store.caseOpenReducer.shouldAddForm;
export const getCaseOpenDetailState = (store: IStore) =>
  store.caseOpenReducer.shouldOpenDetail;
export const getDetailFaq = (store: IStore) => store.faqReducer.selectedFaq;
export const getUserLanguageState = (store: IStore) =>
  store.loginReducer.language;
export const getLanguagesState = (store: IStore) => store.languageReducer;

// CaseReducer
export const getAllCases = (store: IStore) =>
  store.caseReducer.ticketDTOList.data;
export const getCasesTotal = (store: IStore) =>
  store.caseReducer.ticketDTOList.total;
export const getCaseDetail = (store: IStore) => store.caseReducer.selectedCase;
export const getMessages = (store: IStore) => store.caseReducer.messages;
export const getDocuments = (store: IStore) => store.caseReducer.documents;
export const getPhotos = (store: IStore) => store.caseReducer.photos;
export const getListFieldState = (store: IStore) => store.caseReducer.fields;
export const getListFormState = (store: IStore) => store.caseReducer.forms;
export const getSelectedFormState = (store: IStore) =>
  store.caseReducer.selectedForm;
export const getTimeline = (store: IStore) => store.caseReducer.timeline;
export const getSnackbarState = (store: IStore) =>
  store.appStateReducer.snackbar;
export const getCasePage = (store: IStore) =>
  store.caseReducer.ticketDTOList.page;
export const getCaseSize = (store: IStore) =>
  store.caseReducer.ticketDTOList.size;
export const getNextKey = (store: IStore) =>
  store.caseReducer.ticketDTOList.nextKey;

// faqReducer
export const getArticleDetail = (store: IStore) =>
  store.faqReducer.selectedArticle;
