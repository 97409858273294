import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { NavLink, Route, useParams } from 'react-router-dom';
import CaseItem from '../CaseItem';
import useStyles from './styles';
import AddCase from '../AddCase';
import CaseDetail from '../CaseDetail';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCases,
  getCaseOpenDetailState,
  getCasePage,
  getCaseSize,
  getCasesTotal,
  getNextKey,
  sTaskStatus,
} from 'store/selectors';
import { closeAddForm, closeDetail } from 'store/actions/caseOpenAction';
import ListCaseHeader from './ListCaseHeader';
import { cleanupCaseList, getCases } from 'store/actions/caseActions';
import { DispatchType } from 'types/store';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingComponent from 'components/LoadingComponent';
import { CaseActionTypeEnum } from 'enums/actions';

interface IProps {
  setOpenDetail: () => void;
  closeShouldAdd: () => void;
  setShouldAdd: () => void;
  shouldAdd: boolean;
  shouldDetail: boolean;
}

const ListCase = ({
  setOpenDetail,
  closeShouldAdd,
  shouldAdd,
  setShouldAdd,
  shouldDetail,
}: IProps) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const shouldOpenDetail = useSelector(getCaseOpenDetailState);
  const dispatch: DispatchType = useDispatch();
  const cases = useSelector(getAllCases);
  const page = useSelector(getCasePage);
  const size = useSelector(getCaseSize);
  const total = useSelector(getCasesTotal);
  const nextKey = useSelector(getNextKey);
  const status = useSelector(sTaskStatus(CaseActionTypeEnum.GET_ALL_CASES));

  const onClickHandler = (param: any) => {
    setOpenDetail();
  };
  const { enterprise } = useParams<any>();

  useEffect(() => {
    setLoading(true);
    dispatch(cleanupCaseList());
    dispatch(getCases(isActive))
      .then(() => setLoading(false))
      .catch((error) => {
        setLoading(false);
        throw new Error('Cannot fetch data');
      });
  }, [enterprise, isActive]);

  const handleFetchingMore = () => {
    dispatch(getCases(isActive, nextKey));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanupCaseList());
    };
  }, []);

  return (
    <Box
      className="flex1"
      style={{
        overflow: 'hidden',
      }}
    >
      <Box
        className={
          (shouldAdd || shouldOpenDetail) && matches ? classes.displayNone : ''
        }
      >
        <ListCaseHeader
          setShouldAdd={setShouldAdd}
          isActive={isActive}
          setIsActive={setIsActive}
        />
      </Box>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Box className={classes.wrapper}>
          <Box
            className={
              (shouldAdd || shouldDetail) && matches
                ? classes.displayNone
                : classes.caseList
            }
            id="scrollableDiv"
          >
            <InfiniteScroll
              dataLength={cases.length}
              next={handleFetchingMore}
              hasMore={!!nextKey}
              loader={<LoadingComponent />}
              endMessage={
                status?.processing ? (
                  <LoadingComponent />
                ) : (
                  cases.length == 0 && (
                    <p style={{ textAlign: 'center', fontSize: 12 }}>
                      <b>No tickets found</b>
                    </p>
                  )
                )
              }
              scrollableTarget="scrollableDiv"
            >
              {cases.map((c) => (
                <NavLink
                  key={c.id}
                  to={`/${enterprise}?case-id=${c.id}`}
                  activeClassName={classes.activeStyle}
                  className={classes.item}
                >
                  <CaseItem
                    openDetail={shouldOpenDetail || shouldAdd}
                    key={c.id}
                    item={c}
                    onClick={onClickHandler}
                  />
                </NavLink>
              ))}
            </InfiniteScroll>
          </Box>
          <AddCase shouldAdd={shouldAdd} closeShouldAdd={closeShouldAdd} />
          <Route exact path={'/:enterprise'}>
            <CaseDetail
              openDetail={shouldOpenDetail}
              closeDetail={() => dispatch(closeDetail())}
              closeAddForm={() => dispatch(closeAddForm())}
            />
          </Route>
        </Box>
      )}
    </Box>
  );
};

export default ListCase;
