import { FaqActionTypeEnum } from 'enums/actions';
import faqSvc from 'services/faq';
import { ApiError } from 'types/api';
import { FAQ } from 'types/faq';
import { DispatchType } from './../../types/store';
import { asyncTaskStartAction, asyncTaskStopAction } from './asyncTaskActions';

export const getFaqsSuccessAction = (payload: any) => {
  return {
    type: FaqActionTypeEnum.GET_ALL_FAQS,
    payload,
  };
};

export const toggleShowDetailAction = (faq?: FAQ) => {
  return {
    type: FaqActionTypeEnum.SELECTED_FAQ,
    payload: faq,
  };
};

export const getArticleDetailSuccess = (faq: FAQ) => {
  return {
    type: FaqActionTypeEnum.GET_DETAIL_ARTICLE,
    payload: faq,
  };
};

export const getFaqsByCategoryId = (categoryId: string) => {
  const taskId = FaqActionTypeEnum.GET_ALL_FAQS;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await faqSvc.GetFaqsByCategory({ categoryId });
      dispatch(
        getFaqsSuccessAction({
          faqs: response.data,
          categoryId,
        }),
      );
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const getArticlesDetail = (articleId: string) => {
  const taskId = FaqActionTypeEnum.GET_DETAIL_ARTICLE;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await faqSvc.GetArticleDetail({ articleId });
      dispatch(getArticleDetailSuccess(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};
