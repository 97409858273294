import React from 'react';
import Header from 'components/Header';
import LoginForm from './LoginForm';
import { Route } from 'react-router-dom';
import SignUpForm from 'pages/SignUp/SignUpForm';
import styles from './styles';

function SignIn() {
  const classes = styles();

  return (
    <div className={classes.page}>
      <Header />
      <div>
        <Route exact path={`/:enterprise/sign-up`}>
          <SignUpForm />
        </Route>
        <Route exact path={`/:enterprise/sign-in`}>
          <LoginForm />
        </Route>
      </div>
    </div>
  );
}

export default SignIn;
