import React from 'react';

const BoldCloseIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6067 23.3347L23.3346 10.6068"
        stroke="#ff7b00"
        strokeWidth="3.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3346 23.3346L10.6067 10.6067"
        stroke="#ff7b00"
        strokeWidth="3.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BoldCloseIcon;
