import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  itemList: {
    // backgroundColor: '#dbdbd9',
    height: 'calc(100vh - 133px)',
    marginLeft: 24,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 240px)',
    },
  },
  header: {
    borderBottom: '1px solid #f1f1f1',
    display: 'flex',
    alignItems: 'center',
    padding: '30px 32px 13px 32px',
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'unset',
    },
  },
  body: {
    padding: '24px',
    backgroundColor: '#fafbfc',
  },
  faqMobile: {
    height: 120,
    padding: '16px 12px',
    borderRadius: 10,
    display: 'flex',
    border: '1px solid #f1f1f1',
    marginTop: 10,
  },
  logoMobile: {
    height: '100%',
    width: 104,
    borderRadius: 10,
  },
  mainMobile: {
    marginLeft: 12,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
  },
  titleMobile: {
    fontWeight: 700,
  },
}));

export default styles;
