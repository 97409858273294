import { makeStyles } from '@material-ui/core/styles';
import colors from 'material/colors';

const styles = makeStyles((theme) => ({
  sidebar: {
    overflowY: 'hidden',
    alignItems: 'flex-start',
    flexDirection: 'column',
    display: 'flex',
    position: 'sticky',
    width: '264px',
    borderRadius: 10,
    border: '1px solid #f1f1f1',
    background: '#fff',
    paddingTop: 20,
  },
  text: {
    paddingLeft: '20px',
    marginBottom: '30px',
    marginTop: '30px',
    fontWeight: 'bold',
    fontSize: '26px',
    borderBottom: `0.5px solid ${theme.colors.gray1}`,
    width: '100%',
  },
  navlink: {
    display: 'inline-block',
    textDecoration: 'none',
    fontSize: '14px',
    padding: '10px 0px 10px 20px',
    marginBottom: '15px',
    borderRadius: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 200,
    color: colors.secondary,
  },
  activeStyle: {
    backgroundColor: '#FEF9F5',
    color: colors.primary,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        transform: 'rotateX(180deg)',
      },
      '&>p': {
        color: colors.primary,
      },
    },
  },
  submitButton: {
    color: theme.colors.pureWhite,
    width: '125px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  navlinkMobile: {
    width: '100%',
    height: 40,
    borderRadius: 8,
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0px 12px',
    justifyContent: 'space-between',
    border: '1px solid #f1f1f1',
    marginTop: 20,
  },
  labelMobile: {
    fontSize: '14px',
    fontWeight: 600,
    color: colors.secondary,
  },
}));

export default styles;
