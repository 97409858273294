import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  container: {
    '& > div:nth-child(3) > div': {
      maxWidth: 'none!important',
    },
  },
  title: {
    paddingBottom: 0,
    '& > h2': {
      fontSize: '18px',
    },
    borderBottom: `1px solid ${theme.colors.inputBackground}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    padding: '1rem',
  },
  editor: {
    '& > div > div > div:nth-child(2)': {
      backgroundColor: `${theme.colors.editorBackground}!important`,
      color: `${theme.colors.black}`,
    },
    '& > div > div > div:nth-child(3)': {
      display: 'none!important',
    },
  },
  cancelButton: {
    width: 48,
    height: 35,
    fontSize: 12,
    marginRight: 8,
    color: `${theme.colors.whiteText}`,
    backgroundColor: `${theme.colors.pureWhite}`,
    border: `1px solid ${theme.colors.whiteText}`,
    '&:hover': {
      color: `${theme.colors.whiteText}`,
      backgroundColor: `${theme.colors.white1}`,
    },
  },
  sendButton: {
    width: 48,
    height: 35,
    fontSize: 12,
    '&:hover': {
      backgroundColor: `${theme.colors.primaryHover}`,
    },
  },
}));

export default styles;
