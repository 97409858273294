import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  items: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyItems: 'center',
  },
  item: {
    width: '25%',
    minWidth: '220px',
    maxWidth: '400px',
    textDecoration: 'none',
    borderRadius: 10,
    marginRight: 24,
    marginBottom: 24,
  },
}));

export default styles;
