import { apiRoutesEnum } from 'enums/routes';
import { AXIOS_REVAMP } from './axios';
import {
  CaseDetailRevamp,
  CaseFieldRevamp,
  CaseRevampResponse,
  DocumentRevamp,
  DropDownFieldResponse,
  FormRevampResponse,
  GetCaseDetailByIdRequest,
  GetDropdownDataRequest,
  MessagesRevamp,
  PhotosRevamp,
  PostMessageRevamp,
  SaveCaseRequest,
  TimeLineRevamp,
} from 'types/cases';
import { PaginationRequest } from 'types/pagination';

async function FetchAllCases(
  status: string,
  nextKey: string | null,
): Promise<CaseRevampResponse> {
  return AXIOS_REVAMP.get(`${apiRoutesEnum.GET_ALL_CASES_REVAMP}/${status}`, {
    params: {
      pageSize: 20,
      nextKey,
    },
  });
}

async function getCount(status: string): Promise<{ data: number }> {
  return AXIOS_REVAMP.get(
    `${apiRoutesEnum.GET_ALL_CASES_REVAMP}/${status}/count`,
  );
}

async function GetCaseDetailById(
  params: GetCaseDetailByIdRequest,
): Promise<CaseDetailRevamp> {
  return AXIOS_REVAMP.get(
    `${apiRoutesEnum.GET_CASE_DETAIL_BY_ID_REVAMP}/${params.caseId}`,
  );
}

async function FetchCaseMessagesById(
  params: GetCaseDetailByIdRequest,
  pagination: PaginationRequest,
): Promise<MessagesRevamp> {
  return AXIOS_REVAMP.get(
    `${apiRoutesEnum.CASE_MESSAGES_REVAMP}/${params.caseId}`,
  );
}

async function FetchCaseDocumentsById(
  params: GetCaseDetailByIdRequest,
): Promise<DocumentRevamp> {
  return AXIOS_REVAMP.get(
    `${apiRoutesEnum.CASE_DOCUMENTS_REVAMP}/${params.caseId}`,
  );
}

async function FetchCasePhotosById(
  params: GetCaseDetailByIdRequest,
): Promise<PhotosRevamp> {
  return AXIOS_REVAMP.get(
    `${apiRoutesEnum.CASE_PHOTOS_REVAMP}/${params.caseId}`,
  );
}

async function FetchListField(formId: string): Promise<CaseFieldRevamp> {
  return AXIOS_REVAMP.get(`${apiRoutesEnum.GET_LIST_FIELD_REVAMP}/${formId}`);
}

async function FetchDropdownData(
  query: GetDropdownDataRequest,
): Promise<DropDownFieldResponse> {
  return AXIOS_REVAMP.get(`${apiRoutesEnum.GET_DROPDOWN_DATA_REVAMP}`, {
    params: query,
  });
}

async function SaveCase(body: SaveCaseRequest): Promise<{ data: string }> {
  return AXIOS_REVAMP.post(
    `${apiRoutesEnum.SAVE_CASE_REVAMP}`,
    body.dataField,
    {
      params: {
        formId: body.formId,
      },
    },
  );
}

async function GetListForm(): Promise<FormRevampResponse> {
  return AXIOS_REVAMP.get(`${apiRoutesEnum.GET_LIST_FORM_REVAMP}`, {});
}
async function PostMessage(formData: any): Promise<PostMessageRevamp> {
  return AXIOS_REVAMP.post(`${apiRoutesEnum.CASE_MESSAGES_REVAMP}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function PostDocument(
  formData: any,
  caseId: string,
): Promise<DocumentRevamp> {
  return AXIOS_REVAMP.post(
    `${apiRoutesEnum.CASE_DOCUMENTS_REVAMP}/${caseId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
      },
    },
  );
}

async function DeleteDocument(id: string, url: string): Promise<void> {
  return AXIOS_REVAMP.delete(
    `${apiRoutesEnum.CASE_DOCUMENTS_REVAMP}/${id}?url=${url}`,
  );
}

async function PostPhoto(formData: any, caseId: string): Promise<PhotosRevamp> {
  return AXIOS_REVAMP.post(
    `${apiRoutesEnum.CASE_PHOTOS_REVAMP}/${caseId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
      },
    },
  );
}

async function DeletePhoto(id: string, url: string): Promise<void> {
  return AXIOS_REVAMP.delete(
    `${apiRoutesEnum.CASE_PHOTOS_REVAMP}/${id}?url=${url}`,
  );
}

async function GetTimeline(caseId: string): Promise<TimeLineRevamp> {
  return AXIOS_REVAMP.get(`${apiRoutesEnum.GET_TIMELINE_REVAMP}/${caseId}`);
}

async function PublishEnvelope(formData: FormData): Promise<{ data: string }> {
  return AXIOS_REVAMP.post(
    `${apiRoutesEnum.PUBLISH_ENVELOPE_REVAMP}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
      },
    },
  );
}
const caseService = {
  FetchAllCases,
  getCount,
  GetCaseDetailById,
  FetchCaseMessagesById,
  FetchCaseDocumentsById,
  FetchCasePhotosById,
  FetchListField,
  FetchDropdownData,
  SaveCase,
  GetListForm,
  PostMessage,
  PostDocument,
  PostPhoto,
  GetTimeline,
  PublishEnvelope,
  DeleteDocument,
  DeletePhoto,
};

export default caseService;
