import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 90px)',
  },
  form: {
    width: '500px',
  },
  submitButton: {
    color: theme.colors.pureWhite,
    width: '125px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  submitButtonDisable: {
    backgroundColor: `${theme.colors.black1} !important`,
  },
  loading: {
    top: 0,
    bottom: 0,
    right: 10,
    margin: 'auto',
    position: 'absolute',
  },
  forgotPasswordLink: {
    textDecoration: 'none',
    border: 'none',
    backgroundColor: 'inherit',
    cursor: 'pointer',
    marginTop: '5px',
    marginBottom: '5px',
    padding: '0px',
    display: 'block',
    '&:focus': {
      outline: 'none',
    },
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.black,
    '& p': {
      color: theme.colors.black1,
    },
  },
}));
