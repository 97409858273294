import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px 12px 16px 16px',
    borderBottom: `1px solid ${theme.colors.inputBackground}`,
    // height: 117,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
      marginTop: 20,
      borderRadius: 15,
      border: `1px solid ${theme.colors.inputBackground}`,
    },
    '& p': {
      whiteSpace: 'nowrap',
      margin: 0,
    },
    '& h4': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  field: {
    display: 'flex',
    alignItems: 'baseline',
  },
  typography: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginBottom: '0!important',
    },
  },
}));

export default useStyles;
