import React, { useEffect } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import TextButton from 'components/TextButton';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { authenticated, getLogoState, getMenuState } from 'store/selectors';
import { logginLinks, notLogginLinks } from 'material/sidebarLinks';
import { DispatchType } from 'types/store';
import { logoutAction } from 'store/actions/loginActions';
import LocalStorage from 'utils/localStorage';
import { LocalStorageEnum } from 'enums/storage';
import { Box, Typography } from '@material-ui/core';
import colors from 'material/colors';
import LogoutIcon from 'icons/LogoutIcon';

export type SidebarProps = {
  links: { label: string; path: string; exact?: boolean }[];
};

const Sidebar = () => {
  const sAuthenticated = useSelector(authenticated);
  const token = LocalStorage.getItem(LocalStorageEnum.ACCESS_TOKEN);
  const dispatch: DispatchType = useDispatch();
  const routeHistory = useHistory();
  let logoFile = useSelector(getLogoState);
  const menu = useSelector(getMenuState);
  const { enterprise } = useParams<any>();
  const isLoggedIn = sAuthenticated || token;
  const myLinks = isLoggedIn
    ? logginLinks.map((l) => ({
        ...l,
        path: `${enterprise ? `/${enterprise}` : ''}${l.path}`,
      }))
    : notLogginLinks.map((n) => ({
        ...n,
        path: `${enterprise ? `/${enterprise}` : ''}${n.path}`,
      }));
  const classes = styles();

  useEffect(() => {
    document.title = menu.pageName;
  }, [menu.pageName]);

  const handleClick = async () => {
    try {
      if (isLoggedIn) {
        await dispatch(logoutAction());
      }
      const enterprises = location.pathname.split('/');
      if (!enterprises[2] || enterprises[2] == 'sign-in') {
        return;
      }
      const url = `/${enterprise ? `${enterprise}/` : ''}sign-in`;
      if (url !== location.pathname) {
        routeHistory.push(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (logoFile === 'logo.png') {
    logoFile = `https://d3si3omi71glok.cloudfront.net/salesboxfiles/${logoFile.slice(
      -3,
    )}/${logoFile}`;
  }

  const getLabel = (key: string, label: string) => {
    if (!key) return label;
    return (menu as any)[key];
  };

  return (
    <div className={classes.sidebar}>
      <Box className="w100">
        <img src={logoFile} className={classes.logo} alt="logo" />
        <Typography className={classes.menu}>Menu</Typography>
        {myLinks.map((link) => {
          return (
            <NavLink
              key={link.label}
              to={link.path}
              exact={link.exact}
              activeClassName={classes.activeStyle}
              className={classes.navlink}
            >
              <link.logo color={colors.secondary} />
              <Typography className="ml3">
                {getLabel(link.key, link.label)}
              </Typography>
            </NavLink>
          );
        })}
      </Box>
      <TextButton
        label={isLoggedIn ? 'Logout' : 'Login'}
        className={classes.submitButton}
        onClick={handleClick}
        icon={isLoggedIn ? <LogoutIcon className="mr3" /> : null}
      />
    </div>
  );
};

export default Sidebar;
