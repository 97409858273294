import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useCallback } from 'react';
import useStyles from './styles';
import TextButton from 'components/TextButton';
import TabButton from 'components/TabButton';
import AddIcon from 'icons/AddIcon';
import { DispatchType } from 'types/store';
import { useDispatch } from 'react-redux';
import { closeDetail } from 'store/actions/caseOpenAction';

interface IProps {
  setIsActive: (value: boolean) => void;
  isActive: boolean;
  setShouldAdd: () => void;
}

const ListCaseHeader = ({ isActive, setIsActive, setShouldAdd }: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch: DispatchType = useDispatch();

  const onToggleActive = useCallback((value: boolean) => {
    dispatch(closeDetail());
    setIsActive(value);
  }, []);

  return (
    <Box className={classes.header}>
      <Box className={classes.wrapperButton}>
        <TabButton
          onClick={() => onToggleActive(true)}
          active={isActive}
          label="Active"
        />
        <TabButton
          onClick={() => onToggleActive(false)}
          active={!isActive}
          label="Closed"
        />
      </Box>
      <TextButton
        icon={<AddIcon className={matches ? '' : 'mr2'} />}
        label={matches ? '+ Add case' : 'Add case'}
        onClick={setShouldAdd}
        className={matches ? classes.addCaseButtonMobile : 'mb2'}
      />
    </Box>
  );
};

export default ListCaseHeader;
