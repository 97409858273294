import {
  languageReducer,
  initialLanguageState,
} from './reducers/languageReducer';
import { initialMenuState, menuReducer } from './reducers/menuReducer';
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { RootStateType } from 'types/store';
import { initialAppState, appStateReducer } from './reducers/appReducer';
import {
  asyncTaskReducer,
  initialAsyncTaskState,
} from './reducers/asyncTaskReducer';
import {
  initialCategoryState,
  categoryReducer,
} from './reducers/categoryReducer';
import { initialFaqState, faqReducer } from './reducers/faqReducer';
import { initialLoginState, loginReducer } from './reducers/loginReducer';
import { initialLogoState, logoReducer } from './reducers/logoReducer';
import {
  initialCaseOpenState,
  caseOpenReducer,
} from './reducers/caseOpenReducer';
import { caseReducer, initialCasesState } from './reducers/caseReducer';

export const initialRootState: RootStateType = {
  asyncTaskReducer: initialAsyncTaskState,
  appStateReducer: initialAppState,
  loginReducer: initialLoginState,
  categoryReducer: initialCategoryState,
  faqReducer: initialFaqState,
  logoReducer: initialLogoState,
  menuReducer: initialMenuState,
  caseOpenReducer: initialCaseOpenState,
  languageReducer: initialLanguageState,
  caseReducer: initialCasesState,
};

export default function configureStore(
  preloadedState: RootStateType = initialRootState,
) {
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers =
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(...enhancers)
      : compose(...enhancers);

  const appReducer = combineReducers<RootStateType>({
    appStateReducer,
    asyncTaskReducer,
    loginReducer,
    categoryReducer,
    faqReducer,
    logoReducer,
    menuReducer,
    caseOpenReducer,
    languageReducer,
    caseReducer,
  });

  // Reset state after logout
  const rootReducer = (state: RootStateType, action: AnyAction) => {
    return appReducer(state, action);
  };

  // @ts-ignore
  return createStore(rootReducer, preloadedState, composedEnhancers);
}
