import React from 'react';
import { IIconProperty } from 'types/iconProps';

const UserEditIcon = ({ color }: IIconProperty) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99967 10.1667C12.3008 10.1667 14.1663 8.37587 14.1663 6.16675C14.1663 3.95761 12.3008 2.16675 9.99967 2.16675C7.69849 2.16675 5.83301 3.95761 5.83301 6.16675C5.83301 8.37587 7.69849 10.1667 9.99967 10.1667Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0086 13.1586L13.0585 15.9907C12.9419 16.1027 12.8335 16.3106 12.8085 16.4626L12.6502 17.5426C12.5919 17.9346 12.8752 18.2066 13.2835 18.1506L14.4085 17.9986C14.5669 17.9746 14.7919 17.8706 14.9002 17.7586L17.8502 14.9266C18.3585 14.4386 18.6002 13.8706 17.8502 13.1506C17.1085 12.4386 16.5169 12.6706 16.0086 13.1586Z"
        stroke={color}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.584 13.5667C15.834 14.4307 16.534 15.1026 17.434 15.3426"
        stroke={color}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.8418 18.1667C2.8418 15.0707 6.05015 12.5667 10.0001 12.5667C10.8668 12.5667 11.7001 12.6867 12.4751 12.9107"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserEditIcon;
