import { Box, Menu, Typography } from '@material-ui/core';
import IconButton from 'components/IconButton';
import { LocalStorageEnum } from 'enums/storage';
import BackIcon from 'icons/BackIcon';
import LogoutIcon from 'icons/LogoutIcon';
import MenuIcon from 'icons/MenuIcon';
import colors from 'material/colors';
import { logginLinks, notLogginLinks } from 'material/sidebarLinks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { closeAddForm, closeDetail } from 'store/actions/caseOpenAction';
import { toggleShowDetailAction } from 'store/actions/faqActions';
import { logoutAction } from 'store/actions/loginActions';
import {
  authenticated,
  getCaseOpenDetailState,
  getCaseOpenFormState,
  getDetailFaq,
  getLogoState,
  getMenuState,
} from 'store/selectors';
import { DispatchType } from 'types/store';
import LocalStorage from 'utils/localStorage';
import styles from './styles';

export type SidebarProps = {
  links: { label: string; path: string; exact?: boolean }[];
};

const Sidebar = () => {
  const sAuthenticated = useSelector(authenticated);
  const token = LocalStorage.getItem(LocalStorageEnum.ACCESS_TOKEN);
  const dispatch: DispatchType = useDispatch();
  const routeHistory = useHistory();
  let logoFile = useSelector(getLogoState);
  const menu = useSelector(getMenuState);
  const { enterprise } = useParams<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const shouldAddForm = useSelector(getCaseOpenFormState);
  const shouldOpenDetail = useSelector(getCaseOpenDetailState);
  const shouldOpenFaqDetail = useSelector(getDetailFaq);

  useEffect(() => {
    document.title = menu.pageName;
  }, [menu.pageName]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isLoggedIn = sAuthenticated || token;
  const myLinks = isLoggedIn
    ? logginLinks.map((l) => ({
        ...l,
        path: `${enterprise ? `/${enterprise}` : ''}${l.path}`,
      }))
    : notLogginLinks.map((n) => ({
        ...n,
        path: `${enterprise ? `/${enterprise}` : ''}${n.path}`,
      }));
  const classes = styles();

  const handleClick = async () => {
    try {
      if (isLoggedIn) {
        await dispatch(logoutAction());
      }
      const url = `/${enterprise ? `${enterprise}/` : ''}sign-in`;
      if (url !== location.pathname) {
        routeHistory.push(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (logoFile === 'logo.png') {
    logoFile = `https://d3si3omi71glok.cloudfront.net/salesboxfiles/${logoFile.slice(
      -3,
    )}/${logoFile}`;
  }

  const getLabel = (key: string, label: string) => {
    if (!key) return label;
    return (menu as any)[key];
  };

  return (
    <div className={classes.sidebarMobile}>
      {shouldAddForm && (
        <IconButton
          Icon={<BackIcon />}
          onClick={() => dispatch(closeAddForm())}
        />
      )}
      {shouldOpenDetail && (
        <IconButton
          Icon={<BackIcon />}
          onClick={() => dispatch(closeDetail())}
        />
      )}
      {shouldOpenFaqDetail && (
        <IconButton
          Icon={<BackIcon />}
          onClick={() => dispatch(toggleShowDetailAction())}
        />
      )}
      {!shouldAddForm && !shouldOpenDetail && !shouldOpenFaqDetail && (
        <>
          <img className={classes.imgMobile} src={logoFile} alt="logo" />
          <IconButton
            Icon={<MenuIcon />}
            className={classes.menuMobile}
            onClick={handleOpenMenu}
          />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.menuMobileOpen}
          >
            {myLinks.map((link) => {
              return (
                <NavLink
                  key={link.label}
                  to={link.path}
                  exact={link.exact}
                  activeClassName={classes.activeStyle}
                  className={classes.navlink}
                  onClick={handleClose}
                >
                  <link.logo color={colors.secondary} />
                  <Typography className="ml6">
                    {getLabel(link.key, link.label)}
                  </Typography>
                </NavLink>
              );
            })}
            <Box className={classes.logoutMobile} onClick={handleClick}>
              <LogoutIcon className={classes.logoutIcon} />
              <Typography className="ml6">Logout</Typography>
            </Box>
          </Menu>
        </>
      )}
    </div>
  );
};

export default Sidebar;
