import { Logo, LogoRequest } from 'types/logo';
import { apiRoutesEnum } from './../enums/routes';
import { AXIOS_REVAMP } from './axios';

async function GetLogo(params: LogoRequest): Promise<Logo> {
  return AXIOS_REVAMP.get(`${apiRoutesEnum.LOGO_REVAMP}`);
}

export default {
  GetLogo,
};
