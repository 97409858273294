import React from 'react';
import SignUpForm from 'pages/SignUp/SignUpForm';
import Header from 'components/Header';
import styles from './styles';

function SignUp() {
  const classes = styles();

  return (
    <div className={classes.page}>
      <Header />
      <SignUpForm />
    </div>
  );
}

export default SignUp;
