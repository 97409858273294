import React from 'react';
const MessageIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4884 11.4151V14.7544C24.4884 19.2068 22.2622 21.4329 17.8098 21.4329H17.2533C16.9082 21.4329 16.5743 21.5999 16.3628 21.8782L14.6932 24.1044C13.9586 25.0839 12.7564 25.0839 12.0218 24.1044L10.3521 21.8782C10.174 21.6333 9.76219 21.4329 9.46166 21.4329H8.90511C4.45275 21.4329 2.22656 20.3198 2.22656 14.7544V9.18893C2.22656 4.73656 4.45275 2.51038 8.90511 2.51038H15.5837"
        stroke="#292D32"
        strokeWidth="1.66964"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8065 12.5285H17.8165"
        stroke="#292D32"
        strokeWidth="2.22618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3524 12.5285H13.3624"
        stroke="#292D32"
        strokeWidth="2.22618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.89931 12.5285H8.90931"
        stroke="#292D32"
        strokeWidth="2.22618"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default MessageIcon;
