export const convertUuidToImageUrl = (uuid?: string) => {
  return `${process.env.REACT_APP_IMAGE_BASE_URL}/${uuid?.substr(
    uuid?.length - 3,
  )}/${uuid}`;
};

export const stripHtml = (html: string) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const isEmailField = (field: string) => {
  const emailText = 'EMAIL';
  return field.includes(emailText);
};
