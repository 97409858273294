import React, { ChangeEvent, useState } from 'react';
// import * as yup from 'yup';
import { UserCredentials } from 'types/user';
import { styles } from './styles';
import TextInput from 'components/TextInput';
import { UserFieldEnum } from 'enums/user';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { DispatchType } from 'types/store';
import { loginAction } from 'store/actions/loginActions';
import { useHistory, useParams } from 'react-router-dom';
import TextButton from 'components/TextButton';
import isEmail from 'validator/lib/isEmail';
import {
  EMAIL_IS_REQUIRED,
  INVALID_EMAIL,
  PASSWORD_IS_REQUIRED,
} from 'constatnts';
import { LoginActionTypeEnum } from 'enums/actions';
import { LocalStorageEnum } from 'enums/storage';
import LocalStorage from 'utils/localStorage';
import ForgotPasswordModal from '../ForgotPasswordModal';

export type LoginFormProps = {
  handleForm?: (credentials: UserCredentials) => Promise<boolean>;
};

/**
 * LoginForm component
 * Render login form
 */
export default function LoginForm({ handleForm }: LoginFormProps) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [form, setForm] = useState({
    email: LocalStorage.getItem(LocalStorageEnum.USER_NAME) || '',
    password: '',
  });
  const [rememberMe, setRememberMe] = useState(
    LocalStorage.getItem(LocalStorageEnum.REMEMBER_ME) == 'true',
  );

  const [error, setError] = useState({
    email: '',
    password: '',
  });

  const dispatch: DispatchType = useDispatch();
  const routeHistory = useHistory();
  const toggleShowPassword = React.useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, []);
  const { enterprise } = useParams<any>();

  const redirectToHome = () => {
    if (rememberMe) {
      LocalStorage.setItem(LocalStorageEnum.USER_NAME, form.email);
    } else {
      LocalStorage.setItem(LocalStorageEnum.USER_NAME, '');
    }
    routeHistory.push(`/${enterprise}`);
  };

  const handleLoginSubmit = async () => {
    try {
      if (!form.email) {
        setError({
          email: EMAIL_IS_REQUIRED,
          password: '',
        });
        return;
      }
      if (!isEmail(form.email)) {
        setError({
          email: INVALID_EMAIL,
          password: '',
        });
        return;
      }
      if (!form.password) {
        setError({
          email: '',
          password: PASSWORD_IS_REQUIRED,
        });
        return;
      }
      await dispatch(
        loginAction(
          {
            username: form.email,
            password: form.password,
          },
          enterprise,
          redirectToHome,
        ),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const classes = styles();
  const { EMAIL, PASSWORD, REMEMBER_ME } = UserFieldEnum;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;
    setError({
      ...error,
      [name]: '',
    });
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onRemember = (e: any) => {
    if (e.target.checked) {
      LocalStorage.setItem(LocalStorageEnum.REMEMBER_ME, 'true');
      setRememberMe(true);
    } else {
      LocalStorage.setItem(LocalStorageEnum.REMEMBER_ME, 'false');
      setRememberMe(false);
    }
  };

  const handleOpenModal = () => {
    setOpenForgotPasswordModal(true);
  };

  const handleCloseModal = () => {
    setOpenForgotPasswordModal(false);
  };

  return (
    <div className={classes.container}>
      <div>
        <form className={classes.form}>
          <h2>Login to the support portal</h2>
          <h3>Enter the details below</h3>
          <div style={{ marginBottom: '12px' }}>
            <TextInput
              placeholder={'Your e-mail address'}
              name={EMAIL}
              type={'email'}
              value={form.email}
              onChange={onChange}
              helperText={error.email}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <TextInput
              placeholder={'Password'}
              name={PASSWORD}
              type={showPassword ? 'text' : 'password'}
              value={form.password}
              onChange={onChange}
              helperText={error.password}
            />
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox style={{ color: 'green' }} checked={rememberMe} />
              }
              label="Remember me on this computer"
              onClick={onRemember}
            />
          </FormGroup>
          <button
            type="button"
            className={classes.forgotPasswordLink}
            onClick={handleOpenModal}
          >
            <Typography variant="body1">Forgot your password?</Typography>
          </button>
          <TextButton
            label="Login"
            className={classes.submitButton}
            onClick={handleLoginSubmit}
            enterPressDependencies={[form]}
            actionId={LoginActionTypeEnum.LOGIN}
          />
        </form>
      </div>

      {openForgotPasswordModal && (
        <ForgotPasswordModal
          open={openForgotPasswordModal}
          handleClose={handleCloseModal}
        />
      )}
    </div>
  );
}
