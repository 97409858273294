import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    width: '100px',
    overflowX: 'hidden',
    position: 'relative',
  },
  icon: {
    height: '100px',
    width: '100px',
    color: 'grey',
  },
  closeButton: {
    width: '30px',
    height: '30px',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  confirmDelete: {
    width: '300px!important',
    height: '150px!important',
    top: '40%!important',
    left: '50%!important',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    padding: '0px 20px!important',
  },
}));

export default styles;
