import React from 'react';
import SubmitTicket from 'pages/SubmitTicket/SubmitTicket';
import { Route, Switch } from 'react-router-dom';
import styles from './styles';
import Faq from './Faq/Faq';

const FaqPage = () => {
  const classes = styles();

  return (
    <div className={classes.category}>
      <Switch>
        <Route path={'/:enterprise/submit-ticket'}>
          <SubmitTicket />
        </Route>
        <Route path={'/:enterprise/faq'}>
          <Faq />
        </Route>
      </Switch>
    </div>
  );
};

export default FaqPage;
