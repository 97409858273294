import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  photo: {
    width: '120px',
    height: '120px',
    color: 'grey',
    borderRadius: 8,
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dialog: {
    width: '700px',
    height: '500px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    width: '30px',
    height: '30px',
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0.5,
  },
  confirmDelete: {
    width: '300px!important',
    height: '150px!important',
    top: '40%!important',
    left: '50%!important',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    padding: '0px 20px!important',
  },
}));
