import React, { useEffect } from 'react';
import styles from './styles';
import { Box, Typography } from '@material-ui/core';
import { getListFormState, getSelectedFormState } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import AddCaseForm from 'pages/Home/AddCase/AddCaseForm';
import Dropdown from 'components/Dropdown';
import { DispatchType } from 'types/store';
import {
  getListForm,
  selectFormSuccessAction,
} from 'store/actions/caseActions';
import _ from 'lodash';
import MessageQuestion from 'icons/MessageQuestion';

const SubmitTicket = () => {
  const classes = styles();
  const selectedForm = useSelector(getSelectedFormState);
  const forms = useSelector(getListFormState);
  let submitTicketForm = _.cloneDeep(forms);

  const dispatch: DispatchType = useDispatch();

  const onChange = (event: React.ChangeEvent<{ value: any }>) => {
    dispatch(selectFormSuccessAction(event.target.value));
  };

  useEffect(() => {
    dispatch(getListForm());
  }, []);

  return (
    <Box>
      <Box className={classes.header}>
        <MessageQuestion color="#ff7b00" />
        <Typography className="ml3 ">Submit a ticket</Typography>
      </Box>
      <div className={classes.panel}>
        <div className={classes.form}>
          <Box
            style={{
              width: '100%',
              marginBottom: 24,
            }}
          >
            <Dropdown
              values={submitTicketForm?.map((f) => ({
                id: f.id,
                description: f.name,
              }))}
              currentValue={selectedForm}
              onChange={onChange}
            />
          </Box>
          <Box className={classes.addCaseContainer}>
            <AddCaseForm selectedForm={selectedForm} />
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default SubmitTicket;
