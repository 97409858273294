import React from 'react';

const EditIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.18264 1.95016L2.73556 6.65725C2.56764 6.836 2.40514 7.18808 2.37264 7.43183L2.17223 9.1868C2.10181 9.82055 2.55681 10.2539 3.18514 10.1456L4.92931 9.84769C5.17306 9.8043 5.51431 9.62555 5.68223 9.44144L10.1293 4.73433C10.8985 3.92183 11.2452 2.99558 10.048 1.86349C8.85642 0.742242 7.95181 1.13766 7.18264 1.95016Z"
        stroke="#002F47"
        strokeWidth="0.8125"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.44043 2.73535C6.67334 4.23035 7.88668 5.37327 9.39252 5.52493"
        stroke="#002F47"
        strokeWidth="0.8125"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.625 11.9167H11.375"
        stroke="#002F47"
        strokeWidth="0.8125"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
