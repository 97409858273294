import colors from './colors';

const overrides = {
  MuiTypography: {
    // root: {
    //   color: colors.darkGray,
    // },
    body1: {
      fontSize: 13,
      color: colors.black,
      lineHeight: '22px',
    },
    body2: {
      fontSize: 11,
      color: colors.black1,
      fontWeight: 600,
    },
    h2: {
      fontSize: 13,
      color: colors.black,
    },
    h4: {
      fontSize: 12,
      color: colors.secondary,
      fontWeight: 400,
    },
    h5: {
      color: colors.primary,
    },
    h6: {
      fontSize: 11,
      color: colors.primary,
    },
    subtitle1: {
      fontSize: 13,
      color: colors.black,
    },
    subtitle2: {
      fontSize: 12,
      color: colors.black1,
    },
  },
  MuiButton: {
    root: {
      backgroundColor: colors.primary,
      textTransform: 'none' as any,
      '&:hover': {
        backgroundColor: colors.primary,
      },
      // '&.Mui-disabled':{
      //   backgroundColor:colors.gray
      // }
    },
  },
};

export default overrides;
