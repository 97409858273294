import React from 'react';
import { IIconProperty } from 'types/iconProps';

const RowVertical = ({ color }: IIconProperty) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5837 11.25H3.41699C2.16699 11.25 1.66699 11.7833 1.66699 13.1083V16.475C1.66699 17.8 2.16699 18.3333 3.41699 18.3333H16.5837C17.8337 18.3333 18.3337 17.8 18.3337 16.475V13.1083C18.3337 11.7833 17.8337 11.25 16.5837 11.25Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5837 1.66675H3.41699C2.16699 1.66675 1.66699 2.20008 1.66699 3.52508V6.89175C1.66699 8.21675 2.16699 8.75008 3.41699 8.75008H16.5837C17.8337 8.75008 18.3337 8.21675 18.3337 6.89175V3.52508C18.3337 2.20008 17.8337 1.66675 16.5837 1.66675Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default RowVertical;
