import { MenuStateEnum } from 'enums/actions';
import { ApiError } from 'types/api';
import { DispatchType } from 'types/store';
import { asyncTaskStartAction, asyncTaskStopAction } from './asyncTaskActions';
import MenuService from '../../services/menu';
import { Menu } from 'types/menu';

export const getMenuSuccessAction = (payload: Menu) => ({
  type: MenuStateEnum.GET_MENU,
  payload: payload,
});

export const getMenuAction = (pageUrl: string) => {
  const taskId = MenuStateEnum.GET_MENU;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const data: any = await MenuService.GetMenu({ pageUrl });
      if (data.code !== 'ECN404') {
        dispatch(getMenuSuccessAction(data));
      }
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};
