import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  dialog: {
    width: '700px',
    minHeight: '400px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    padding: '1rem',
  },
  editor: {
    '& > div > div ': {
      border: 'none!important',
    },
    '& > div > div > div:nth-child(2)': {
      backgroundColor: `${theme.colors.editorBackground}!important`,
      color: `${theme.colors.black}`,
    },
    // '& > div > div > div:nth-child(3)': {
    //   display: 'none!important',
    // },
  },
  btn: {
    height: 40,
    width: 80,
  },
  cancelButton: {
    width: 48,
    height: 35,
    fontSize: 12,
    marginRight: 8,
    color: `${theme.colors.whiteText}`,
    backgroundColor: `${theme.colors.pureWhite}`,
    border: `1px solid ${theme.colors.whiteText}`,
    '&:hover': {
      color: `${theme.colors.whiteText}`,
      backgroundColor: `${theme.colors.white1}`,
    },
  },
  sendButton: {
    width: 48,
    height: 35,
    fontSize: 12,
    '&:hover': {
      backgroundColor: `${theme.colors.primaryHover}`,
    },
  },
  img: {
    width: 100,
    height: 100,
    marginLeft: 24,
    marginTop: 12,
    [theme.breakpoints.down('sm')]: {
      width: 125,
      height: 125,
    },
  },
  fileInput: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 12,
  },
}));

export default styles;
