import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface IProps {
  onClick?: () => void;
  label: string;
  className?: string;
  active?: boolean;
}

const TabButton = ({ onClick, label, className, active }: IProps) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.root, className, { [classes.active]: active })}
      onClick={onClick}
      disableFocusRipple
      disableRipple
    >
      {label}
    </Button>
  );
};

export default TabButton;
const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 11,
    backgroundColor: theme.colors.pureWhite,
    padding: '2px 8px',
    color: theme.colors.gray4,
    fontWeight: 700,
    border: 'none',
    textDecoration: 'none',
    borderRadius: 0,
    height: 48,
    '&:hover': {
      backgroundColor: theme.colors.gray3,
      color: theme.colors.black2,
    },
    [theme.breakpoints.down('sm')]: {
      fontWeight: 600,
      fontSize: 20,
    },
  },
  disabled: {
    backgroundColor: theme.colors.gray1,
    color: theme.colors.gray,
  },
  active: {
    color: theme.colors.black2,
    borderBottom: `2px solid ${theme.colors.primary}`,
  },
}));
