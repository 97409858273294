import { LanguageActionTypeEnum } from './../../enums/actions';
import { LanguageActionTypes, LanguageRevamp } from 'types/language';

export const initialLanguageState: LanguageRevamp[] = [];

export const languageReducer = (
  state = initialLanguageState,
  action: LanguageActionTypes,
) => {
  switch (action.type) {
    case LanguageActionTypeEnum.GET_ALL_LANGUAGES:
      return [...action.payload];
    default:
      return state;
  }
};
