import { Box, Typography } from '@material-ui/core';
import TextInput from '../TextInput';
import React from 'react';
import clsx from 'clsx';

interface IProps {
  required?: boolean;
  value: string;
  placeholder?: string;
  onChange: any;
  name: string;
  label?: string;
  labelClassName?: string;
  type?: string;
  helperText?: string;
  className?: string;
}

const TextInputWithRequireIndicator = ({
  required,
  value,
  placeholder,
  onChange,
  name,
  label,
  labelClassName,
  type,
  helperText,
  className,
}: IProps) => {
  return (
    <Box className="p2" display="flex" alignItems="center">
      <Box
        className={labelClassName}
        height="fit-content"
        mt={1}
        display="flex"
        alignItems="center"
      >
        {label && (
          <Typography
            style={{
              marginRight: 4,
              marginBottom: helperText ? 26 : 0,
            }}
          >
            {label}
          </Typography>
        )}
        {required && (
          <Typography
            style={{
              marginRight: 4,
              color: 'red',
              fontWeight: 700,
              marginBottom: helperText ? 26 : 0,
            }}
          >
            *
          </Typography>
        )}
      </Box>
      <TextInput
        className={clsx('flex1', { ml3: !required }, className)}
        required={required}
        value={value}
        name={name}
        error={!!helperText}
        helperText={helperText}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
      />
    </Box>
  );
};

export default TextInputWithRequireIndicator;
