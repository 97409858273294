import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    padding: 10,
  },
  spinner: {
    width: 30,
    height: 30,
    border: '2px solid',
    borderColor: '#827b7a #f1f1f1 #f1f1f1 #f1f1f1',
    borderRadius: '50%',
    animation: '$spinAnim 0.5s linear infinite',
  },
  '@keyframes spinAnim': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

const LoadingComponent = () => {
  const classes = styles();

  return (
    <div className={classes.loaderContainer}>
      <div className={classes.spinner}></div>
    </div>
  );
};

export default LoadingComponent;
