import { makeStyles } from '@material-ui/core/styles';
import colors from 'material/colors';

const styles = makeStyles((theme) => ({
  sidebar: {
    overflowY: 'hidden',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
    justifyContent: 'space-between',
  },
  text: {
    paddingLeft: '20px',
    marginBottom: '30px',
    fontWeight: 'normal',
    fontSize: '26px',
    color: theme.colors.black1,
  },
  menu: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    color: '#798B9B !important',
    opacity: 0.3,
  },
  navlink: {
    textDecoration: 'none',
    fontSize: '16px',
    width: '100%',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    padding: '0px 12px',
    borderRadius: 8,
    '& p': {
      color: colors.secondary,
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #FEF9F5',
      borderRadius: 0,
    },
  },
  logo: {
    aspectRatio: '1/1',
    borderRadius: 12,
    width: '100%',
    objectFit: 'contain',
  },
  activeStyle: {
    backgroundColor: colors.activeLink,
    fontWeight: 'bold',
    '& path': {
      stroke: colors.primary,
    },
    '& p': {
      color: colors.primary,
    },
  },
  submitButton: {
    color: theme.colors.pureWhite,
    width: '100%',
  },
  sidebarMobile: {
    padding: '16px 16px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #f1f1f1;',
  },
  menuMobile: {
    borderRadius: '5px !important',
    border: '1.5px solid #ff7b00',
    height: 40,
    width: 40,
  },
  imgMobile: {
    height: '100%',
    aspectRatio: '1/1',
    borderRadius: 4,
  },
  menuMobileOpen: {
    '& .MuiPaper-root': {
      width: '100%',
      padding: 14,
    },
  },
  logoutMobile: {
    display: 'flex',
    height: 48,
    borderBottom: '1px solid #FEF9F5',
    alignItems: 'center',
    padding: '0px 12px',
  },
  logoutIcon: {
    '& path': {
      stroke: colors.primary,
    },
  },
}));

export default styles;
