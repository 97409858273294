import React from 'react';
import { clientRoutesEnum } from 'enums/routes';
import AppLayout from 'layouts/AppLayout';
import HomePage from 'pages/Home';
import { Route, Switch } from 'react-router-dom';
import MyInformationPage from 'pages/MyInformation';
import FaqPage from 'pages/FaqPage';
import NotFound from 'pages/NotFound';
import SignInPage from 'pages/SignIn';
import SignUpPage from 'pages/SignUp';
import SubmitTicket from 'pages/SubmitTicket/SubmitTicket';

const AppRoutes = () => {
  return (
    <Switch>
      <AppLayout
        exact
        path={clientRoutesEnum.HOME}
        RenderComponent={HomePage}
      />
      <AppLayout
        exact
        path={clientRoutesEnum.MY_INFORMATION}
        RenderComponent={MyInformationPage}
      />
      <AppLayout
        exact
        path={clientRoutesEnum.SIGN_IN}
        RenderComponent={SignInPage}
      />
      <AppLayout
        exact
        path={clientRoutesEnum.SIGN_UP}
        RenderComponent={SignUpPage}
      />
      <AppLayout
        exact
        path={clientRoutesEnum.SUBMIT_TICKET}
        RenderComponent={SubmitTicket}
      />
      <AppLayout exact path={clientRoutesEnum.FAQ} RenderComponent={FaqPage} />
      <AppLayout
        exact
        path={clientRoutesEnum.CATEGORY_DETAIL}
        RenderComponent={FaqPage}
      />
      <AppLayout
        exact
        path={'/:enterprise/faq/:id/:articleId'}
        RenderComponent={FaqPage}
      />
      <Route exact path="*" render={NotFound} />
    </Switch>
  );
};

export default AppRoutes;
