import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { UploadDTOList } from 'types/cases';

const slideStyles = {
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const rightArrowStyles = {
  position: 'absolute',
  top: '50%',
  transform: 'translate(0, -50%)',
  right: '0px',
  fontSize: '32px',
  color: '#000',
  zIndex: 1,
  cursor: 'pointer',
};

const leftArrowStyles = {
  position: 'absolute',
  top: '50%',
  transform: 'translate(0, -50%)',
  left: '0px',
  fontSize: '32px',
  color: '#000',
  zIndex: 1,
  cursor: 'pointer',
};

const sliderStyles = {
  position: 'relative',
  height: '100%',
};

const dotsContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  // zIndex: 1,
};

const dotStyleActive = {
  margin: '0 3px',
  cursor: 'pointer',
  fontSize: '20px',
};

const dotStyle = {
  ...dotStyleActive,
  color: 'grey',
};

const ImageSlider = ({
  slides,
  selectedIndex,
}: {
  slides: Partial<UploadDTOList>[];
  selectedIndex: number;
}) => {
  const [currentIndex, setCurrentIndex] = useState(selectedIndex);
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };
  const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${
      slides[currentIndex].url || slides[currentIndex].fileUrl
    })`,
  };

  useEffect(() => {
    const handleNavigationPress = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        goToNext();
      } else if (event.key === 'ArrowLeft') {
        goToPrevious();
      }
    };
    document.addEventListener('keydown', handleNavigationPress);
    return () => {
      document.removeEventListener('keydown', handleNavigationPress);
    };
  }, [goToNext, goToPrevious]);

  return (
    <Box sx={sliderStyles}>
      <Box>
        <Box onClick={goToPrevious} sx={leftArrowStyles}>
          ❰
        </Box>
        <Box onClick={goToNext} sx={rightArrowStyles}>
          ❱
        </Box>
      </Box>
      <div style={slideStylesWidthBackground} />
      <Box style={dotsContainerStyles}>
        {slides.map((slide, slideIndex) => (
          <Box
            style={slideIndex === currentIndex ? dotStyleActive : dotStyle}
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            ●
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImageSlider;
