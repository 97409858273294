import { DispatchType } from './../../types/store';
import { LogoStateEnum } from 'enums/actions';
import { asyncTaskStopAction, asyncTaskStartAction } from './asyncTaskActions';
import { ApiError } from 'types/api';
import logoService from '../../services/logo';

export const getLogoSuccessAction = (payload: string) => ({
  type: LogoStateEnum.GET_LOGO,
  payload,
});

export const getLogoAction = (pageUrl: string) => {
  const taskId = LogoStateEnum.GET_LOGO;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const data: any = await logoService.GetLogo({ pageUrl });
      if (data) {
        dispatch(getLogoSuccessAction(data));
      }
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};
