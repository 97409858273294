import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';
import TextButton from 'components/TextButton';
import CustomizedTimeline from 'components/Timeline';
import Message from 'components/Message';
import styles from './styles';
import Document from 'components/Document';
import Photo from 'components/Photos';
import { AddButtonText } from 'enums/AddButtonText';
import { useQuery } from 'utils/useQuery';
import MessageIcon from 'icons/MessageIcon';
import DocumentIcon from 'icons/DocumentIcon';
import GalleryIcon from 'icons/GalleryIcon';
import ActiveIcon from 'icons/ActiveIcon';
import { DispatchType } from 'types/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetTimeLine,
  getCaseDetailById,
  getCaseDocuments,
  getCaseMessages,
  getCasePhotos,
  postMessageAction,
  uploadDocumentsAction,
  uploadPhotosAction,
} from 'store/actions/caseActions';
import {
  getCaseDetail,
  getDocuments,
  getMessages,
  getPhotos,
  getTimeline,
} from 'store/selectors';
import AddAttachmentModal from 'components/AddAttachmentModal';
import { AttachmentFormData, MessageFormData } from 'enums/formDataEnum';
import {
  DocumentDTOList,
  TicketMessageResponseDTOList,
  UploadDTOList,
} from 'types/cases';
import parse from 'html-react-parser';
import { CaseStateEnum } from 'enums/caseStateEnum';
import { CaseStatusEnum } from 'enums/caseStatusEnum';

interface CaseDetailProps {
  openDetail: boolean;
  closeDetail: () => void;
  closeAddForm: () => void;
}

const ButtonTextOptions: { [key: number]: string } = {
  0: AddButtonText.ADD_MESSAGE,
  1: AddButtonText.ADD_DOCUMENT,
  2: AddButtonText.ADD_PHOTO,
};

const BgColor: { [key: number]: boolean } = {
  0: false,
  1: true,
  2: true,
};

const CaseState: { [key: string]: string } = {
  [CaseStateEnum.CASE]: CaseStatusEnum.ACTIVE,
  [CaseStateEnum.CASE_CLOSED]: CaseStatusEnum.CLOSED,
};

const CaseDetail = ({
  openDetail,
  closeDetail,
  closeAddForm,
}: CaseDetailProps) => {
  const classes = styles();
  const [addLabel, setAddLabel] = useState(ButtonTextOptions[0]);
  const [tabIndex, setTabIndex] = useState(0);
  // for modals
  const [isAddMessage, setIsAddMessage] = useState(false);
  const [isAddAttachment, setIsAddAttachment] = useState(false);
  // render background of message/document/photo conditionally based on state
  const [isWhiteBgColor, setIsWhiteBgColor] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const query = useQuery();
  const caseId = query.get('case-id');

  const dispatch: DispatchType = useDispatch();

  const caseDetail = useSelector(getCaseDetail);
  const messages = useSelector(getMessages);
  const documents = useSelector(getDocuments);
  const photos = useSelector(getPhotos);
  const timeline = useSelector(getTimeline);
  const lastTime = timeline[0] ? timeline[0].dateTime : 0;

  // scroll into view ref
  const messageScrollIntoViewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chatContainerElement = messageScrollIntoViewRef.current;
    if (chatContainerElement) {
      chatContainerElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [messages]);

  const handleTabChange = (event: ChangeEvent<{}>, newTabIndex: number) => {
    setTabIndex(newTabIndex as number);
    setAddLabel(ButtonTextOptions[newTabIndex]);
    setIsWhiteBgColor(BgColor[newTabIndex]);
  };

  const handleAddButton = () => {
    if (tabIndex === 0) {
      setIsAddMessage(true);
    } else {
      setIsAddAttachment(true);
    }
  };

  const handleSendMessage = (message: string, attachment: File | undefined) => {
    const formData = new FormData();
    formData.append(MessageFormData.CONTENT, message);
    attachment && formData.append(MessageFormData.ATTACHMENTS, attachment);
    caseId && formData.append(MessageFormData.TICKET_ID, caseId);
    dispatch(postMessageAction(formData));
  };

  const handleFileInputChange = (
    description: string,
    attachment: File | undefined,
  ) => {
    const formData = new FormData();
    formData.append(AttachmentFormData.DESCRIPTION, description);
    if (!attachment || !caseId) {
      return;
    }
    formData.append(AttachmentFormData.FILE, attachment);
    if (tabIndex === 1) {
      dispatch(uploadDocumentsAction(formData, caseId));
    }
    if (tabIndex === 2) {
      dispatch(uploadPhotosAction(formData, caseId));
    }
  };

  useEffect(() => {
    if (caseId) {
      dispatch(getCaseDetailById(caseId));
      dispatch(getCaseMessages(caseId));
      dispatch(getCaseDocuments(caseId));
      dispatch(getCasePhotos(caseId));
      dispatch(GetTimeLine(caseId));
    }
  }, [caseId]);

  useEffect(() => {
    if (caseId) {
      if (tabIndex === 0) {
        dispatch(getCaseMessages(caseId));
      } else if (tabIndex === 1) {
        dispatch(getCaseDocuments(caseId));
      } else {
        dispatch(getCasePhotos(caseId));
      }
    }
  }, [tabIndex]);

  useEffect(() => {
    if (!caseId) {
      closeDetail();
      closeAddForm();
    }
  }, [caseId]);

  return (
    <>
      <Box
        className="transition"
        style={{
          width: isMobile ? '100%' : openDetail ? '55%' : '0%',
          display: openDetail ? 'block' : 'none',
          backgroundColor: isMobile ? '' : '#f1f1f1',
          overflow: isMobile ? 'scroll' : 'hidden',
        }}
      >
        <Box className={classes.main}>
          <Box className={classes.detail}>
            <Box sx={{ marginBottom: 25 }}>
              <Box className={classes.headerWrapper}>
                <Box className={classes.header}>
                  <Typography className={classes.caseId} variant="h2">
                    #{caseDetail?.serialNumber}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={
                      caseDetail?.status === 'TICKET'
                        ? classes.isActive
                        : classes.isClosed
                    }
                  >
                    {caseDetail?.status === 'CASE' && <ActiveIcon />}
                    {CaseState[caseDetail?.status || CaseStateEnum.TICKET]}
                  </Typography>
                </Box>
                {isMobile && (
                  <Box className={classes.addButton}>
                    <TextButton
                      label={`+ Add ${addLabel}`}
                      className={classes.addButtonMobile}
                      onClick={handleAddButton}
                    />
                  </Box>
                )}
              </Box>
              <Typography className={classes.titleText} variant="body1">
                {parse(caseDetail?.description ?? '')}
              </Typography>
            </Box>

            <Box
              sx={{
                borderRadius: '10px',
                flex: 1,
                display: 'flex',
                flexDirection: isMobile ? 'column-reverse' : 'column',
                gridGap: 20,
                height: isMobile ? '550px' : 'calc(100vh - 240px)',
              }}
              className={isWhiteBgColor ? classes.whiteBackground : 'none'}
            >
              <Box className={classes.options}>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  className={classes.tabs}
                >
                  <Tab
                    icon={
                      <Badge
                        overlap="rectangular"
                        badgeContent={messages?.length}
                        classes={{
                          badge: classes.badge,
                        }}
                        color="error"
                        max={10}
                      >
                        <MessageIcon />
                      </Badge>
                    }
                    className={classes.tab}
                  />
                  <Tab
                    icon={
                      <Badge
                        classes={{
                          badge: classes.badge,
                        }}
                        overlap="rectangular"
                        badgeContent={documents.length}
                        color="error"
                        max={10}
                      >
                        <DocumentIcon />
                      </Badge>
                    }
                    className={classes.tab}
                  />
                  <Tab
                    icon={
                      <Badge
                        overlap="rectangular"
                        badgeContent={photos?.length}
                        classes={{
                          badge: classes.badge,
                        }}
                        color="error"
                        max={10}
                      >
                        <GalleryIcon />
                      </Badge>
                    }
                    className={classes.tab}
                  />
                </Tabs>
              </Box>
              {!isMobile && (
                <Box className={classes.addButton}>
                  <TextButton
                    label={`Add ${addLabel}`}
                    onClick={handleAddButton}
                  />
                </Box>
              )}
              <Box
                style={{
                  overflow: 'hidden',
                  overflowY: 'auto',
                  height: 'calc(100vh - 420px)',
                }}
              >
                {tabIndex === 0 && (
                  <>
                    <div>
                      {messages.length > 0 ? (
                        messages
                          .sort((m1, m2) => {
                            const date1 = new Date(m1.createdAt).getTime();
                            const date2 = new Date(m2.createdAt).getTime();
                            return date1 - date2;
                          })
                          .map((message: TicketMessageResponseDTOList) => {
                            return (
                              <Message key={message.id} message={message} />
                            );
                          })
                      ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          No messages
                        </Box>
                      )}
                    </div>
                    <div ref={messageScrollIntoViewRef} />
                  </>
                )}
                {tabIndex === 1 && (
                  <Box className={classes.documents}>
                    {documents.length > 0 ? (
                      documents.map((document: DocumentDTOList) => {
                        return (
                          <Document
                            key={`${document.url}-${document.createdDate}`}
                            document={document}
                            caseId={caseId || ''}
                          />
                        );
                      })
                    ) : (
                      <Box>No documents</Box>
                    )}
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box className={classes.photos}>
                    {photos.length > 0 ? (
                      photos.map((photo: UploadDTOList, index: number) => {
                        return (
                          <Photo
                            key={photo.url + index}
                            photo={photo}
                            index={index}
                            caseId={caseId || ''}
                          />
                        );
                      })
                    ) : (
                      <Box>No images</Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box className={classes.timestamp}>
            {!!{ lastTime } && (
              <Typography>
                Last action was {moment(lastTime).fromNow()}
              </Typography>
            )}

            <CustomizedTimeline timeline={timeline} />
          </Box>
        </Box>
        {/* modal for message */}
        <AddAttachmentModal
          open={isAddMessage}
          onClose={() => setIsAddMessage(false)}
          onSend={handleSendMessage}
          attachmentId={addLabel}
        />
        {/* modal for other attachments */}
        <AddAttachmentModal
          open={isAddAttachment}
          onClose={() => setIsAddAttachment(false)}
          onSend={handleFileInputChange}
          attachmentId={addLabel}
        />
      </Box>
    </>
  );
};

export default CaseDetail;
