const colors = {
  pureWhite: '#FFFFFF',
  white1: '#e6e6e6',
  whiteText: '#1a3848',
  black: '#000000',
  black1: '#163849',
  black2: '#2E2F2F',
  black3: '#1b1c1d',
  gray: '#808080',
  gray1: '#E5E6E6',
  gray2: '#F3F4F4',
  gray3: '#00000008',
  gray4: '#798B9B',
  darkGray: '#000000de',
  task: '#F8C168',
  pipeline: '#EE8267',
  delegation: '#7D7FBC',
  account: '#5F6A7C',
  contact: '#4E9B89',
  appointment: '#CEA02D',
  call: '#A5687C',
  campaign: '#68BDD4',
  insight: '#12323C',
  green: '#9AC80E',
  green1: '#8cc290',
  green2: '#669888',
  primary: '#ff7b00',
  secondary: '#798B9B',
  inputBackground: '#f1f1f1',
  activeLink: '#FEF9F5',
  editorBackground: '#FFFFFF',
  primaryHover: '#EC7200',
};

export default colors;
