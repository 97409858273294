import { Box, CardMedia, FormHelperText, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import DropdownWithRequireIndicator from 'components/DropdownWithRequireIndicator';
import FileInput from 'components/FileInput';
import TextButton from 'components/TextButton';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import IconButton from 'components/IconButton';
import TextInput from 'components/TextInput';
import useStyles from './styles';
import RequiredAsterisk from 'components/RequiredAsterisk';
import { authenticated, getListFieldState, sTaskStatus } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType } from 'types/store';
import {
  addNewCase,
  clearField,
  getListField,
  saveDocument,
  savePhoto,
  updateListFieldSuccessAction,
} from 'store/actions/caseActions';
import { CaseField } from 'types/cases';
import {
  INVALID_EMAIL,
  THIS_FIELD_IS_REQUIRED,
  MAXIMUM_FILE_SIZE_ERROR,
} from 'constatnts';
import caseService from 'services/case';
import { getListFormState } from 'store/selectors';
import PdfModal from 'components/PdfModal';
import { FormFieldEnum } from 'enums/formTypeEnum';
import JoditEditor from 'jodit-react';
import { CaseActionTypeEnum } from 'enums/actions';
import { ReadOnlyFieldsEnum } from 'enums/readOnlyFieldsEnum';
import { LocalStorageEnum } from 'enums/storage';
import localStorage from 'utils/localStorage';
import _ from 'lodash';
import TextInputWithRequireIndicator from 'components/TextInputWithRequireIndicator';
import { isEmailField } from 'utils';
import isEmail from 'validator/lib/isEmail';

const buttons = ['bold', 'italic', 'ul', 'ol', 'link'];

const defaultError = {};
interface IProps {
  selectedForm: string;
}

// const MAX_CHARACTER = 2000;
// const MAX_WORD = 30;

const AddCaseForm = ({ selectedForm }: IProps) => {
  const classes = useStyles();

  const [document, setDocument] = useState<File | undefined>();
  const [image, setImage] = useState<File | undefined>();
  const [documentDescription, setDocumentDescription] = useState('');
  const [imageDescription, setImageDescription] = useState('');
  const [error, setError] = useState<any>(defaultError);
  const [dropdownData, setDropDownData] = useState<any>({});
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [pdf, setPdf] = useState<CaseField[]>([]);
  const status = useSelector(sTaskStatus(CaseActionTypeEnum.PUBLISH_ENVELOPE));

  const dispatch: DispatchType = useDispatch();

  const sAuthenticated = useSelector(authenticated);
  const token = localStorage.getItem(LocalStorageEnum.ACCESS_TOKEN);
  const isLoggedIn = sAuthenticated || token;
  const form = useSelector(getListFieldState);
  const allForms = useSelector(getListFormState);

  const config = useMemo(
    () => ({
      readonly: false,
      toolbar: true,
      addNewLine: false,
      spellcheck: true,
      height: 200,
      wrap: true,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      // limitChars: MAX_CHARACTER,
      // limitWords: MAX_WORD,
      hidePoweredByJodit: true,
      additionalStylesheets: ['src/pages/Home/AddCase/JoditEditorStyle.css'],
    }),
    [],
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;
    setError({
      ...error,
      [name]: '',
    });
    const newField = form.find((f) => f.field === name) as CaseField;

    newField.value = value;
    dispatch(updateListFieldSuccessAction([...form]));
  };

  const onAttachFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const name = e.target.name;
    if (name === 'document') {
      setDocument(file);
    } else {
      setImage(file);
    }

    onResetError(`${name}File`);
  };

  const checkExceedMaximumFileSize = (size: number | undefined) => {
    if (!size) return false;

    return size > 1 * 1024 * 1024;
  };

  const onResetError = (name: string) => {
    setError({
      ...error,
      [name]: '',
    });
  };

  const onSubmit = () => {
    for (let i = 0; i < form.length; i++) {
      if (isEmailField(form[i].field) && !isEmail(form[i].value || '')) {
        setError({
          ...defaultError,
          [form[i].field]: INVALID_EMAIL,
        });
        return;
      }
      if (!form[i].value && form[i].isRequired) {
        setError({
          ...defaultError,
          [form[i].field]: THIS_FIELD_IS_REQUIRED,
        });
        return;
      }
    }
    if (error.DESCRIPTION) {
      return;
    }

    if (checkExceedMaximumFileSize(document?.size)) {
      setError({
        ...error,
        documentFile: MAXIMUM_FILE_SIZE_ERROR,
      });

      return;
    }

    if (checkExceedMaximumFileSize(image?.size)) {
      setError({
        ...error,
        imageFile: MAXIMUM_FILE_SIZE_ERROR,
      });

      return;
    }

    const selectedFormDetail = allForms.find(
      (form) => form.id === selectedForm,
    );

    if (document) {
      dispatch(
        saveDocument({ data: document, description: documentDescription }),
      );
    }
    if (image) {
      dispatch(savePhoto({ data: image, description: imageDescription }));
    }

    if (selectedFormDetail?.isESign) {
      const pdfForm = _.cloneDeep(form);
      const typeField = pdfForm.find(
        (f) => f.field === FormFieldEnum.TICKET_TYPE,
      );
      if (typeField && typeField.value) {
        const typePdfDisplay = dropdownData[typeField.field].find(
          (data: any) => data.id === typeField.value,
        );
        typeField.value = typePdfDisplay.description;
      }
      setIsOpenModal(true);
      setPdf(pdfForm);
    } else {
      dispatch(addNewCase(form, selectedForm));
    }
    setDocument(undefined);
    setImage(undefined);
    setDocumentDescription('');
    setImageDescription('');
  };

  const onFocus = async (fieldName: string) => {
    if (!dropdownData[fieldName]) {
      const result = await caseService.FetchDropdownData({
        field: fieldName,
        // page: 0,
        // size: 20,
      });

      dropdownData[fieldName] = result.data.map((c) => ({
        description: c.display,
        id: c.value,
      }));
      setDropDownData({
        ...dropdownData,
      });
    }
  };

  const handleDescriptionChange = (text: string) => {
    const descriptionField = form.find(
      (f) => f.field === FormFieldEnum.DESCRIPTION,
    );
    if (descriptionField) {
      const parser = new DOMParser();
      const parsedDescription = parser.parseFromString(text, 'text/html');
      const trimDescription =
        parsedDescription.documentElement.textContent?.trim() || '';
      descriptionField.value = text;
      if (trimDescription) {
        setError({
          ...error,
          [descriptionField.field]: '',
        });
      } else {
        setError({
          ...error,
          [descriptionField.field]: THIS_FIELD_IS_REQUIRED,
        });
      }
    }
  };

  const handleAttachmentDescriptionChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'document') {
      setDocumentDescription(value);
    }
    if (name === 'image') {
      setImageDescription(value);
    }
  };

  const checkIfReadOnlyField = (fieldName: string) => {
    if (isLoggedIn) {
      return Object.values<string>(ReadOnlyFieldsEnum).includes(fieldName);
    }
    return false;
  };

  useEffect(() => {
    if (status && !status.processing && !status.error) {
      onClear();
      setIsOpenModal(false);
      setDocument(undefined);
      setImage(undefined);
    }
  }, [status]);

  const onClear = () => {
    dispatch(clearField());
  };

  const ImageRender = useMemo(() => {
    return (
      image && (
        <Box display="flex" alignItems="start">
          <CardMedia
            className={classes.img}
            image={URL.createObjectURL(image)}
          />
          <IconButton
            onClick={() => {
              setImage(undefined);

              const imageRef: any = window.document.getElementById('image');
              if (imageRef) {
                imageRef.value = '';
              }

              onResetError('imageFile');
            }}
            className="ml2"
            Icon={<Clear />}
          />
        </Box>
      )
    );
  }, [image]);

  useEffect(() => {
    if (selectedForm) {
      dispatch(getListField(selectedForm));
    }
  }, [selectedForm]);

  return (
    <Box>
      <form id="ticket-form">
        <Box className={classes.submitForm}>
          {form
            .sort((a, b) => a.position - b.position)
            .map((f) =>
              f.field === FormFieldEnum.TICKET_TYPE ? (
                <Box key={f.field} className={classes.inputDiv}>
                  <label className={classes.label} htmlFor="problem">
                    {f.title}
                    {f.isRequired && <RequiredAsterisk />}
                  </label>
                  <DropdownWithRequireIndicator
                    className={classes.textField}
                    name={f.field}
                    values={dropdownData[f.field] || []}
                    currentValue={f.value || ''}
                    onChange={onChange}
                    helperText={error[f.field]}
                    id={f.field}
                    onFocus={() => onFocus(f.field)}
                  />
                </Box>
              ) : f.field === FormFieldEnum.DESCRIPTION ? (
                <Box key={f.field} className={classes.descriptionContainer}>
                  <label className={classes.label} htmlFor="problem">
                    {f.title}
                    {f.isRequired && <RequiredAsterisk />}
                  </label>
                  <Box className={classes.editor}>
                    <JoditEditor
                      value={f.value || ''}
                      config={
                        error[f.field]
                          ? {
                              ...config,
                              style: {
                                background: '#fff6f6',
                              },
                            }
                          : config
                      }
                      onBlur={handleDescriptionChange}
                      className={classes.JoditEditor}
                    />
                    {error[f.field] && (
                      <FormHelperText
                        style={{
                          fontSize: 12,
                          color: '#f44336',
                        }}
                      >
                        {THIS_FIELD_IS_REQUIRED}
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
              ) : (
                <Box key={f.field} className={classes.inputDiv}>
                  <label className={classes.label} htmlFor="firstName">
                    {f.title}
                    {f.isRequired && <RequiredAsterisk />}
                  </label>
                  <TextInput
                    className={classes.textField}
                    name={f.field}
                    type={isEmailField(f.field) ? 'email' : 'text'}
                    value={f.value}
                    onChange={onChange}
                    helperText={error[f.field]}
                    required={f.isRequired}
                    disabled={checkIfReadOnlyField(f.field)}
                  />
                </Box>
              ),
            )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 30,
          }}
        >
          <Box className={classes.fileInput}>
            {/* upload document */}
            <FileInput
              style={{
                marginLeft: 12,
              }}
              label="Attach document"
              onChange={onAttachFile}
              name="document"
              id="document"
            />

            {document && (
              <Box display="flex" alignItems="center">
                <Typography
                  style={{
                    marginLeft: 24,
                    marginTop: 4,
                  }}
                >
                  {(document as File).name}
                </Typography>
                <IconButton
                  onClick={() => {
                    setDocument(undefined);

                    const documentRef: any =
                      window.document.getElementById('document');
                    if (documentRef) {
                      documentRef.value = '';
                    }

                    onResetError('documentFile');
                  }}
                  className="ml2"
                  Icon={<Clear />}
                />
              </Box>
            )}

            {error?.documentFile && (
              <FormHelperText
                style={{
                  marginLeft: 25,
                  marginTop: -8,
                  fontSize: 12,
                  color: '#f44336',
                }}
              >
                {error?.documentFile}
              </FormHelperText>
            )}

            <TextInputWithRequireIndicator
              value={documentDescription}
              name="document"
              onChange={handleAttachmentDescriptionChange}
              helperText={error.document}
              placeholder="Document description"
              className={classes.textField}
            />

            {/* upload image */}
            <FileInput
              style={{
                marginLeft: 12,
              }}
              accept=".jpg,.png,.jpeg,.png"
              label="Attach image"
              onChange={onAttachFile}
              name="image"
              id="image"
            />

            {ImageRender}

            {error?.imageFile && (
              <FormHelperText
                style={{
                  marginLeft: 25,
                  fontSize: 12,
                  color: '#f44336',
                }}
              >
                {error?.imageFile}
              </FormHelperText>
            )}

            <TextInputWithRequireIndicator
              required={!!image}
              value={imageDescription}
              name="image"
              onChange={handleAttachmentDescriptionChange}
              helperText={error.image}
              placeholder="Image description"
              className={classes.textField}
            />
          </Box>
          <Box className="center-root mt2" mb={4}>
            <TextButton
              label="Submit"
              onClick={onSubmit}
              actionId={CaseActionTypeEnum.ADD_NEW_CASE}
            />
          </Box>
        </Box>
      </form>
      <PdfModal
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        pdf={pdf}
        pdfFormData={form}
        selectedForm={selectedForm}
      />
    </Box>
  );
};

export default AddCaseForm;
