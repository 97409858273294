export enum apiRoutesEnum {
  LOGIN = 'self-service/auth/login',
  CATEGORIES = 'self-service/categories',
  ARTICLES = 'self-service/articles/categories',
  LOGO = 'self-service/setting/logo',
  MENU = 'self-service/setting/menu',
  USER_INFORMATION_FORM = 'self-service/user-information/user-information-form',
  UPDATE_USER_INFORMATION = 'self-service/user-information/update-information',
  CHANGE_PASSWORD = 'self-service/user-information/change-password',
  USER_AVATAR = 'self-service/user-information/avatar',
  REGISTER = 'self-service/auth/register',
  USER_LANGUAGE = 'self-service/user-information/get-language',
  GET_LIST_LANGUAGE = 'self-service/user-information/get-list-languages',
  UPDATE_USER_LANGUAGE = 'self-service/user-information/update-language',
  GET_ALL_CASES = 'self-service/cases/get-list-case',
  GET_CASE_DETAIL_BY_ID = 'self-service/cases',
  GET_CASE_MESSAGES = 'self-service/cases/list-message-for-ticket',
  GET_CASE_DOCUMENTS = 'self-service/cases/documents',
  GET_CASE_PHOTOS = 'self-service/cases/photos',
  GET_LIST_FIELD = 'self-service/cases/get-form-field-list',
  GET_DROPDOWN_DATA = 'self-service/case/get-dropdown-options',
  SAVE_CASE = 'self-service/cases/save',
  GET_LIST_FORM = 'self-service/cases/get-form-list',
  POST_MESSAGE = 'self-service/cases/reply-message',
  POST_DOCUMENT = 'self-service/cases/document/add',
  POST_PHOTO = 'self-service/cases/photo/add',
  GET_TIMELINE = 'self-service/cases/list-timeline-for-ticket',
  PUBLISH_ENVELOPE = 'self-service/cases/esign/publishEnvelope',
  // REVAMP APIS
  FORGOT_PASSWORD = 'service-portal/auth/forgot-password',
  LOGIN_REVAMP = 'service-portal/auth/login',
  CATEGORIES_REVAMP = 'service-portal/faq/categories',
  REGISTER_REVAMP = 'service-portal/auth/register',
  ARTICLES_REVAMP = 'service-portal/faq/articles/categories',
  GET_ARTICLE_DETAIL = 'service-portal/faq/articles',
  // user information revamp
  USER_INFORMATION_FORM_REVAMP = 'service-portal/user-information/user-form-data',
  CHANGE_PASSWORD_REVAMP = 'service-portal/user-information/change-password',
  // settings
  MENU_REVAMP = 'service-portal/self-service',
  LOGO_REVAMP = 'service-portal/self-service/logo',
  // case
  GET_ALL_CASES_REVAMP = 'service-portal/case',
  GET_LIST_FORM_REVAMP = 'service-portal/case/form',
  GET_LIST_FIELD_REVAMP = 'service-portal/case/form-field',
  GET_DROPDOWN_DATA_REVAMP = 'service-portal/case/get-dropdown-options',
  SAVE_CASE_REVAMP = 'service-portal/case',
  GET_CASE_DETAIL_BY_ID_REVAMP = 'service-portal/case/detail',
  GET_TIMELINE_REVAMP = 'service-portal/case/ticket-timeline',
  CASE_DOCUMENTS_REVAMP = 'service-portal/case/document',
  CASE_PHOTOS_REVAMP = 'service-portal/case/photo',
  CASE_MESSAGES_REVAMP = 'service-portal/case/ticket-message',
  USER_AVATAR_REVAMP = 'service-portal/user-information/avatar',
  PUBLISH_ENVELOPE_REVAMP = 'service-portal/case/esign/publishEnvelope',
  USER_LANGUAGE_REVAMP = 'service-portal/user-information/user-language',
  GET_LIST_LANGUAGE_REVAMP = 'service-portal/user-information/language',
}

export enum clientRoutesEnum {
  HOME = '/:enterprise',
  MY_CASES = '/:enterprise',
  SIGN_IN = '/:enterprise/sign-in',
  SIGN_UP = '/:enterprise/sign-up',
  SUBMIT_TICKET = '/:enterprise/submit-ticket',
  FAQ = '/:enterprise/faq',
  CATEGORY_DETAIL = '/:enterprise/faq/:id',
  MY_INFORMATION = '/:enterprise/my-information',
  ARTICLE_DETAIL = '/:enterprise/faq/:id/articleId/:id',
}
