import React, { useState } from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Box } from '@material-ui/core';
import { DocumentDTOList } from 'types/cases';
import styles from './styles';
import IconButton from 'components/IconButton';
import { DispatchType } from 'types/store';
import { useDispatch } from 'react-redux';
import BoldCloseIcon from 'icons/BoldCloseIcon';
import ConfirmModal from 'components/ConfirmModal';
import CommonModal from 'components/CommonModal';
import { AddButtonText } from 'enums/AddButtonText';
import parse from 'html-react-parser';

interface IProps {
  document: DocumentDTOList;
  caseId: string;
}
const Document = ({ document, caseId }: IProps) => {
  const classes = styles();
  const [isDelete, setIsDelete] = useState(false);

  const dispatch: DispatchType = useDispatch();

  const downloadFile = () => {
    window.location.href = document.url;
  };

  const handleDeleteDocument = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDelete(true);
  };

  return (
    <Box className={classes.container}>
      <a href={document.url} download onClick={downloadFile}>
        <InsertDriveFileIcon className={classes.icon} />
      </a>
      <IconButton
        Icon={<BoldCloseIcon />}
        onClick={(event) => handleDeleteDocument(event)}
        className={classes.closeButton}
      />
      <Box>{parse(decodeURIComponent(document.description))}</Box>
      <CommonModal
        isOpen={isDelete}
        onClose={() => setIsDelete(false)}
        className={classes.confirmDelete}
      >
        <ConfirmModal
          title={AddButtonText.ADD_DOCUMENT}
          onClose={() => setIsDelete(false)}
          caseId={caseId}
          url={document.url}
        />
      </CommonModal>
    </Box>
  );
};

export default Document;
