export function isImage(url: string) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

export function checkAttachmentType(file: File) {
  const fileExtension = file.name.replace(/^.*\./, '');
  const validImageExtension = ['jpg', 'png', 'jpeg', 'png'];
  if (validImageExtension.includes(fileExtension)) {
    return true;
  } else {
    return false;
  }
}
