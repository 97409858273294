import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  timeline: {
    zIndex: 1,
    minHeight: 80,
    display: 'flex',
    alignItems: 'flex-start',
    '&:before': {
      content: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 54,
    },
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.divider,
  },
  typography: {
    fontWeight: 600,
    fontSize: '12px',
    marginTop: 10,
  },
  backgroundWhite: {
    background: 'white',
  },
  content: {
    marginLeft: 10,
    padding: 0,
    marginTop: -8,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginTop: 2,
      justifyContent: 'space-between',
    },
  },
  subtitle: {
    fontSize: 12,
    color: '#798B9B',
    lineHeight: '150%',
  },
}));

export default useStyles;
