import React, { useEffect, useMemo } from 'react';
import './index.css';
import AppRoutes from 'components/Routes/AppRoutes';
import AuthRoutes from 'components/Routes/AuthRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { LocalStorageEnum } from 'enums/storage';
import LocalStorage from 'utils/localStorage';
import { authenticated, getAppState } from 'store/selectors';
import { getUser } from 'store/actions/loginActions';
import { getCategories } from 'store/actions/categoryActions';
import { DispatchType } from 'types/store';
import NotFound from 'pages/NotFound';
import { getLogoAction } from 'store/actions/logoActions';
import { getMenuAction } from 'store/actions/menuAction';
import { useHistory } from 'react-router-dom';

function AppComponent() {
  const isStoreAuthenticated = useSelector(authenticated);
  const token = LocalStorage.getItem(LocalStorageEnum.ACCESS_TOKEN);
  const isAuthenticated = token || isStoreAuthenticated;
  const dispatch: DispatchType = useDispatch();
  const { notFound } = useSelector(getAppState);
  const enterprise = location.pathname.split('/');
  const routeHistory = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUser());
    }
    dispatch(getLogoAction(enterprise[1]));
    dispatch(getMenuAction(enterprise[1]));
    dispatch(getCategories(enterprise[1] || ''));
  }, []);

  const AppComponent = useMemo(() => {
    // navigate to login page immediately!
    if (notFound || !enterprise[1]) {
      return <NotFound />;
    }
    if (!isAuthenticated) {
      if (!enterprise[2]) {
        routeHistory.push(`${enterprise[1]}/sign-in`);
      }
      return <AuthRoutes />;
    }
    return <AppRoutes />;
  }, [isAuthenticated, notFound]);

  return AppComponent;
}

export default AppComponent;
