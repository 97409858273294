import React from 'react';

const LogoutIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.21582 6.81063C8.47415 3.93064 10.0158 2.75464 13.3908 2.75464H13.4992C17.2242 2.75464 18.7158 4.18664 18.7158 7.76264V12.9786C18.7158 16.5546 17.2242 17.9866 13.4992 17.9866H13.3908C10.0408 17.9866 8.49915 16.8266 8.22415 13.9946"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3007 10.3625H3.81738"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.67448 7.68237L2.88281 10.3623L5.67448 13.0423"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default LogoutIcon;
