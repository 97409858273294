import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderLeft: `1px solid ${theme.colors.inputBackground}`,
    padding: '0px 16px',
    borderRadius: '0px 0px 15px 0px',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px',
      margin: 0,
      borderRadius: 0,
      border: 'none',
      // height: 'auto',
    },
  },
  header: {
    padding: '21px 0px 0px  2px',
    marginBottom: 32,
  },
  title: {
    color: `${theme.colors.primary}`,
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  img: {
    width: 300,
    height: 300,
    marginLeft: 24,
    marginTop: 12,
    [theme.breakpoints.down('sm')]: {
      width: 125,
      height: 125,
    },
  },
  submitForm: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 42,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  descriptionContainer: {
    display: 'flex',
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  inputDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  label: {
    display: 'flex',
    width: 160,
    color: theme.colors.black,
    fontSize: 12,
    lineHeight: '30px',
  },
  textField: {
    // width: '391px!important',
    padding: '0!important',
    [theme.breakpoints.down('sm')]: {
      // width: '366px!important',
    },
  },
  editor: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > div > div > div:nth-child(2)': {
      backgroundColor: `${theme.colors.editorBackground}!important`,
      color: `${theme.colors.black}`,
    },
    '& > div > div > div:nth-child(3)': {
      display: 'none!important',
    },
  },
  fileInput: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 12,
  },
  JoditEditor: {
    backgroundColor: 'red',
  },
}));

export default useStyles;
