import React, { useEffect } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import AddCaseForm from './AddCaseForm';
import AddCaseHeader from './AddCaseHeader';
import clsx from 'clsx';
import useStyles from './styles';
import { calculateWidth } from 'utils/calculateWidth';
import Dropdown from 'components/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getListFormState, getSelectedFormState } from 'store/selectors';
import { DispatchType } from 'types/store';
import {
  getListForm,
  selectFormSuccessAction,
} from 'store/actions/caseActions';

interface IProps {
  shouldAdd: boolean;
  closeShouldAdd: () => void;
}

const AddCase = ({ shouldAdd, closeShouldAdd }: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const width = calculateWidth(shouldAdd, matches);
  const selectedForm = useSelector(getSelectedFormState);
  const forms = useSelector(getListFormState);

  const dispatch: DispatchType = useDispatch();

  const onChange = (event: React.ChangeEvent<{ value: any }>) => {
    dispatch(selectFormSuccessAction(event.target.value));
  };

  useEffect(() => {
    dispatch(getListForm());
  }, []);

  return (
    <Box
      className={clsx('transition', classes.container)}
      style={{
        width: `${width}`,
        display: shouldAdd ? 'block' : 'none',
      }}
    >
      <AddCaseHeader closeShouldAdd={closeShouldAdd} />
      <Box
        style={{
          width: '100%',
          marginBottom: 24,
        }}
      >
        <Dropdown
          values={forms
            .sort((a, b) => a.position - b.position)
            .map((f) => ({
              id: f.id,
              description: f.name,
            }))}
          currentValue={selectedForm}
          onChange={onChange}
        />
      </Box>
      <AddCaseForm selectedForm={selectedForm} />
    </Box>
  );
};

export default AddCase;
