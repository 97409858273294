import { CaseOpenTypeEnum } from 'enums/actions';

export const openAddForm = () => ({
  type: CaseOpenTypeEnum.OPEN_ADD_FORM,
});

export const closeAddForm = () => ({
  type: CaseOpenTypeEnum.CLOSE_ADD_FORM,
});

export const openDetail = () => ({
  type: CaseOpenTypeEnum.OPEN_DETAIL,
});

export const closeDetail = () => ({
  type: CaseOpenTypeEnum.CLOSE_DETAIL,
});
