/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import localStorageUtils from 'utils/localStorage';
import { LocalStorageEnum } from 'enums/storage';
import EventEmitter from 'utils/eventEmitter';

const eventChannel = new EventEmitter();
let accessToken =
  localStorageUtils.getItem(LocalStorageEnum.ACCESS_TOKEN) || '';

const pagesUrl = window.location.pathname.split('/');

const AXIOS = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  headers: {
    Authorization: `${accessToken}`,
    'Content-Type': 'application/json',
    'pages-url': pagesUrl[1],
  },
  timeout: 60000,
});

const AXIOS_REVAMP = axios.create({
  baseURL: process.env.REACT_APP_BE_REVAMP_URL,
  headers: {
    Authorization: `${accessToken}`,
    'Content-Type': 'application/json',
    'pages-url': pagesUrl[1],
  },
  timeout: 60000,
});
AXIOS.interceptors.request.use((config) => {
  // Use latest 'accessToken' in auth header when reference is expired
  const latestAccessToken = localStorageUtils.getItem(
    LocalStorageEnum.ACCESS_TOKEN,
  );

  // renew accessToken
  if (latestAccessToken !== accessToken && latestAccessToken) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    config.headers!.Authorization = `${latestAccessToken}`;
  }

  return config;
});

AXIOS.interceptors.response.use(
  (response) => {
    if (response.data.code) {
      return Promise.reject(response.data);
    }

    if (response.status === 200) {
      return response.data;
    }
    return response;
  },
  (interceptorError) => {
    const originalRequest = interceptorError.config;
    if (
      interceptorError.response &&
      (interceptorError.response.status === 401 ||
        interceptorError.response.status === 403)
    ) {
      // only make renew token when there are no renew request being processed
      // TODO comment 2 lines below only for now since some apis are fails
      // localStorageUtils.removeItem(LocalStorageEnum.ACCESS_TOKEN);
      // localStorageUtils.removeItem(LocalStorageEnum.FILE_STORAGE_ACCESS_TOKEN);
      accessToken = '';
      const enterprise = location.pathname.split('/');
      if (!enterprise[1]) {
        window.location.href = `${enterprise[1]}/sign-in`;
      }
    }
    return Promise.reject(interceptorError);
  },
);

AXIOS_REVAMP.interceptors.request.use((config) => {
  // Use latest 'accessToken' in auth header when reference is expired
  const latestAccessToken = localStorageUtils.getItem(
    LocalStorageEnum.ACCESS_TOKEN,
  );

  // renew accessToken
  if (latestAccessToken !== accessToken && latestAccessToken) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    config.headers!.Authorization = `${latestAccessToken}`;
  }

  return config;
});

AXIOS_REVAMP.interceptors.response.use(
  (response) => {
    if (response.data.code) {
      return Promise.reject(response.data);
    }

    if (response.status === 200) {
      return response.data;
    }
    return response;
  },
  (interceptorError) => {
    const originalRequest = interceptorError.config;
    if (
      interceptorError.response &&
      (interceptorError.response.status === 401 ||
        interceptorError.response.status === 403)
    ) {
      // only make renew token when there are no renew request being processed
      // TODO comment 2 lines below only for now since some apis are fails
      localStorageUtils.removeItem(LocalStorageEnum.ACCESS_TOKEN);
      localStorageUtils.removeItem(LocalStorageEnum.FILE_STORAGE_ACCESS_TOKEN);
      accessToken = '';
      const enterprise = location.pathname.split('/');
      if (!enterprise[1]) {
        window.location.href = `${enterprise[1]}/sign-in`;
      }
    }
    return Promise.reject(interceptorError);
  },
);

export default AXIOS;
export { AXIOS_REVAMP };
