import React, { useEffect, useRef } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import colors from 'material/colors';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { sTaskStatus } from 'store/selectors';
import ReactLoading from 'react-loading';
import { DispatchType } from 'types/store';
import { updateAppStateAction } from 'store/actions/appActions';

interface IProps {
  loading?: boolean;
  className?: string;
  onClick?: any;
  label: string;
  disableRipple?: boolean;
  disabled?: boolean;
  icon?: any;
  enterPressDependencies?: any[];
  actionId?: string;
}

const TextButton = ({
  loading,
  className,
  onClick,
  disableRipple,
  disabled,
  label,
  icon,
  enterPressDependencies = [],
  actionId,
}: IProps) => {
  const classes = useStyles();
  const status = useSelector(sTaskStatus(actionId || ''));
  const dispatch: DispatchType = useDispatch();
  const shouldShowSnackBar = useRef(false);

  const callback = (event: KeyboardEvent) => {
    if (event.keyCode === 13) {
      onClick?.();
    }
  };
  useEffect(() => {
    if (!_.isEmpty(enterPressDependencies)) {
      document.addEventListener('keypress', callback);
    }
    return () => {
      document.removeEventListener('keypress', callback);
    };
  }, [...enterPressDependencies]);

  useEffect(() => {
    if (
      !actionId ||
      !status ||
      status.processing ||
      !shouldShowSnackBar.current
    )
      return;
    if (status.error) {
      dispatch(
        updateAppStateAction({
          snackbar: {
            open: true,
            message:
              status.error.response?.data.message || 'Something went wrong',
            severity: 'error',
          },
        }),
      );
    } else if (!status.processing) {
      dispatch(
        updateAppStateAction({
          snackbar: {
            open: true,
            message: 'Success',
            severity: 'success',
          },
        }),
      );
    }
    shouldShowSnackBar.current = false;
  }, [actionId, status]);

  return (
    <Button
      className={clsx(classes.root, className)}
      onClick={() => {
        if (status?.processing) return;
        shouldShowSnackBar.current = true;
        onClick();
      }}
      disableRipple={disableRipple}
      disabled={disabled}
      classes={{
        disabled: classes.disabled,
      }}
    >
      {icon}
      {status?.processing || loading ? (
        <ReactLoading
          className={classes.loading}
          type="spin"
          color="#FFFFFF"
          width={20}
          height={20}
        />
      ) : (
        label
      )}
    </Button>
  );
};

export default TextButton;
const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 11,
    color: theme.colors.pureWhite,
    textTransform: 'none',
    backgroundColor: colors.primary,
    borderRadius: 4,
    padding: '5px 16px',
    '&:hover': {
      backgroundColor: theme.colors.primaryHover,
      color: theme.colors.pureWhite,
    },
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'white',
      // color: theme.colors.primary,
      color: theme.colors.pureWhite,
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '27px',
      '&:hover': {
        // backgroundColor: 'white',
        color: theme.colors.primary,
      },
    },
  },
  disabled: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.gray,
  },
  loading: {
    marginLeft: 8,
  },
}));
