import { FaqActionTypeEnum } from 'enums/actions';
import { FaqActionTypes, FaqReducerState } from '../../types/faq';

export const initialFaqState: FaqReducerState = {
  categoryId: '',
  faqs: [],
};

export const faqReducer = (state = initialFaqState, action: FaqActionTypes) => {
  switch (action.type) {
    case FaqActionTypeEnum.GET_ALL_FAQS:
      return {
        ...state,
        categoryId: action.payload.categoryId,
        faqs: action.payload.faqs,
      };
    case FaqActionTypeEnum.SELECTED_FAQ:
      return {
        ...state,
        selectedFaq: action.payload,
      };
    case FaqActionTypeEnum.GET_DETAIL_ARTICLE:
      return {
        ...state,
        selectedArticle: action.payload,
      };
    default:
      return state;
  }
};
