import { CategoryRequest, FaqCategoryRevamp } from 'types/category';
import { apiRoutesEnum } from './../enums/routes';
import { AXIOS_REVAMP } from './axios';

async function GetCategories(
  params: CategoryRequest,
): Promise<FaqCategoryRevamp> {
  return AXIOS_REVAMP.get(
    `${apiRoutesEnum.CATEGORIES_REVAMP}/${params.pageUrl}`,
  );
}

export default {
  GetCategories,
};
