export enum LoginActionTypeEnum {
  LOGOUT = 'api/logout',
  LOGIN = 'LOGIN',
  GET_USER_INFO = 'GET_USER_INFO',
  UPDATE_USER_INFO = 'UPDATE_USER_INFO',
  SAVE_CHANGES = 'SAVE_CHANGES',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  REGISTER_NEW_USER = 'REGISTER_NEW_USER',
  GET_AVATAR = 'GET_AVATAR',
  UPDATE_AVATAR = 'UPDATE_AVATAR',
  GET_USER_LANGUAGE = 'GET_USER_LANGUAGE',
  UPDATE_USER_LANGUAGE = 'UPDATE_USER_LANGUAGE',
  CHANGE_PASSWORD_REVAMP = 'CHANGE_PASSWORD_REVAMP',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export enum CategoryActionTypeEnum {
  GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES',
}

export enum LanguageActionTypeEnum {
  GET_ALL_LANGUAGES = 'GET_ALL_LANGUAGES',
}

export enum FaqActionTypeEnum {
  GET_ALL_FAQS = 'GET_ALL_FAQS',
  GET_DETAIL_FAQS = 'GET_DETAIL_FAQS',
  SELECTED_FAQ = 'SELECTED_FAQ',
  GET_DETAIL_ARTICLE = 'GET_DETAIL_ARTICLE',
}

export enum AppReducerEnum {
  UPDATE_APP_REDUCER = 'UPDATE_APP_REDUCER',
}

export enum AsyncTaskTypeEnum {
  ASYNC_TASK_START = 'ASYNC_TASK_START',
  ASYNC_TASK_STOP = 'ASYNC_TASK_STOP',
  ASYNC_TASK_RESET = 'ASYNC_TASK_RESET',
}

export enum AppStateEnum {
  UPDATE_APP_STATE = 'UPDATE_APP_STATE',
}

export enum LogoStateEnum {
  GET_LOGO = 'GET_LOGO',
}

export enum MenuStateEnum {
  GET_MENU = 'GET_MENU',
}

export enum CaseOpenTypeEnum {
  OPEN_ADD_FORM = 'OPEN_ADD_FORM',
  CLOSE_ADD_FORM = 'CLOSE_ADD_FORM',
  OPEN_DETAIL = 'OPEN_DETAIL',
  CLOSE_DETAIL = 'CLOSE_DETAIL',
}

export enum CaseActionTypeEnum {
  GET_ALL_CASES = 'GET_ALL_CASES',
  GET_CASES_TOTAL_NUMBER = 'GET_CASES_TOTAL_NUMBER',
  GET_CASE_DETAIL_BY_ID = 'GET_CASE_DETAIL_BY_ID',
  GET_MESSAGES_BY_ID = 'GET_MESSAGES_BY_ID',
  GET_DOCUMENTS_BY_ID = 'GET_DOCUMENTS_BY_ID',
  GET_PHOTOS_BY_ID = 'GET_PHOTOS_BY_ID',
  GET_LIST_FIELD_REVAMP = 'GET_LIST_FIELD_REVAMP',
  ADD_NEW_CASE = 'ADD_NEW_CASE',
  GET_LIST_FORM = 'GET_LIST_FORM',
  SELECT_FORM = 'SELECT_FORM',
  POST_MESSAGE = 'POST_MESSAGE',
  POST_DOCUMENT = 'POST_DOCUMENT',
  CASE_DOCUMENTS_REVAMP = 'CASE_DOCUMENTS_REVAMP',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  POST_PHOTO = 'POST_PHOTO',
  DELETE_PHOTO = 'DELETE_PHOTO',
  GET_TIMELINE_REVAMP = 'GET_TIMELINE_REVAMP',
  PUBLISH_ENVELOPE = 'PUBLISH_ENVELOPE',
  SAVE_DOCUMENT = 'SAVE_DOCUMENT',
  SAVE_PHOTO = 'SAVE_PHOTO',
  CLEAN_UP_CASE_DATA = 'CLEAN_UP_CASE_DATA',
}
