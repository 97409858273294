import React, { memo, ReactElement } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface IInput {
  value?: string | number;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  placeholder?: string;
  name?: string;
  multiline?: boolean;
  rows?: number;
  type?: string;
  disabled?: boolean;
  startAdornmentIcon?: ReactElement;
  endAdornmentIcon?: ReactElement;
  error?: boolean;
  helperText?: string;
  required?: boolean;
}
const TextInput = ({
  value,
  onChange,
  className,
  name,
  multiline,
  rows,
  placeholder,
  type,
  startAdornmentIcon,
  endAdornmentIcon,
  disabled,
  helperText,
}: IInput) => {
  const classes = useStyles({ error: !!helperText });

  return (
    <TextField
      value={value}
      onChange={onChange}
      name={name}
      multiline={multiline}
      rows={rows}
      type={type}
      disabled={disabled}
      error={!!helperText}
      helperText={helperText}
      InputProps={{
        startAdornment: startAdornmentIcon,
        endAdornment: endAdornmentIcon,
      }}
      className={clsx(className, classes.root)}
      placeholder={placeholder}
      variant="outlined"
    />
  );
};

export default memo(TextInput);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& .MuiFormHelperText-root': {
      fontSize: 12,
      margin: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'unset',
    },
    '& .PrivateNotchedOutline-legend': {
      border: 'unset',
    },
    '& .MuiInput-root': {
      height: 'inherit',
    },
    '& input': {
      padding: '5px 10px',
      height: 'inherit',
      minWidth: 300,
      fontSize: 11,
      borderRadius: 4,
      border: ({ error }: { error?: boolean }) =>
        error ? '1px solid red' : '1px solid rgba(34,36,38,.15)',
      color: ({ error }: { error?: boolean }) =>
        error ? '#9f3a38' : theme.colors.black,
      background: '#ffffff',
    },
    '& input:focus': {},
    '& input::placeholder': {
      opacity: 0.4,
      fontWeight: 600,
    },
    '& input:focus::placeholder': {
      opacity: 0.5,
    },
  },
}));
