import React from 'react';
import ImgMediaCard from 'components/Card';
import { Link, useRouteMatch } from 'react-router-dom';
import styles from './styles';
import { useSelector } from 'react-redux';
import { getFaqsState } from 'store/selectors';

const CategoryDetails = () => {
  const classes = styles();
  const faqs = useSelector(getFaqsState);
  const match = useRouteMatch();

  const ItemList = faqs
    ?.sort((a, b) => a.position - b.position)
    .map((data) => {
      return (
        <Link
          to={`${match.url}/${data.articleId}`}
          key={data.articleId}
          className={classes.item}
        >
          <ImgMediaCard key={data.articleId} data={data} />
        </Link>
      );
    });

  return <div className={classes.items}>{ItemList}</div>;
};

export default CategoryDetails;
