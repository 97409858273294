import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '10px 10px 25px 10px',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: '10vh',
    left: '10%',
    width: '80%',
    height: '80%',
    zIndex: 999,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '25%',
      top: '30vh',
    },
  },
}));

export default styles;
