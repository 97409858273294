import { LogoStateEnum } from 'enums/actions';
import { Logo, LogoActionTypes } from 'types/logo';

export const initialLogoState: Logo = {
  file: 'logo.png',
  objectId: '',
  objectStorageType: '',
  uuid: '',
};

export const logoReducer = (
  state = initialLogoState,
  action: LogoActionTypes,
) => {
  switch (action.type) {
    case LogoStateEnum.GET_LOGO:
      return {
        ...state,
        file: action.payload,
      };
    default:
      return state;
  }
};
