import React, { useEffect, useState } from 'react';
import CategoryList from '../Categories';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import CategoryDetails from '../CategoryDetails';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryState, getDetailFaq } from 'store/selectors';
import { DispatchType } from 'types/store';
import { getFaqsByCategoryId } from 'store/actions/faqActions';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CategoryIcon from 'icons/CategoryIcon';
import clsx from 'clsx';
import CategoryListMobile from '../Categories/CategoryListMobile';
import Item from 'components/Item';
import LoadingComponent from 'components/LoadingComponent';

const Faq = () => {
  const match = useRouteMatch();
  const classes = styles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);

  const categories = useSelector(getCategoryState);
  const location = useLocation();
  const history = useHistory();
  const dispatch: DispatchType = useDispatch();
  const { enterprise, ...id } = useParams<any>();
  const shouldShowDetailFaq = useSelector(getDetailFaq);
  const path = location.pathname.split('/');
  const categoryId = path[3] || '';
  useEffect(() => {
    if (location.pathname.endsWith('/faq') && categories?.length > 0) {
      history.replace({
        pathname: `/${enterprise ? `${enterprise}/` : ''}faq/${
          categories[0].categoryId
        }`,
      });
    }
  }, [categories?.length, location?.pathname]);

  useEffect(() => {
    if (categoryId) {
      setLoading(true);
      dispatch(getFaqsByCategoryId(categoryId))
        .then(() => setLoading(false))
        .catch((error) => {
          setLoading(false);
          throw new Error('Cannot fetch data');
        });
    }
  }, [categoryId]);

  return (
    <div>
      {!shouldShowDetailFaq && (
        <Box className={classes.header}>
          <CategoryIcon />
          <Typography className="ml3">Categories</Typography>
        </Box>
      )}
      {isMobile ? (
        <Switch>
          <Route path={`${match.path}/:id/:articleId`}>
            <Item />
          </Route>
          <Route exact path={`${match.path}/:id`}>
            <CategoryListMobile categories={categories} />
          </Route>
        </Switch>
      ) : (
        <Box className={clsx('d-flex', classes.body)}>
          <CategoryList categories={categories} />
          <div className={classes.itemList}>
            <Switch>
              <Route path={`${match.path}/:id/:articleId`}>
                <Item />
              </Route>
              <Route exact path={`${match.path}/:id`}>
                {loading ? <LoadingComponent /> : <CategoryDetails />}
              </Route>
            </Switch>
          </div>
        </Box>
      )}
    </div>
  );
};

export default Faq;
