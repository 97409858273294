import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDetailFaq } from 'store/selectors';
import { FaqCategory } from 'types/category';
import CategoryItem from './CategoryItem';

interface IProps {
  categories: FaqCategory[];
}

const CategoryListMobile = ({ categories }: IProps) => {
  const categoryId = useMemo(() => {
    const path = location.pathname.split('/');
    const categoryId = path[3] || '';
    return categoryId;
  }, [location.pathname]);

  const faq = useSelector(getDetailFaq);

  return (
    <>
      <Box style={{ padding: '0px 24px' }}>
        {categories
          ?.sort((a, b) => a.position - b.position)
          ?.map((c) => (
            <CategoryItem
              key={c.categoryId}
              category={c}
              shouldShowDetail={c.categoryId === categoryId}
            />
          ))}
      </Box>
    </>
  );
};

export default CategoryListMobile;
