import { Box, Modal } from '@material-ui/core';
import SecondaryTextButton from 'components/SecondaryTextButton';
import TextButton from 'components/TextButton';
import TextInput from 'components/TextInput';
import { EMAIL_IS_REQUIRED, INVALID_EMAIL } from 'constatnts';
import React, { ChangeEvent, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import userSvc from 'services/user';
import { LoginActionTypeEnum } from 'enums/actions';
import { DispatchType } from 'types/store';
import { useDispatch } from 'react-redux';
import { updateAppStateAction } from 'store/actions/appActions';
import { styles } from './styles';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 5,
  outline: 'none',
  p: 4,
};

export type ForgotPasswordModalProps = {
  open: boolean;
  handleClose: () => void;
};

export default function ForgotPasswordModal({
  open,
  handleClose,
}: ForgotPasswordModalProps) {
  const classes = styles();

  const dispatch: DispatchType = useDispatch();
  const [email, setEmail] = useState('');
  const [error, setError] = useState({
    email: '',
  });
  const [loading, setLoading] = useState(false);

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;
    setError({
      ...error,
      [name]: '',
    });
    setEmail(value);
  };

  const handleLoginSubmit = async () => {
    try {
      if (!email) {
        setError({
          email: EMAIL_IS_REQUIRED,
        });
        return;
      }
      if (!isEmail(email)) {
        setError({
          email: INVALID_EMAIL,
        });
        return;
      }

      setLoading(true);

      const res = await userSvc.ForgotPassword({ email });
      if (res.success) {
        setLoading(false);
        handleClose();

        dispatch(
          updateAppStateAction({
            snackbar: {
              open: true,
              message: 'New password has been sent to your email',
              severity: 'success',
            },
          }),
        );
      } else {
        setLoading(false);
        dispatch(
          updateAppStateAction({
            snackbar: {
              open: true,
              message: res.message,
              severity: 'error',
            },
          }),
        );
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        updateAppStateAction({
          snackbar: {
            open: true,
            message: 'Something went wrong',
            severity: 'error',
          },
        }),
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <h3>Forgot password</h3>

        <TextInput
          placeholder={'Your e-mail address'}
          name="email"
          type={'email'}
          value={email}
          onChange={onChangeEmail}
          helperText={error.email}
        />

        <div>
          <TextButton
            loading={loading}
            label="OK"
            className={classes.submitButton}
            onClick={handleLoginSubmit}
            actionId={LoginActionTypeEnum.FORGOT_PASSWORD}
          />
          <SecondaryTextButton
            label="Cancel"
            className={classes.cancelButton}
            onClick={handleClose}
          />
        </div>
      </Box>
    </Modal>
  );
}
