import { apiRoutesEnum } from 'enums/routes';
import { Menu, MenuRequest } from 'types/menu';
import { AXIOS_REVAMP } from './axios';

async function GetMenu(params: MenuRequest): Promise<Menu> {
  return AXIOS_REVAMP.get(`${apiRoutesEnum.MENU_REVAMP}`);
}

export default {
  GetMenu,
};
