import React, { useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@material-ui/core';
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  pdf as PdfRender,
} from '@react-pdf/renderer';
import styles from './styles';
import { CaseField, PublishEnvelopeDto } from 'types/cases';
import { getListFormState } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType } from 'types/store';
import { publishEnvelope } from 'store/actions/caseActions';
import { ESignFormDataEnum } from 'enums/eSignFormData';
import TextButton from 'components/TextButton';
import parse from 'html-react-parser';
import Html from 'react-pdf-html';
import { CaseActionTypeEnum } from 'enums/actions';

type Props = {
  open: boolean;
  onClose: () => void;
  pdf: CaseField[];
  pdfFormData: CaseField[];
  selectedForm: string;
};

const stylesSheet = StyleSheet.create({
  documentContainer: {
    width: '100%',
  },
  container: {
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    margin: 'auto',
    paddingBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'row', // must have
    gap: '50px',
    alignItems: 'baseline',
    marginTop: '10px',
  },
  item: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  label: {
    fontSize: 15,
    width: '30%',
    marginLeft: '50px',
  },
  valueContainer: {
    flex: 1,
    flexWrap: 'wrap',
  },
  value: {
    fontSize: 15,
  },
});

const PdfModal = ({ pdf, pdfFormData, open, selectedForm, onClose }: Props) => {
  const classes = styles();
  const dispatch: DispatchType = useDispatch();
  const pdfRef = useRef(null);
  const allForms = useSelector(getListFormState);
  const selectedFormDetail = allForms?.find((form) => form.id === selectedForm);

  const handleClose = () => {
    onClose();
  };

  const generatePdfBlob = async () => {
    const blob = await PdfRender(renderPdfForDownload(pdf)).toBlob();
    const fileURL = window.URL.createObjectURL(blob);
    // let alink = document.createElement('a');
    // alink.href = fileURL;
    // alink.download = 'SamplePDF.pdf';
    // alink.click();
    return blob;
  };

  const handleSend = async () => {
    const formData = new FormData();
    const pdfFile = await generatePdfBlob();
    const publishEnvelopeDto: PublishEnvelopeDto = {
      dataField: pdfFormData,
      formId: selectedForm,
    };
    formData.append(ESignFormDataEnum.FILE, pdfFile);
    formData.append(
      ESignFormDataEnum.CREATE_CASE_REQUEST,
      JSON.stringify(publishEnvelopeDto),
    );
    dispatch(publishEnvelope(formData));
  };

  const renderPdf = (data: CaseField[]) => (
    <Document style={stylesSheet.documentContainer} ref={pdfRef}>
      <Page size="A4">
        <View style={stylesSheet.container}>
          <Text style={stylesSheet.title}>
            Form: {selectedFormDetail?.name}
          </Text>
          {data.map((p) => {
            return (
              <View key={p.field} style={stylesSheet.form}>
                <Text style={stylesSheet.label}>{p.title}</Text>
                <View style={stylesSheet.valueContainer}>
                  <Text style={stylesSheet.value}>{parse(p.value || '')}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );

  const renderPdfForDownload = (data: CaseField[]) => (
    <Document style={stylesSheet.documentContainer} ref={pdfRef}>
      <Page size="A4">
        <View style={stylesSheet.container}>
          <Text style={stylesSheet.title}>
            Form: {selectedFormDetail?.name}
          </Text>
          {data.map((p) => {
            return (
              <View key={p.field} style={stylesSheet.form}>
                <Text style={stylesSheet.label}>{p.title}</Text>
                <View style={stylesSheet.valueContainer}>
                  <Html>{p.value || ''}</Html>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );

  return (
    <Dialog open={open} onClose={onClose} className={classes.container}>
      <DialogTitle className={classes.title}>PDF Previews</DialogTitle>
      <DialogContent>
        <Box sx={{ width: 500, height: 800 }}>{renderPdf(pdf)}</Box>
      </DialogContent>
      <DialogActions>
        <TextButton
          className={classes.cancelButton}
          onClick={handleClose}
          label="Cancel"
        />
        <TextButton
          className={classes.sendButton}
          onClick={handleSend}
          label="Send"
          actionId={CaseActionTypeEnum.PUBLISH_ENVELOPE}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PdfModal;
