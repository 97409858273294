import { CaseActionTypeEnum } from 'enums/actions';
import { DispatchType } from './../../types/store';
import { asyncTaskStartAction, asyncTaskStopAction } from './asyncTaskActions';
import { ApiError } from 'types/api';
import caseService from 'services/case';
import { Case, CaseField, Form, UploadDTOList } from 'types/cases';
import { RootStateType as IStore } from '../../types/store';
import { CaseStatusEnumQuery } from 'enums/caseStatusEnum';
import { CaseFormData } from 'enums/caseFormData';

export const getCasesSuccessAction = (payload: any) => {
  return {
    type: CaseActionTypeEnum.GET_ALL_CASES,
    payload,
  };
};

export const getCaseCountSuccessAction = (payload: number) => {
  return {
    type: CaseActionTypeEnum.GET_CASES_TOTAL_NUMBER,
    payload,
  };
};

export const getCaseDetailByIdSuccessAction = (payload: any) => {
  return {
    type: CaseActionTypeEnum.GET_CASE_DETAIL_BY_ID,
    payload,
  };
};

export const addNewCaseSuccessAction = (payload: Case) => {
  return {
    type: CaseActionTypeEnum.ADD_NEW_CASE,
    payload,
  };
};

export const getCaseMessagesByIdSuccessAction = (payload: any) => {
  return {
    type: CaseActionTypeEnum.GET_MESSAGES_BY_ID,
    payload,
  };
};

export const getCasePhotosByIdSuccessAction = (payload: UploadDTOList[]) => {
  return {
    type: CaseActionTypeEnum.GET_PHOTOS_BY_ID,
    payload,
  };
};

export const updateListFieldSuccessAction = (payload: CaseField[]) => {
  return {
    type: CaseActionTypeEnum.GET_LIST_FIELD_REVAMP,
    payload,
  };
};

export const getCaseDocumentsByIdSuccessAction = (payload: any) => {
  return {
    type: CaseActionTypeEnum.GET_DOCUMENTS_BY_ID,
    payload,
  };
};

export const getListFormSuccessAction = (payload: Form[]) => {
  return {
    type: CaseActionTypeEnum.GET_LIST_FORM,
    payload,
  };
};

export const selectFormSuccessAction = (payload: string) => {
  return {
    type: CaseActionTypeEnum.SELECT_FORM,
    payload,
  };
};

export const postMessageSuccessAction = (payload: any) => {
  return {
    type: CaseActionTypeEnum.POST_MESSAGE,
    payload,
  };
};

export const postDocumentSuccessAction = (payload: any) => {
  return {
    type: CaseActionTypeEnum.POST_DOCUMENT,
    payload,
  };
};

export const postPhotoSuccessAction = (payload: any) => {
  return {
    type: CaseActionTypeEnum.POST_PHOTO,
    payload,
  };
};

export const getTimelineSuccessAction = (payload: any) => {
  return {
    type: CaseActionTypeEnum.GET_TIMELINE_REVAMP,
    payload,
  };
};

export const saveDocument = (payload: any) => {
  return {
    type: CaseActionTypeEnum.SAVE_DOCUMENT,
    payload,
  };
};

export const savePhoto = (payload: any) => {
  return {
    type: CaseActionTypeEnum.SAVE_PHOTO,
    payload,
  };
};

export const cleanupCaseList = () => {
  return {
    type: CaseActionTypeEnum.CLEAN_UP_CASE_DATA,
  };
};

export const getCaseDetailById = (caseId: string) => {
  const taskId = CaseActionTypeEnum.GET_CASE_DETAIL_BY_ID;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await caseService.GetCaseDetailById({ caseId });
      dispatch(getCaseDetailByIdSuccessAction(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const getCases = (isActive: boolean, nextKey?: string) => {
  const taskId = CaseActionTypeEnum.GET_ALL_CASES;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      await dispatch(getCaseTotalNumber(isActive));
      const response = await caseService.FetchAllCases(
        isActive ? CaseStatusEnumQuery.ACTIVE : CaseStatusEnumQuery.CLOSED,
        nextKey ? nextKey : null,
      );

      dispatch(
        getCasesSuccessAction({
          data: response.data.items,
          nextKey: response.data.nextKey,
        }),
      );
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const getCaseTotalNumber = (isActive: boolean) => {
  const taskId = CaseActionTypeEnum.GET_CASES_TOTAL_NUMBER;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await caseService.getCount(
        isActive ? CaseStatusEnumQuery.ACTIVE : CaseStatusEnumQuery.CLOSED,
      );
      dispatch(getCaseCountSuccessAction(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const getCaseMessages = (caseId: string) => {
  const taskId = CaseActionTypeEnum.GET_MESSAGES_BY_ID;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response: any = await caseService.FetchCaseMessagesById(
        { caseId },
        { page: 0, size: 10 },
      );
      dispatch(getCaseMessagesByIdSuccessAction(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const getCaseDocuments = (caseId: string) => {
  const taskId = CaseActionTypeEnum.GET_DOCUMENTS_BY_ID;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));

      const response = await caseService.FetchCaseDocumentsById({
        caseId,
        // enterprise,
      });
      dispatch(getCaseDocumentsByIdSuccessAction(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const getCasePhotos = (caseId: string) => {
  const taskId = CaseActionTypeEnum.GET_PHOTOS_BY_ID;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await caseService.FetchCasePhotosById({
        caseId,
      });
      dispatch(getCasePhotosByIdSuccessAction(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const getListField = (formId: string) => {
  const taskId = CaseActionTypeEnum.GET_LIST_FIELD_REVAMP;
  return async (dispatch: DispatchType, getState: () => IStore) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await caseService.FetchListField(formId);
      const userInfoFields = getState().loginReducer.fields;
      userInfoFields.forEach((userField) => {
        const newField = response.data.find(
          (f) => f.field === userField.field,
        ) as CaseField;
        if (newField) {
          newField.value = userField.value;
        }
      });

      dispatch(updateListFieldSuccessAction(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const clearField = () => {
  return async (dispatch: DispatchType, getState: () => IStore) => {
    const fields: CaseField[] = getState().caseReducer.fields;
    const userInfoFields = getState().loginReducer.fields;
    fields.forEach((f) => {
      if (!userInfoFields.find((u) => u.field === f.field)) {
        f.value = '';
      }
    });

    dispatch(updateListFieldSuccessAction(fields));
  };
};

export const addNewCase = (dataField: CaseField[], formId: string) => {
  const taskId = CaseActionTypeEnum.ADD_NEW_CASE;
  return async (dispatch: DispatchType, getState: () => IStore) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await caseService.SaveCase({
        dataField,
        formId,
      });

      const document = getState().caseReducer.documentFile;
      const photo = getState().caseReducer.photoFile;
      const id = response.data;

      if (id) {
        let newId = id;

        if (newId.includes('|')) {
          newId = newId.split('|')[0];
        }

        if (document) {
          const formData = new FormData();
          formData.append(CaseFormData.FILE, document.data);
          formData.append(
            CaseFormData.DESCRIPTION,
            document.description || 'Document',
          );
          await dispatch(uploadDocumentsAction(formData, newId));
        }
        if (photo) {
          const formData = new FormData();
          formData.append(CaseFormData.FILE, photo.data);
          formData.append(
            CaseFormData.DESCRIPTION,
            photo.description || 'Photo',
          );

          await dispatch(uploadPhotosAction(formData, newId));
        }
      }
      dispatch(saveDocument(undefined));
      dispatch(savePhoto(undefined));
      dispatch(clearField());
      dispatch(cleanupCaseList());
      await dispatch(getCases(true));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const getListForm = () => {
  const taskId = CaseActionTypeEnum.GET_LIST_FORM;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await caseService.GetListForm();
      dispatch(getListFormSuccessAction(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const postMessageAction = (formData: any) => {
  const taskId = CaseActionTypeEnum.POST_MESSAGE;
  return async (dispatch: DispatchType, getState: () => IStore) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      await caseService.PostMessage(formData);
      const caseId = getState().caseReducer.selectedCase?.id;
      if (caseId) {
        dispatch(getCaseMessages(caseId));
      }
      // dispatch(postMessageSuccessAction(data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const uploadDocumentsAction = (formData: any, caseId: string) => {
  const taskId = CaseActionTypeEnum.POST_DOCUMENT;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await caseService.PostDocument(formData, caseId);
      dispatch(postDocumentSuccessAction(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const deleteDocumentAction = (id: string, url: string) => {
  const taskId = CaseActionTypeEnum.DELETE_DOCUMENT;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      await caseService.DeleteDocument(id, url);
      dispatch(getCaseDocuments(id));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const uploadPhotosAction = (formData: any, caseId: string) => {
  const taskId = CaseActionTypeEnum.POST_PHOTO;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await caseService.PostPhoto(formData, caseId);
      dispatch(postPhotoSuccessAction(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const deletePhotoAction = (id: string, url: string) => {
  const taskId = CaseActionTypeEnum.DELETE_PHOTO;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      await caseService.DeletePhoto(id, url);
      dispatch(getCasePhotos(id));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const GetTimeLine = (caseId: string) => {
  const taskId = CaseActionTypeEnum.GET_TIMELINE_REVAMP;

  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await caseService.GetTimeline(caseId);
      dispatch(getTimelineSuccessAction(response.data));

      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const publishEnvelope = (formData: FormData) => {
  const taskId = CaseActionTypeEnum.PUBLISH_ENVELOPE;

  return async (dispatch: DispatchType, getState: () => IStore) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await caseService.PublishEnvelope(formData);

      const document = getState().caseReducer.documentFile;
      const photo = getState().caseReducer.photoFile;
      const id = response.data;
      const eSign = true;

      if (id) {
        if (document) {
          const formData = new FormData();
          formData.append(CaseFormData.FILE, document.data);
          formData.append(
            CaseFormData.DESCRIPTION,
            document.description || 'Document',
          );
          formData.append(CaseFormData.ESIGN, eSign.toString());

          await dispatch(uploadDocumentsAction(formData, id));
        }
        if (photo) {
          const formData = new FormData();
          formData.append(CaseFormData.FILE, photo.data);
          formData.append(
            CaseFormData.DESCRIPTION,
            photo.description || 'Photo',
          );
          formData.append(CaseFormData.ESIGN, eSign.toString());

          await dispatch(uploadPhotosAction(formData, id));
        }
      }

      dispatch(saveDocument(undefined));
      dispatch(savePhoto(undefined));
      dispatch(clearField());
      dispatch(cleanupCaseList());
      await dispatch(getCases(true));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};
