export enum MessageFormData {
  CONTENT = 'content',
  TICKET_ID = 'ticketId',
  ATTACHMENTS = 'attachments',
}

export enum AttachmentFormData {
  FILE = 'file',
  DESCRIPTION = 'description',
}
