import { RegisterRequest } from './../types/loginStore';
import {
  ForgotPasswordRequest,
  ForgotPasswordResult,
  LoginResult,
  LoginUserRequest,
  USerField,
  UserFieldFormData,
} from 'types/user';
import { apiRoutesEnum } from './../enums/routes';
import { AXIOS_REVAMP } from './axios';
import { LanguageRevamp } from 'types/language';

async function LoginUser(body: LoginUserRequest): Promise<LoginResult> {
  return AXIOS_REVAMP.post(apiRoutesEnum.LOGIN_REVAMP, {
    ...body,
  });
}

async function ForgotPassword(
  payload: ForgotPasswordRequest,
): Promise<ForgotPasswordResult> {
  return AXIOS_REVAMP.post(apiRoutesEnum.FORGOT_PASSWORD, payload);
}

async function GetUser(): Promise<UserFieldFormData> {
  return AXIOS_REVAMP.get(apiRoutesEnum.USER_INFORMATION_FORM_REVAMP);
}

async function UpdateUser(fields: USerField[]) {
  return AXIOS_REVAMP.post(apiRoutesEnum.USER_INFORMATION_FORM_REVAMP, fields);
}

async function ChangePassword(password: {
  newPassword: string;
  oldPassword: string;
}) {
  return AXIOS_REVAMP.post(apiRoutesEnum.CHANGE_PASSWORD_REVAMP, password);
}

async function RegisterNewUser(payload: RegisterRequest) {
  return AXIOS_REVAMP.post(apiRoutesEnum.REGISTER_REVAMP, payload);
}

async function GetUserAvatar(): Promise<{ data: string }> {
  return AXIOS_REVAMP.get(apiRoutesEnum.USER_AVATAR_REVAMP);
}

async function UpdateUserAvatar(
  formData: FormData,
): Promise<{ data: string; message: string }> {
  return AXIOS_REVAMP.post(apiRoutesEnum.USER_AVATAR_REVAMP, formData);
}

async function GetUserLanguage(): Promise<{ data: LanguageRevamp }> {
  return AXIOS_REVAMP.get(apiRoutesEnum.USER_LANGUAGE_REVAMP);
}

async function UpdateUserLanguage(updatedLanguage: LanguageRevamp) {
  return AXIOS_REVAMP.put(apiRoutesEnum.USER_LANGUAGE_REVAMP, updatedLanguage);
}

export default {
  LoginUser,
  ForgotPassword,
  GetUser,
  UpdateUser,
  ChangePassword,
  RegisterNewUser,
  GetUserAvatar,
  UpdateUserAvatar,
  GetUserLanguage,
  UpdateUserLanguage,
};
