import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  detail: {
    flex: 1,
    marginTop: 20,
    gap: 25,
    padding: '0px 21px 10px 21px',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(478px + 230px)',
    },
  },
  headerWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      gap: 10,
      height: 90,
    },
  },
  header: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'baseline',
      gap: 10,
    },
  },
  isActive: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    color: `${theme.colors.green2}`,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
    },
  },
  isClosed: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    color: `${theme.colors.black}`,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
    },
  },
  caseId: {
    fontWeight: 600,
    fontSize: 16,
    width: '0px',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '27px',
    },
  },
  activeText: {
    backgroundColor: theme.colors.green,
    fontWeight: 'bolder',
    '&:hover': {
      backgroundColor: 'rgba(169,210,49,255)',
    },
  },
  titleText: {
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  options: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '5%',
    width: '100%',
    paddingRight: '5%',
    textAlign: 'center',
    backgroundColor: 'white',
    height: 48,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      border: `1px solid ${theme.colors.inputBackground}`,
    },
  },
  icon: { width: '30%', flex: 1 },
  addButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    flexDirection: 'column',
  },
  addButtonMobile: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
      color: theme.colors.primary,
      fontWeight: 400,
      fontSize: '16px',
      padding: 0,
      lineHeight: '27px',
    },
  },
  main: {
    display: 'flex',
    flex: 1,
    height: 'calc(100vh - 75px)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  timestamp: {
    width: 200,
    backgroundColor: theme.colors.pureWhite,
    padding: 20,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      overflowY: 'unset',
    },
  },
  lastActionText: {
    borderRadius: '10px 10px 0px 0px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: '13px',
  },
  tabs: {
    display: 'flex',
    minHeight: 0,
    width: '100%',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    // maxWidth: '30%!important' as '30%',
    // maxHeight: '30%!important' as '30%',
    // // minWidth: '120px',
    width: '33%',
    minWidth: 0,
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: 13,
  },
  documents: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(80px,1fr))',
    rowGap: '1rem',
    columnGap: '0.5rem',
    justifyItems: 'center',
    textAlign: 'center',
    padding: '5px',
  },
  photos: {
    display: 'grid',
    // padding: '10px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(110px,1fr))',
    rowGap: '1rem',
    columnGap: '20px',
    justifyItems: 'center',
    textAlign: 'center',
  },
  clear: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  badge: {
    backgroundColor: 'rgba(239,131,103,255)',
  },
  whiteBackground: {
    backgroundColor: theme.colors.pureWhite,
  },
}));

export default styles;
