import { PasswordPayload, RegisterRequest } from './../../types/loginStore';
import { LoginActionTypeEnum } from 'enums/actions';
import { LocalStorageEnum } from 'enums/storage';
import { LoginUserRequest, USerField, UserResponse } from 'types/user';
import localStorage from 'utils/localStorage';
import { DispatchType } from './../../types/store';
import userSvc from 'services/user';
import { asyncTaskStartAction, asyncTaskStopAction } from './asyncTaskActions';
import { ApiError } from 'types/api';
import { LanguageRevamp } from 'types/language';

export const getUserSuccessAction = (payload: Partial<UserResponse>) => {
  return {
    type: LoginActionTypeEnum.GET_USER_INFO,
    payload,
  };
};

export const updateUserSuccessAction = (payload: Partial<UserResponse>) => {
  return {
    type: LoginActionTypeEnum.UPDATE_USER_INFO,
    payload,
  };
};

export const updatePasswordAction = (payload: PasswordPayload) => {
  return {
    type: LoginActionTypeEnum.CHANGE_PASSWORD_REVAMP,
    payload,
  };
};

export const updateUserAvatarSuccessAction = (payload: string) => {
  return {
    type: LoginActionTypeEnum.UPDATE_AVATAR,
    payload,
  };
};

export const updateUserLanguageSuccessAction = (payload: string) => {
  return {
    type: LoginActionTypeEnum.UPDATE_USER_LANGUAGE,
    payload,
  };
};

export const loginAction = (
  params: LoginUserRequest,
  referer: string,
  redirectToHome: () => void,
) => {
  const taskId = LoginActionTypeEnum.LOGIN;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const data: any = await userSvc.LoginUser(params);
      if (data.code) {
        dispatch(
          asyncTaskStopAction(taskId, {
            message: data.message,
          }),
        );
        return;
      }
      localStorage.setItem(LocalStorageEnum.ACCESS_TOKEN, data.jwt);
      localStorage.setItem(
        LocalStorageEnum.FILE_STORAGE_ACCESS_TOKEN,
        data.mainUserToken,
      );
      localStorage.setItem(LocalStorageEnum.CONTACT_ID, data.contactId);
      const user = await userSvc.GetUser();
      dispatch(
        getUserSuccessAction(user.data.sort((a, b) => a.position - b.position)),
      );
      dispatch(asyncTaskStopAction(taskId));
      redirectToHome();
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const logoutAction = () => async (dispatch: DispatchType) => {
  try {
    localStorage.removeItem(LocalStorageEnum.ACCESS_TOKEN);
    dispatch({ type: LoginActionTypeEnum.LOGOUT });
  } catch (error) {
    console.log(error);
  }
};

export const getUser = () => {
  const taskId = LoginActionTypeEnum.GET_USER_INFO;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const user = await userSvc.GetUser();
      dispatch(
        getUserSuccessAction(user.data.sort((a, b) => a.position - b.position)),
      );
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const updateUser = (fields: USerField[]) => {
  const taskId = LoginActionTypeEnum.UPDATE_USER_INFO;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      dispatch(getUserSuccessAction(fields));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const saveChange = (fields: USerField[]) => {
  const taskId = LoginActionTypeEnum.SAVE_CHANGES;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      await userSvc.UpdateUser(fields);
      updateUserSuccessAction(fields);
      const user = await userSvc.GetUser();
      dispatch(
        getUserSuccessAction(user.data.sort((a, b) => a.position - b.position)),
      );
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const updatePassword = (password: PasswordPayload) => {
  const taskId = LoginActionTypeEnum.CHANGE_PASSWORD_REVAMP;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      await userSvc.ChangePassword(password);
      dispatch(
        updatePasswordAction({
          ...password,
          confirmPassword: '',
          oldPassword: '',
          newPassword: '',
        }),
      );
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const registerNewUser = (
  payload: RegisterRequest,
  redirectToLogin: () => void,
) => {
  const taskId = LoginActionTypeEnum.REGISTER_NEW_USER;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      await userSvc.RegisterNewUser(payload);
      dispatch(asyncTaskStopAction(taskId));
      redirectToLogin();
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const getUserAvatar = () => {
  const taskId = LoginActionTypeEnum.GET_AVATAR;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await userSvc.GetUserAvatar();
      dispatch(updateUserAvatarSuccessAction(response.data));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const updateUserAvatar = (file: File) => {
  const taskId = LoginActionTypeEnum.UPDATE_AVATAR;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const formData = new FormData();
      formData.append('photo', file);
      const response = await userSvc.UpdateUserAvatar(formData);
      dispatch(updateUserAvatarSuccessAction(response.message));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const getUserLanguage = () => {
  const taskId = LoginActionTypeEnum.GET_USER_LANGUAGE;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await userSvc.GetUserLanguage();
      dispatch(updateUserLanguageSuccessAction(response.data.language));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};

export const updateUserLanguage = (updatedLanguage: LanguageRevamp) => {
  const taskId = LoginActionTypeEnum.UPDATE_USER_LANGUAGE;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      await userSvc.UpdateUserLanguage(updatedLanguage);
      dispatch(updateUserLanguageSuccessAction(updatedLanguage.language));
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};
