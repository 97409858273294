import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import React from 'react';
import colors from 'material/colors';

interface IProps {
  onClick?: () => void;
  label: string;
  className?: string;
  to: string;
}

const TabLink = ({ onClick, label, className, to }: IProps) => {
  const classes = useStyles();

  return (
    <NavLink
      className={clsx(classes.root, className)}
      onClick={onClick}
      to={to}
      activeClassName={classes.active}
    >
      {label}
    </NavLink>
  );
};

export default TabLink;
const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 11,
    backgroundColor: theme.colors.pureWhite,
    padding: 14,
    display: 'block',
    borderRadius: 0,
    fontWeight: 600,
    color: colors.secondary,
    borderBottom: '2px solid transparent',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.colors.gray3,
      color: theme.colors.black2,
    },
  },
  disabled: {
    backgroundColor: theme.colors.gray1,
    color: theme.colors.gray,
  },
  active: {
    color: colors.primary,
    borderBottom: '2px solid #ff7b00',
  },
}));
