import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  messageRow: {
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
    marginBottom: 35,
  },
  wrapper: {
    display: 'flex',
  },
  messageLeft: {
    // marginLeft: '16px',
    marginBottom: '10px',
    backgroundColor: 'white',
    width: '90%',
    textAlign: 'left',
    font: "400 .9em 'Open Sans', sans-serif",
    border: '1px solid #97C6E3',
    borderRadius: '0px 10px 10px 10px',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: `${theme.colors.inputBackground}`,
    },
  },
  messageContent: {
    padding: '8px 16px',
    margin: 0,
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  icon: {
    height: '100px',
    width: '100px',
    color: 'grey',
  },
}));

export default styles;
