import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styles from './styles';
import { Box, Typography } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import DropdownIcon from 'icons/DropdownIcon';

interface IProps {
  values: {
    description: string;
    id: string | number;
  }[];
  required?: boolean;
  currentValue: string | number;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  placeholder?: string;
  name?: string;
  helperText?: string;
  id?: string;
  onFocus?: any;
}

export default function Dropdown({
  name,
  values,
  required,
  onChange,
  currentValue,
  placeholder,
  helperText,
  id,
  onFocus,
}: IProps) {
  const classes = styles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box width="100%">
      <FormControl
        className={clsx(classes.formControl, { [classes.error]: !!helperText })}
      >
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={currentValue}
          onChange={onChange}
          className={classes.root}
          required={required}
          variant="outlined"
          displayEmpty
          name={name}
          IconComponent={() => <DropdownIcon className="mr2" />}
          id={id}
          onFocus={onFocus}
        >
          {placeholder && (
            <MenuItem className={classes.option} value="">
              <Typography>{placeholder}</Typography>
            </MenuItem>
          )}
          {values?.map((v) => (
            <MenuItem className={classes.option} key={v.id} value={v.id}>
              {v.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText
        style={{
          fontSize: 12,
          color: '#f44336',
        }}
      >
        {helperText}
      </FormHelperText>
    </Box>
  );
}
