import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CardMedia,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import JoditEditor from 'jodit-react';
import styles from './styles';
import TextButton from 'components/TextButton';
import { CaseActionTypeEnum } from 'enums/actions';
import FileInput from 'components/FileInput';
import IconButton from 'components/IconButton';
import { Clear } from '@material-ui/icons';
import { checkAttachmentType } from 'utils/checkAttachmentType';
import { AddButtonText } from 'enums/AddButtonText';
import { THIS_FIELD_IS_REQUIRED } from 'constatnts';

type Props = {
  open: boolean;
  onClose: () => void;
  onSend: (message: string, attachment: File | undefined) => void;
  attachmentId: string;
};

const buttons = ['bold', 'italic', 'ul', 'ol', 'link'];

const config = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  addNewLine: false,
  height: 250,
  buttonsMD: buttons,
  buttonsSM: buttons,
  buttonsXS: buttons,
  hidePoweredByJodit: true,
  additionalStylesheets: ['src/pages/Home/AddCase/JoditEditorStyle.css'],
};

const AddAttachmentModal = ({ open, onClose, onSend, attachmentId }: Props) => {
  const [description, setDescription] = useState('');
  const [attachment, setAttachment] = useState<File | undefined>();
  const [error, setError] = useState(false);
  const classes = styles();
  const handleSend = () => {
    // trim description
    const parser = new DOMParser();
    const parsedDescription = parser.parseFromString(description, 'text/html');
    const trimDescription =
      parsedDescription.documentElement.textContent?.trim() || '';

    if (!trimDescription && attachmentId !== AddButtonText.ADD_MESSAGE) {
      setError(true);
      return;
    } else {
      setError(false);
    }
    onSend(trimDescription, attachment);
    setDescription('');
    setAttachment(undefined);
    onClose();
  };

  const handleClose = () => {
    setDescription('');
    setAttachment(undefined);
    setError(false);
    onClose();
  };

  const onAttachFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setAttachment(file);
  };

  const handleDescription = (text: string) => {
    setDescription(text);
    const parser = new DOMParser();
    const parsedText = parser.parseFromString(text, 'text/html');
    const trimText = parsedText.documentElement.textContent?.trim() || '';
    if (!trimText && attachmentId !== AddButtonText.ADD_MESSAGE) {
      setError(false);
    }
  };

  const AttachmentRender = useMemo(() => {
    return (
      attachment && (
        <Box display="flex" alignItems="start">
          {checkAttachmentType(attachment) ? (
            <CardMedia
              className={classes.img}
              image={URL.createObjectURL(attachment)}
            />
          ) : (
            <Typography
              style={{
                marginLeft: 24,
                marginTop: 4,
              }}
            >
              {(attachment as File).name}
            </Typography>
          )}
          <IconButton
            onClick={() => {
              setAttachment(undefined);
              const attachmentRef: any =
                window.document.getElementById(attachmentId);
              if (attachmentRef) {
                attachmentRef.value = '';
              }
            }}
            className="ml2"
            Icon={<Clear />}
          />
        </Box>
      )
    );
  }, [attachment]);

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle style={{ fontSize: '14px', paddingBottom: 0 }}>
        New {attachmentId}
      </DialogTitle>
      <DialogContent>
        <Box className={classes.editor}>
          <JoditEditor
            value={description}
            config={
              error
                ? {
                    ...config,
                    style: {
                      background: '#fff6f6',
                    },
                  }
                : config
            }
            onChange={handleDescription}
          />
        </Box>
        {error && (
          <FormHelperText
            style={{
              fontSize: 12,
              color: '#f44336',
            }}
          >
            {THIS_FIELD_IS_REQUIRED}
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Box className={classes.fileInput}>
          <FileInput
            accept={
              attachmentId === AddButtonText.ADD_PHOTO
                ? '.jpg,.png,.jpeg,.png'
                : ''
            }
            label={'Add attachment'}
            onChange={onAttachFile}
            name={attachmentId}
            id={attachmentId}
          />
          {AttachmentRender}
        </Box>
        <Box>
          <TextButton
            className={classes.cancelButton}
            onClick={handleClose}
            label="Cancel"
          />
          <TextButton
            className={classes.sendButton}
            onClick={handleSend}
            label="Send"
            actionId={CaseActionTypeEnum.POST_MESSAGE}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddAttachmentModal;
