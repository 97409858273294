import { LoginActionTypeEnum } from 'enums/actions';
import { LoginActionTypes, LoginReducerState } from '../../types/loginStore';

export const initialLoginState: LoginReducerState = {
  status: false,
  fields: [],
  password: {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  },
  avatar: '',
  language: '',
};

export const loginReducer = (
  state = initialLoginState,
  action: LoginActionTypes,
) => {
  switch (action.type) {
    case LoginActionTypeEnum.GET_USER_INFO:
      return {
        ...state,
        fields: action.payload,
        status: true,
      };
    case LoginActionTypeEnum.UPDATE_USER_INFO:
      return {
        ...state,
        fields: action.payload,
      };
    case LoginActionTypeEnum.CHANGE_PASSWORD_REVAMP:
      return {
        ...state,
        password: action.payload,
      };
    case LoginActionTypeEnum.UPDATE_AVATAR:
      return {
        ...state,
        avatar: action.payload,
      };
    case LoginActionTypeEnum.UPDATE_USER_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case LoginActionTypeEnum.LOGOUT:
      return {
        ...state,
        fields: [],
        status: false,
      };

    default:
      return state;
  }
};
