import React, { useState, useEffect, useRef } from 'react';
import styles from './styles';
import { Box, Typography } from '@material-ui/core';
import MessageAvatar from 'icons/MessageAvatar';
import { TicketMessageResponseDTOList } from 'types/cases';
import { isImage } from 'utils/checkAttachmentType';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CommonModal from 'components/CommonModal';
import ImageSlider from 'components/ImageGallery';
import parse from 'html-react-parser';

interface IProps {
  message: TicketMessageResponseDTOList;
}

const Message = ({ message }: IProps) => {
  const classes = styles();

  const messageRef = useRef<HTMLDivElement>(null);

  const createdDate = new Date(message.createdAt).toLocaleString();
  const content = parse(decodeURIComponent(message.content));
  const [isOpenPreview, setIsOpenPreview] = useState(false);

  useEffect(() => {
    const chatContainerElement = messageRef.current;
    if (chatContainerElement) {
      chatContainerElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [message]);

  const attachmentRender = () => {
    return (
      <Box>
        {message.attachments &&
          message.attachments.map((attachment, index) => {
            if (isImage(attachment.fileUrl)) {
              return (
                <Box
                  onClick={() => setIsOpenPreview(true)}
                  key={attachment.fileUrl + index}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={attachment.fileUrl}
                    alt={attachment.fileName}
                    width={'100%'}
                  />
                  <CommonModal
                    isOpen={isOpenPreview}
                    onClose={() => setIsOpenPreview(false)}
                  >
                    <ImageSlider
                      slides={message.attachments}
                      selectedIndex={index}
                    />
                  </CommonModal>
                </Box>
              );
            } else {
              const downloadFile = () => {
                window.location.href = attachment.fileUrl;
              };
              return (
                <Box key={attachment.fileUrl + index}>
                  <a href={attachment.fileUrl} download onClick={downloadFile}>
                    <InsertDriveFileIcon className={classes.icon} />
                  </a>
                  <Box>{decodeURIComponent(attachment.fileName)}</Box>
                </Box>
              );
            }
          })}
      </Box>
    );
  };

  return (
    <>
      <Box className={classes.messageRow}>
        <Typography component="p" variant="subtitle2">
          {createdDate}
        </Typography>
        <Box className={classes.wrapper}>
          <Box>
            <MessageAvatar />
            <Typography component="p" variant="subtitle2">
              {message.creator.lastName}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '16px',
              width: '100%',
            }}
          >
            {attachmentRender()}
            {content !== 'null' && (
              <Box className={classes.messageLeft}>
                <Box className={classes.messageContent}>{content}</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <div ref={messageRef} />
    </>
  );
};

export default Message;
