import { CategoryActionTypeEnum } from 'enums/actions';
import {
  CategoryActionTypes,
  CategoryReducerState,
} from '../../types/category';

export const initialCategoryState: CategoryReducerState = [];

export const categoryReducer = (
  state = initialCategoryState,
  action: CategoryActionTypes,
) => {
  switch (action.type) {
    case CategoryActionTypeEnum.GET_ALL_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};
