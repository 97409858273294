import React from 'react';
const DocumentIcon = () => {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.018 24.988H16.6965C22.262 24.988 24.4882 22.7618 24.4882 17.1964V10.5178C24.4882 4.95236 22.262 2.72618 16.6965 2.72618H10.018C4.4525 2.72618 2.22632 4.95236 2.22632 10.5178V17.1964C2.22632 22.7618 4.4525 24.988 10.018 24.988Z"
        stroke="#292D32"
        strokeWidth="1.66964"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5315 10.5178H9.18335"
        stroke="#292D32"
        strokeWidth="1.66964"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5315 17.1964H9.18335"
        stroke="#292D32"
        strokeWidth="1.66964"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default DocumentIcon;
