import { Box } from '@material-ui/core';
import React from 'react';

const TimelineSeparator = () => {
  return (
    <Box
      style={{
        width: 1,
        borderRight: '1px solid rgb(0, 47, 71)',
        position: 'absolute',
        left: 20,
        height: '100%',
        zIndex: '-1',
      }}
    ></Box>
  );
};

export default TimelineSeparator;
