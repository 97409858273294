export const PASSWORD_IS_REQUIRED = 'Password is required';
export const EMAIL_IS_REQUIRED = 'Email is required';
export const INVALID_EMAIL = 'Email is invalid';
export const INVALID_PHONE_NUMBER = 'Phone number is invalid';
export const FIRST_NAME_IS_REQUIRED = 'First name is required';
export const LAST_NAME_IS_REQUIRED = 'Last name is required';
export const PROBLEM_IS_REQUIRED = 'Problem is required';
export const SUBJECT_IS_REQUIRED = 'Subject is required';
export const THIS_FIELD_IS_REQUIRED = 'This field is required';
export const CONFIRM_PASSWORD_DOES_NOT_MATCHE =
  'Confirm password does not match';
export const PLEASE_VERIFY_THE_CAPCHA = 'Please verify the capcha';
export const MAXIMUM_FILE_SIZE_ERROR = 'The maximum file size is 1 MB';
