import React, { useEffect, useState } from 'react';
import styles from './styles';
import TextInput from 'components/TextInput';
import Avatar from '@material-ui/core/Avatar';
import TextButton from 'components/TextButton';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAvatarState,
  getPasswordState,
  getUserState,
} from 'store/selectors';
import { USerField } from 'types/user';
import { DispatchType } from 'types/store';
import {
  getUserAvatar,
  saveChange,
  updatePassword,
  updatePasswordAction,
  updateUserAvatar,
} from 'store/actions/loginActions';
import { get, cloneDeep } from 'lodash';
import {
  CONFIRM_PASSWORD_DOES_NOT_MATCHE,
  THIS_FIELD_IS_REQUIRED,
} from 'constatnts';
import PersonalIcon from 'icons/PersonalIcon';
import EditIcon from 'icons/EditIcon';
import FileInput from 'components/FileInput';
import LockIcon from 'icons/LockIcon';
import { LoginActionTypeEnum } from 'enums/actions';
import RequiredAsterisk from 'components/RequiredAsterisk';

const defaultError = {};

const MyInformationPage = () => {
  const classes = styles();
  const dispatch: DispatchType = useDispatch();

  const fieldsRedux = useSelector(getUserState);
  const [fields, setFields] = useState(cloneDeep(fieldsRedux));
  const password = useSelector(getPasswordState);
  const avatar = useSelector(getAvatarState);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [fieldError, setFieldError] = useState<any>({});

  const [error, setError] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const onChangeUserField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setFieldError({
      ...fieldError,
      [name]: '',
    });
    const field = fields.find((f) => f.field === name) as USerField;

    field.value = value;
    setFields([...fields]);
  };

  const onSave = () => {
    for (let i = 0; i < fields.length; i++) {
      if (!fields[i].value && fields[i].isRequired) {
        setFieldError({
          ...defaultError,
          [fields[i].field]: THIS_FIELD_IS_REQUIRED,
        });
        return;
      }
    }
    dispatch(saveChange(fields));
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if (get(error, name)) {
      setError({
        ...error,
        [name]: '',
      });
    }
    dispatch(
      updatePasswordAction({
        ...password,
        [name]: value,
      }),
    );
  };

  const onUpdatePassword = () => {
    if (!password.oldPassword) {
      setError({
        oldPassword: THIS_FIELD_IS_REQUIRED,
        newPassword: '',
        confirmPassword: '',
      });
      return;
    }
    if (!password.newPassword) {
      setError({
        newPassword: THIS_FIELD_IS_REQUIRED,
        oldPassword: '',
        confirmPassword: '',
      });
      return;
    }
    if (!password.confirmPassword) {
      setError({
        confirmPassword: THIS_FIELD_IS_REQUIRED,
        oldPassword: '',
        newPassword: '',
      });
    }
    if (password.confirmPassword !== password.newPassword) {
      setError({
        confirmPassword: CONFIRM_PASSWORD_DOES_NOT_MATCHE,
        oldPassword: '',
        newPassword: '',
      });
      return;
    }
    dispatch(updatePassword(password));
  };

  useEffect(() => {
    dispatch(getUserAvatar());
  }, []);

  useEffect(() => {
    if (fields.length === 0) setFields(cloneDeep(fieldsRedux));
  }, [fieldsRedux]);

  const onChangeAvatar = (e: React.ChangeEvent<any>) => {
    const file = e.target.files[0];
    dispatch(updateUserAvatar(file));
  };

  return (
    <div className={classes.editInformationTemplate}>
      <div className={classes.edit}>
        <Box className={matches ? classes.displayNone : classes.header}>
          <PersonalIcon />
          <Typography className={classes.headerText}>
            Personal information
          </Typography>
        </Box>
        <div className={classes.wrapper}>
          <div className={classes.avatar}>
            <Avatar
              alt="Avatar"
              src={avatar}
              style={{
                width: 132,
                height: 132,
              }}
            />
            <FileInput
              label="Edit"
              onChange={onChangeAvatar}
              name="avatar"
              Icon={<EditIcon />}
              style={{
                marginTop: 12,
              }}
            />
          </div>
          {matches && (
            <Box className={classes.header}>
              <PersonalIcon />
              <Typography className={classes.headerText}>
                Personal information
              </Typography>
            </Box>
          )}
          <form className={classes.informationForm} id="information-form">
            {fields.map((f, i) => (
              <div className={classes.inputDiv} key={i}>
                <label className={classes.label}>
                  {f.title}
                  {f.isRequired && <RequiredAsterisk />}
                </label>
                <TextInput
                  className={classes.textField}
                  name={f.field}
                  type="text"
                  value={f.value}
                  onChange={onChangeUserField}
                  helperText={fieldError[f.field]}
                  required={f.isRequired}
                />
              </div>
            ))}
            <div className={classes.centerButton}>
              <TextButton
                className={classes.doneButton}
                onClick={onSave}
                label="Done"
                actionId={LoginActionTypeEnum.SAVE_CHANGES}
              />
            </div>
          </form>
        </div>
      </div>
      <div className={classes.edit}>
        <Box className={classes.header}>
          <LockIcon className={classes.icon} />
          <Typography className={classes.headerText}>
            Change password
          </Typography>
        </Box>
        <form className={classes.passwordForm}>
          <div className={classes.inputDiv}>
            <label htmlFor={'old_password'} className={classes.label}>
              Old password
            </label>
            <TextInput
              className={classes.textField}
              name={'oldPassword'}
              type="password"
              value={password.oldPassword}
              onChange={onChangePassword}
              helperText={error.oldPassword}
            />
          </div>
          <div className={classes.inputDiv}>
            <label htmlFor={'new_password'} className={classes.label}>
              New password
            </label>
            <TextInput
              className={classes.textField}
              name={'newPassword'}
              type="password"
              value={password.newPassword}
              onChange={onChangePassword}
              helperText={error.newPassword}
            />
          </div>
          <div className={classes.inputDiv}>
            <label htmlFor={'cf_new_password'} className={classes.label}>
              Confirm new password
            </label>
            <TextInput
              className={classes.textField}
              name={'confirmPassword'}
              type="password"
              value={password.confirmPassword}
              onChange={onChangePassword}
              helperText={error.confirmPassword}
            />
          </div>
          <div className={classes.centerButton}>
            <TextButton
              label="Done"
              className={classes.doneButton}
              onClick={onUpdatePassword}
              actionId={LoginActionTypeEnum.CHANGE_PASSWORD_REVAMP}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyInformationPage;
