import { Box, Typography } from '@material-ui/core';
import TextButton from 'components/TextButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteDocumentAction,
  deletePhotoAction,
} from 'store/actions/caseActions';
import { DispatchType } from 'types/store';
import styles from './styles';
import { AddButtonText } from 'enums/AddButtonText';

type Props = {
  onClose: () => void;
  caseId: string;
  url: string;
  title: string;
};

const ConfirmModal = ({ onClose, caseId, url, title }: Props) => {
  const classes = styles();
  const dispatch: DispatchType = useDispatch();

  const onConfirmDelete = () => {
    if (title === AddButtonText.ADD_PHOTO) {
      dispatch(deletePhotoAction(caseId, url));
    } else if (title === AddButtonText.ADD_DOCUMENT) {
      dispatch(deleteDocumentAction(caseId, url));
    }
    onClose();
  };

  return (
    <Box className={classes.container}>
      <Typography style={{ fontWeight: 'bold' }} component="p" variant="body1">
        Confirm
      </Typography>
      <Typography variant="body2" component={'p'}>
        Do you want delete this item
      </Typography>
      <Box className={classes.buttonDisplay}>
        <TextButton
          label="Cancel"
          onClick={onClose}
          className={classes.cancelButton}
        />
        <TextButton
          label="Sure"
          onClick={onConfirmDelete}
          className={classes.confirmButton}
        />
      </Box>
    </Box>
  );
};

export default ConfirmModal;
