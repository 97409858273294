import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Dropdown from 'components/Dropdown';
import clsx from 'clsx';

interface IProps {
  required?: boolean;
  placeholder?: string;
  onChange: any;
  name?: string;
  values: {
    description: string;
    id: string | number;
  }[];
  currentValue: string | number;
  helperText?: string;
  className?: string;
  id?: string;
  onFocus?: any;
}

const DropdownWithRequireIndicator = ({
  name,
  required,
  values,
  currentValue,
  placeholder,
  onChange,
  helperText,
  className,
  id,
  onFocus,
}: IProps) => {
  return (
    <Box className={clsx('p2 align-item-center w100', className)}>
      {required && (
        <Typography
          style={{
            marginRight: 4,
            color: 'red',
          }}
        >
          *
        </Typography>
      )}
      <Dropdown
        values={values}
        placeholder={placeholder}
        name={name}
        required={required}
        currentValue={currentValue}
        onChange={onChange}
        helperText={helperText}
        id={id}
        onFocus={onFocus}
      />
    </Box>
  );
};

export default DropdownWithRequireIndicator;
