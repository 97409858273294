import React, { ChangeEvent, useState } from 'react';
import TextButton from 'components/TextButton';
import { UserFieldEnum } from 'enums/user';
import { styles } from './styles';
import ReCAPTCHA from 'react-google-recaptcha';
import SecondaryTextButton from 'components/SecondaryTextButton';
import TextInputWithRequireIndicator from 'components/TextInputWithRequireIndicator';
import isEmail from 'validator/lib/isEmail';
import {
  EMAIL_IS_REQUIRED,
  FIRST_NAME_IS_REQUIRED,
  INVALID_EMAIL,
  LAST_NAME_IS_REQUIRED,
  PASSWORD_IS_REQUIRED,
  PLEASE_VERIFY_THE_CAPCHA,
} from 'constatnts';
import { DispatchType } from 'types/store';
import { useDispatch } from 'react-redux';
import { registerNewUser } from 'store/actions/loginActions';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { LoginActionTypeEnum } from 'enums/actions';

const SignUpForm = () => {
  const classes = styles();
  const { FIRST_NAME, LAST_NAME, EMAIL, PASSWORD } = UserFieldEnum;
  const dispatch: DispatchType = useDispatch();
  const routeHistory = useHistory();

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const [isVerify, setIsVerify] = useState(false);

  const [error, setError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    capcha: '',
  });

  const { enterprise } = useParams<any>();

  const redirectToLogin = () => {
    routeHistory.push(`/${enterprise}/sign-in`);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;
    setError({
      ...error,
      [name]: '',
    });
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onRegister = async () => {
    try {
      if (!form.firstName) {
        setError({
          firstName: FIRST_NAME_IS_REQUIRED,
          lastName: '',
          email: '',
          password: '',
          capcha: '',
        });
        return;
      }
      if (!form.lastName) {
        setError({
          lastName: LAST_NAME_IS_REQUIRED,
          firstName: '',
          email: '',
          password: '',
          capcha: '',
        });
        return;
      }
      if (!form.email) {
        setError({
          email: EMAIL_IS_REQUIRED,
          firstName: '',
          lastName: '',
          password: '',
          capcha: '',
        });
        return;
      }
      if (!isEmail(form.email)) {
        setError({
          email: INVALID_EMAIL,
          firstName: '',
          lastName: '',
          password: '',
          capcha: '',
        });
        return;
      }
      if (!form.password) {
        setError({
          password: PASSWORD_IS_REQUIRED,
          firstName: '',
          lastName: '',
          email: '',
          capcha: '',
        });
        return;
      }
      if (!isVerify) {
        setError({
          password: '',
          firstName: '',
          lastName: '',
          email: '',
          capcha: PLEASE_VERIFY_THE_CAPCHA,
        });
        return;
      }
      await dispatch(registerNewUser(form, redirectToLogin));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 90px)',
      }}
    >
      <form className={classes.form}>
        <TextInputWithRequireIndicator
          required
          value={form.firstName}
          name={FIRST_NAME}
          onChange={onChange}
          label="First name"
          helperText={error.firstName}
          labelClassName={classes.label}
          className={classes.input}
        />
        <TextInputWithRequireIndicator
          required
          value={form.lastName}
          name={LAST_NAME}
          onChange={onChange}
          label="Last name"
          helperText={error.lastName}
          labelClassName={classes.label}
          className={classes.input}
        />
        <TextInputWithRequireIndicator
          required
          value={form.email}
          name={EMAIL}
          onChange={onChange}
          label="Email"
          helperText={error.email}
          labelClassName={classes.label}
          type="email"
          className={classes.input}
        />
        <TextInputWithRequireIndicator
          required
          value={form.password}
          name={PASSWORD}
          onChange={onChange}
          label="Password"
          helperText={error.password}
          labelClassName={classes.label}
          type="password"
          className={classes.input}
        />
        <ReCAPTCHA
          style={{ marginLeft: 116, marginTop: 16 }}
          sitekey={process.env.REACT_APP_RE_CAPTCHA_KEY || ''}
          onChange={() => {
            setError({
              ...error,
              capcha: '',
            });
            setIsVerify(true);
          }}
          type="image"
        />
        {error.capcha && (
          <Typography
            style={{ color: '#f44336', fontSize: 12, marginLeft: 116 }}
          >
            {error.capcha}
          </Typography>
        )}
        <div style={{ marginLeft: 116 }}>
          <TextButton
            label="Register"
            className={classes.submitButton}
            onClick={onRegister}
            actionId={LoginActionTypeEnum.REGISTER_NEW_USER}
          />
          <SecondaryTextButton
            label="Cancel"
            className={classes.cancelButton}
            onClick={() => {
              setForm({
                firstName: '',
                lastName: '',
                email: '',
                password: '',
              });
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
