import React, { MouseEvent, useState } from 'react';
import { Box } from '@material-ui/core';
import { UploadDTOList } from 'types/cases';
import { styles } from './styles';
import CommonModal from 'components/CommonModal';
import { getPhotos } from 'store/selectors';
import { useSelector } from 'react-redux';
import ImageSlider from 'components/ImageGallery';
import BoldCloseIcon from 'icons/BoldCloseIcon';
import IconButton from 'components/IconButton';
import ConfirmModal from 'components/ConfirmModal';
import { AddButtonText } from 'enums/AddButtonText';
import parse from 'html-react-parser';

interface IProps {
  photo: UploadDTOList;
  index: number;
  caseId: string;
}

const Photo = ({ photo, index, caseId }: IProps) => {
  const classes = styles();
  const photos = useSelector(getPhotos);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const handleDeletePhoto = (event: MouseEvent) => {
    event.stopPropagation();
    setIsDelete(true);
  };

  return (
    <>
      <Box>
        <Box
          style={{ display: 'flex', position: 'relative' }}
          onClick={() => setIsOpenPreview(true)}
        >
          <img
            src={photo.url}
            alt="case_detail_image"
            className={classes.photo}
          />
          <IconButton
            Icon={<BoldCloseIcon />}
            onClick={(event) => handleDeletePhoto(event)}
            className={classes.closeButton}
          />
        </Box>
        <Box>{parse(decodeURIComponent(photo.description?.slice(0, 15)))}</Box>
      </Box>
      <CommonModal
        isOpen={isOpenPreview}
        onClose={() => setIsOpenPreview(false)}
      >
        <ImageSlider slides={photos} selectedIndex={index} />
      </CommonModal>
      <CommonModal
        isOpen={isDelete}
        onClose={() => setIsDelete(false)}
        className={classes.confirmDelete}
      >
        <ConfirmModal
          title={AddButtonText.ADD_PHOTO}
          onClose={() => setIsDelete(false)}
          caseId={caseId}
          url={photo.url}
        />
      </CommonModal>
    </>
  );
};

export default Photo;
