import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Sidebar from 'components/Sidebar';
import React, { ElementType, useCallback } from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import SidebarMobile from 'components/Sidebar/SidebarMobile';

export interface DashboardLayoutProps extends RouteProps {
  RenderComponent: ElementType;
}

interface LayoutProps {
  routeProps: RouteComponentProps;
  RenderComponent: ElementType;
  path?: string | string[];
}

const Layout = (props: LayoutProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { routeProps, RenderComponent } = props;

  return (
    <div className={classes.page}>
      <div className={classes.sidebar}>
        {isMobile ? <SidebarMobile /> : <Sidebar />}
      </div>
      <div className={classes.body}>
        <RenderComponent {...routeProps} />
      </div>
    </div>
  );
};

const DashBoardLayout = ({
  RenderComponent,
  ...rest
}: DashboardLayoutProps) => {
  const render = useCallback(
    (routeProps: RouteComponentProps) => {
      return (
        <Layout
          routeProps={routeProps}
          RenderComponent={RenderComponent}
          path={rest.path as any}
        />
      );
    },
    [RenderComponent, rest.path],
  );

  return <Route {...rest} render={render} />;
};

export default DashBoardLayout;

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sidebar: {
    backgroundColor: theme.colors.pureWhite,
    borderRight: '1px solid #f1f1f1',
    left: '0',
    height: '100vh',
    width: 240,
    color: 'white',
    padding: '16px 16px',
    [theme.breakpoints.down('sm')]: {
      height: 80,
      width: '100%',
      padding: 0,
    },
  },
  body: {
    flex: 1,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 100px)',
      overflow: 'auto',
    },
  },
}));
