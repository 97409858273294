import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
  },
  editInformationTemplate: {
    flex: 1,
    boxSizing: 'border-box',
  },
  edit: {
    backgroundColor: theme.colors.pureWhite,
    borderRadius: '10px',
  },
  headerText: {
    color: theme.colors.black3,
    fontSize: 11,
    fontWeight: 600,
  },
  header: {
    height: 90,
    margin: 0,
    padding: '40px 0px 0px 32px',
    borderBottom: `1px solid #f1f1f1`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
    '& svg': {
      marginRight: 12,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px',
      borderBottom: 'none',
      height: 'fit-content',
      marginBottom: 24,
    },
  },
  wrapper: {
    display: 'flex',
    marginTop: 32,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  avatar: {
    marginLeft: 16,
    marginRight: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginLeft: 0,
      marginBottom: 20,
      marginRight: 0,
    },
  },
  textField: {
    flex: 1,
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },
  },
  informationForm: {
    alignItems: 'center',
    margin: '0 16px',
    overflow: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputDiv: {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0px 24px',
      alignItems: 'flex-start',
      height: '64px',
    },
  },
  label: {
    display: 'flex',
    width: 170,
    color: theme.colors.black1,
    fontSize: '11px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
      width: 200,
    },
  },
  doneButton: {
    color: theme.colors.pureWhite,
    padding: '5px 32px',
    width: 200,
    fontSize: '11px',
    borderRadius: '4px',
  },
  passwordForm: {
    margin: '0 16px',
    marginLeft: '180px',
  },
  formControlWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
      padding: '0px 24px',
    },
  },
  formControl: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    width: '100%',
    marginLeft: '295px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  languageLabel: {
    fontSize: '14px',
    width: '170px',
    color: theme.colors.darkGray,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
    },
  },
  dropdown: {
    flex: 1,
    width: '100%',
    height: 40,
    border: 'unset',
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      height: 32,
    },
  },
  icon: {
    position: 'relative',
    marginRight: '30px',
  },
  centerButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  displayNone: {
    display: 'none',
  },
}));

export default styles;
