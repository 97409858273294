import React from 'react';
import { IIconProperty } from 'types/iconProps';

const MessageQuestion = ({ color }: IIconProperty) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.167 15.3583H10.8337L7.12532 17.825C6.57532 18.1916 5.83366 17.8 5.83366 17.1333V15.3583C3.33366 15.3583 1.66699 13.6916 1.66699 11.1916V6.19157C1.66699 3.69157 3.33366 2.0249 5.83366 2.0249H14.167C16.667 2.0249 18.3337 3.69157 18.3337 6.19157V11.1916C18.3337 13.6916 16.667 15.3583 14.167 15.3583Z"
        stroke={color}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99954 9.46659V9.29159C9.99954 8.72492 10.3495 8.42492 10.6995 8.18324C11.0412 7.94991 11.3829 7.64992 11.3829 7.09992C11.3829 6.33325 10.7662 5.71655 9.99954 5.71655C9.23288 5.71655 8.61621 6.33325 8.61621 7.09992"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99609 11.4583H10.004"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessageQuestion;
