import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    borderRadius: 0,
    justifyContent: 'start',
    marginRight: 8,
    height: 40,
    textTransform: 'none',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  activeStyle: {
    backgroundColor: theme.colors.gray2,
    color: 'black',
    fontWeight: 'bold',
  },
  wrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      border: 'none',
    },
  },
  caseList: {
    flex: 1,
    height: 'calc(100vh - 75px)',
    [theme.breakpoints.up('sm')]: {
      overflowY: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      // height: 'auto',
    },
  },
  wrapperButton: {
    justifyContent: 'space-between',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.colors.inputBackground}`,
    padding: '26px 16px 0 16px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 16px',
      alignItems: 'flex-start',
    },
  },
  tabButtonMobile: {
    [theme.breakpoints.down('sm')]: {
      width: 77,
      fontWeight: 600,
      fontSize: 22,
    },
  },
  addCaseButtonMobile: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
      color: theme.colors.primary,
    },
  },
  displayNone: {
    display: 'none',
  },
  item: {
    textDecoration: 'none',
  },
}));

export default useStyles;
