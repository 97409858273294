import { Box, CardMedia, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { FAQ } from 'types/faq';
import styles from './styles';
import { stripHtml } from 'utils';
import { Link, useHistory } from 'react-router-dom';

interface IProps {
  faq: FAQ;
}

const FaqMobile = ({ faq }: IProps) => {
  const classes = styles();
  const history = useHistory();

  const createdDate = useMemo(() => {
    return dayjs(faq.createdDate).format('MMM DD , YYYY');
  }, [faq.createdDate]);

  return (
    <Link
      to={`${history.location.pathname}/${faq.articleId}`}
      style={{ textDecoration: 'none' }}
    >
      <Box className={classes.faqMobile}>
        <CardMedia
          className={classes.logoMobile}
          image={faq?.imageId || '/square-image.svg'}
        />
        <Box className={classes.mainMobile}>
          <Box>
            <Typography className={classes.titleMobile}>{faq.title}</Typography>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="h2"
              style={{ color: '#798B9B', fontSize: 10, fontWeight: 300 }}
            >
              {createdDate}
            </Typography>
          </Box>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="h2"
            style={{
              color: '#798B9B',
              fontSize: 12,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: 'calc(100vw - 190px)',
            }}
          >
            {stripHtml(faq.content)}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default FaqMobile;
