import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    color: theme.colors.pureWhite,
    width: '125px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    width: '125px',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));
