import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    borderRadius: 10,
    border: '1px solid #f1f1f1',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      border: 'none',
      borderTop: '1px solid #f1f1f1',
      borderRadius: 0,
    },
  },
  closeButton: {
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
    },
  },
  closeIcon: {
    margin: '10px',
    float: 'right',
  },
  main: {
    padding: '0px 32px',
    [theme.breakpoints.down('sm')]: {
      // padding: '30px 24px',
    },
  },
  img: {
    borderRadius: 17,
    height: 320,
    [theme.breakpoints.down('sm')]: {
      height: 220,
    },
  },
}));

export default styles;
