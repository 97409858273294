import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent';
import Typography from '@material-ui/core/Typography';
import TimelineSeparator from './TimelineSeparator';
import { TimeLine } from 'types/cases';
import { TimelineEnum } from 'enums/timelineEnum';
import iconMerge from '../../assets/management_Merge.svg';
import iconEmailSent from '../../assets/management_email_sent.svg';
import iconEmailReceived from '../../assets/management_email_received.svg';
import iconMassEmails from '../../assets/management_mass_emails.svg';
import iconCaseAdded from '../../assets/management_Case_added.svg';
import iconCaseMovedToProgress from '../../assets/management_Case_moved_to_progress.svg';
import iconCaseMovedInProgress from '../../assets/management_Case_moved_in_progress.svg';
import iconNoteAdded from '../../assets/management_note_added.svg';
import iconMeetingAdded from '../../assets/management_meeting_added.svg';
import iconMeetingDone from '../../assets/management_meeting_done.svg';
import iconDocumentAdded from '../../assets/management_document_added.svg';
import iconPhotoAdded from '../../assets/management_Photo_added.svg';
import useStyles from './styles';
import { TimelineDot } from '@material-ui/lab';

const renderTimelineIcon = (type: string) => {
  switch (type) {
    case TimelineEnum.CONTACT_EMAIL_SENT:
    case TimelineEnum.USER_EMAIL_SENT:
      return <img src={iconEmailSent} alt="icon" />;
    case TimelineEnum.CONTACT_EMAIL_RECEIVER:
    case TimelineEnum.USER_EMAIL_RECEIVER:
      return <img src={iconEmailReceived} alt="icon" />;
    case TimelineEnum.MASS_EMAILS:
      return <img src={iconMassEmails} alt="icon" />;
    case TimelineEnum.CASE_ADDED:
      return <img src={iconCaseAdded} alt="icon" />;
    case TimelineEnum.CASE_MOVED_IN_PROCESS:
      return <img src={iconCaseMovedInProgress} alt="icon" />;
    case TimelineEnum.CASE_MOVED_TO_PROCESS:
      return <img src={iconCaseMovedToProgress} alt="icon" />;
    case TimelineEnum.NOTE_ADDED:
      return <img src={iconNoteAdded} alt="icon" />;
    case TimelineEnum.MEETING_ADDED:
      return <img src={iconMeetingAdded} alt="icon" />;
    case TimelineEnum.MEETING_DONE:
      return <img src={iconMeetingDone} alt="icon" />;
    case TimelineEnum.USER_DOCUMENT_ADDED:
    case TimelineEnum.CUSTOMER_DOCUMENT_ADDED:
      return <img src={iconDocumentAdded} alt="icon" />;
    case TimelineEnum.MERGE_TICKET:
    case TimelineEnum.MERGE_CASE:
      return <img src={iconMerge} alt="icon" />;
    case TimelineEnum.TICKET_ADDED:
      return <img src={iconCaseAdded} alt="icon" />;
    case TimelineEnum.USER_PHOTO_ADDED:
    case TimelineEnum.CUSTOMER_PHOTO_ADDED:
      return <img src={iconPhotoAdded} alt="icon" />;
    default:
      return <img src={iconEmailSent} alt="icon" />;
  }
};

export default function CustomizedTimeline({
  timeline,
}: {
  timeline: TimeLine[];
}) {
  const classes = useStyles();

  return (
    <Timeline align="left" style={{ padding: 0 }}>
      {timeline.map((t) => {
        return (
          <TimelineItem key={t.id} className={classes.timeline}>
            <TimelineDot style={{ backgroundColor: 'white', margin: 0 }}>
              {renderTimelineIcon(t.action)}
            </TimelineDot>
            <TimelineSeparator />
            <TimelineContent className={classes.content}>
              <Typography className={classes.typography}>{t.title}</Typography>
              <Typography className={classes.subtitle}>
                {new Date(t.dateTime).toLocaleString()}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}
