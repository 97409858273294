import React from 'react';
import { Box } from '@material-ui/core';
import ListCase from './ListCase';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCaseOpenFormState,
  getCaseOpenDetailState,
} from '../../store/selectors';
import {
  openAddForm,
  closeAddForm,
  openDetail,
} from '../../store/actions/caseOpenAction';
import useStyles from './styles';

const Home = () => {
  const dispatch = useDispatch();
  const shouldAddForm = useSelector(getCaseOpenFormState);
  const shouldOpenDetail = useSelector(getCaseOpenDetailState);
  const classes = useStyles();
  return (
    <Box>
      <ListCase
        shouldAdd={shouldAddForm}
        setShouldAdd={() => dispatch(openAddForm())}
        shouldDetail={shouldOpenDetail}
        setOpenDetail={() => dispatch(openDetail())}
        closeShouldAdd={() => dispatch(closeAddForm())}
      />
    </Box>
  );
};

export default Home;
