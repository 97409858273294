import { CategoryActionTypeEnum } from 'enums/actions';
import categorySvc from 'services/category';
import { ApiError } from 'types/api';
import { FaqCategory } from './../../types/category';
import { DispatchType } from './../../types/store';
import { asyncTaskStartAction, asyncTaskStopAction } from './asyncTaskActions';
import { updateAppStateAction } from './appActions';

export const getCategoriesSuccessAction = (payload: FaqCategory[]) => {
  return {
    type: CategoryActionTypeEnum.GET_ALL_CATEGORIES,
    payload,
  };
};

export const getCategories = (pageUrl: string) => {
  const taskId = CategoryActionTypeEnum.GET_ALL_CATEGORIES;
  return async (dispatch: DispatchType) => {
    try {
      dispatch(asyncTaskStartAction(taskId));
      const response = await categorySvc.GetCategories({ pageUrl });
      if (response.success) {
        dispatch(getCategoriesSuccessAction(response.data));
        dispatch(
          updateAppStateAction({
            notFound: false,
          }),
        );
      } else {
        dispatch(
          updateAppStateAction({
            notFound: true,
          }),
        );
      }
      dispatch(asyncTaskStopAction(taskId));
    } catch (error) {
      dispatch(
        updateAppStateAction({
          notFound: true,
        }),
      );
      dispatch(asyncTaskStopAction(taskId, error as ApiError));
    }
  };
};
