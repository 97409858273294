import { StylesProvider } from '@material-ui/core/styles';
import CustomizedSnackbars from 'components/Snackbar';
import { BrowserRouter } from 'react-router-dom';
import AppComponent from './AppComponent';
import './index.css';
import React from 'react';

const App = () => {
  return (
    <BrowserRouter>
      <StylesProvider injectFirst>
        <AppComponent />
      </StylesProvider>
      <CustomizedSnackbars />
    </BrowserRouter>
  );
};

export default App;
