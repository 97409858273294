import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  panel: {
    padding: 24,
    backgroundColor: '#fafbfc',
  },
  header: {
    position: 'sticky',
    display: 'flex',
    top: 0,
    background: 'white',
    zIndex: 20,
    fontSize: 13,
    padding: '30px 32px 13px 32px',
    borderBottom: '1px solid #f1f1f1',
  },
  form: {
    padding: 16,
    overflowY: 'auto',
    border: '1px solid #f1f1f1',
    borderRadius: 10,
    backgroundColor: 'white',
  },
  addCaseContainer: {},
}));

export default styles;
