import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface IProps {
  className?: string;
  onClick?: (e?: any) => void;
  Icon: React.ReactNode;
  disabled?: boolean;
}

const IconButton = ({ className, onClick, disabled, Icon }: IProps) => {
  const classes = useStyles();
  return (
    <Button
      className={clsx(classes.root, className)}
      onClick={onClick}
      disableRipple
      disableFocusRipple
      disabled={disabled}
      classes={{
        disabled: classes.disabled,
      }}
    >
      {Icon}
    </Button>
  );
};

export default IconButton;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.pureWhite,
    borderRadius: '50%',
    minWidth: 0,
    padding: 6,
    color: theme.colors.gray,
    '&:hover': {
      backgroundColor: theme.colors.gray1,
      color: theme.colors.black1,
    },
  },
  disabled: {
    opacity: 0.5,
  },
}));
