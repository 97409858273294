import React from 'react';
import { clientRoutesEnum } from 'enums/routes';
import AppLayout from 'layouts/AppLayout';
import SignInPage from 'pages/SignIn';
import SignUpPage from 'pages/SignUp';
import { Route, Switch } from 'react-router-dom';
import FaqPage from 'pages/FaqPage';
import SubmitTicket from 'pages/SubmitTicket/SubmitTicket';
import NotFound from 'pages/NotFound';

const AuthRoutes = () => {
  return (
    <Switch>
      <AppLayout
        exact
        path={clientRoutesEnum.SIGN_IN}
        RenderComponent={SignInPage}
      />
      <AppLayout
        exact
        path={clientRoutesEnum.SIGN_UP}
        RenderComponent={SignUpPage}
      />
      <AppLayout
        exact
        path={clientRoutesEnum.SUBMIT_TICKET}
        RenderComponent={SubmitTicket}
      />
      <AppLayout exact path={clientRoutesEnum.FAQ} RenderComponent={FaqPage} />
      <AppLayout
        exact
        path={clientRoutesEnum.CATEGORY_DETAIL}
        RenderComponent={FaqPage}
      />
      <AppLayout
        exact
        path={'/:enterprise/faq/:id/:articleId'}
        RenderComponent={FaqPage}
      />
      <Route exact path={clientRoutesEnum.HOME} render={() => <></>} />
      <Route exact path="*" render={NotFound} />
    </Switch>
  );
};

export default AuthRoutes;
