import React, { useEffect, useMemo, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import IconButton from 'components/IconButton';
import { FAQ } from 'types/faq';
import dayjs from 'dayjs';
import BoldCloseIcon from 'icons/BoldCloseIcon';
import parse from 'html-react-parser';
import { Box } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { DispatchType } from 'types/store';
import { useDispatch, useSelector } from 'react-redux';
import { getArticlesDetail } from 'store/actions/faqActions';
import { getArticleDetail } from 'store/selectors';
import LoadingComponent from 'components/LoadingComponent';

interface ItemComponentProps {
  data?: FAQ;
  handleClick?: () => void;
}

const Item = () => {
  const classes = styles();
  const article = useSelector(getArticleDetail);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const dispatch: DispatchType = useDispatch();
  const { articleId, id, enterprise } = useParams<any>();

  useEffect(() => {
    setLoading(true);
    dispatch(getArticlesDetail(articleId))
      .then(() => setLoading(false))
      .catch((error) => {
        setLoading(false);
        throw new Error('Cannot fetch detail information for this article');
      });
  }, []);

  const handleClose = () => {
    history.push(`/${enterprise}/faq/${id}`);
  };

  const createdDate = useMemo(() => {
    return dayjs(article?.createdDate).format('MMM DD , YYYY');
  }, [article?.createdDate]);

  return (
    <Card elevation={0} className={classes.root}>
      <IconButton
        className={classes.closeButton}
        onClick={handleClose}
        Icon={<BoldCloseIcon />}
      />
      {loading ? (
        <LoadingComponent />
      ) : (
        <CardContent className={classes.main}>
          <CardMedia
            component="img"
            alt="Article Image"
            height="320px"
            image={article?.imageId || '/square-image.svg'}
            title="Article Image"
            className={classes.img}
          />
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{
              fontWeight: 700,
              marginTop: 15,
              fontSize: 24,
              marginBottom: 0,
            }}
          >
            {article?.title}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="h2"
            style={{
              fontSize: 10,
              fontWeight: 300,
              color: '#798B9B',
              margin: '15px 0px',
            }}
          >
            {createdDate}
          </Typography>
          <Box style={{ marginBottom: 43, fontSize: 13 }}>
            {parse(article?.content || '')}
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default Item;
