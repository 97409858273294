import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  buttonDisplay: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
  },
  cancelButton: {
    width: 48,
    height: 35,
    fontSize: 12,
    marginRight: 8,
    color: `${theme.colors.whiteText}`,
    backgroundColor: `${theme.colors.pureWhite}`,
    border: `1px solid ${theme.colors.whiteText}`,
    '&:hover': {
      color: `${theme.colors.whiteText}`,
      backgroundColor: `${theme.colors.white1}`,
    },
  },
  confirmButton: {
    width: 48,
    height: 35,
    fontSize: 12,
    '&:hover': {
      backgroundColor: `${theme.colors.primaryHover}`,
    },
  },
}));

export default styles;
