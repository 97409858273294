import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid #f1f1f1',
    borderRadius: 10,
    margin: '12px 12px',
    height: 'calc(100vh - 70px)',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      border: 'none',
      height: 'calc(100vh - 100px)',
      overflow: 'auto',
      borderRadius: 0,
    },
  },
}));

export default useStyles;
