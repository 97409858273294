import React from 'react';
import { styles } from './styles';
import TabLink from 'components/TabLink';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

export default function Header() {
  const classes = styles();
  const { enterprise } = useParams<any>();

  return (
    <div className={classes.header}>
      <div className={classes.navlink_div}>
        <TabLink
          to={`/${enterprise ? `${enterprise}/` : ''}sign-in`}
          label="Login"
          className={classes.textButton}
        />
      </div>
      <div className={classes.navlink_div}>
        <TabLink
          to={`/${enterprise ? `${enterprise}/` : ''}sign-up`}
          label="Sign up"
          className={clsx(classes.textButton, 'ml3')}
        />
      </div>
    </div>
  );
}
