import {
  CaseOpenActionType,
  CaseStatusReducerState,
} from './../../types/caseOpen';
import { CaseOpenTypeEnum } from '../../enums/actions';

export const initialCaseOpenState: CaseStatusReducerState = {
  shouldOpenDetail: false,
  shouldAddForm: false,
};

export const caseOpenReducer = (
  state = initialCaseOpenState,
  action: CaseOpenActionType,
) => {
  switch (action.type) {
    case CaseOpenTypeEnum.OPEN_ADD_FORM:
      return {
        ...state,
        shouldAddForm: true,
        shouldOpenDetail: false,
      };
    case CaseOpenTypeEnum.CLOSE_ADD_FORM:
      return {
        ...state,
        shouldAddForm: false,
      };
    case CaseOpenTypeEnum.OPEN_DETAIL:
      return {
        ...state,
        shouldOpenDetail: true,
        shouldAddForm: false,
      };
    case CaseOpenTypeEnum.CLOSE_DETAIL:
      return {
        ...state,
        shouldOpenDetail: false,
      };
    default:
      return state;
  }
};
