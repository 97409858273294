import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { FAQ } from 'types/faq';
import dayjs from 'dayjs';
import { stripHtml } from 'utils';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '14px 18px',
    borderRadius: 10,
  },
  img: {
    width: '100%',
    aspectRatio: '1/1',
    objectFit: 'cover',
    borderRadius: 10,
  },
  card: {
    border: '1px solid #f1f1f1',
    borderRadius: 10,
  },
});

interface ImgMediaCardProps {
  data: FAQ;
}

export default function ImgMediaCard({ data }: ImgMediaCardProps) {
  const classes = useStyles();

  const createdDate = useMemo(() => {
    return dayjs(data.createdDate).format('MMM DD , YYYY');
  }, [data.createdDate]);

  return (
    <Card elevation={0} className={classes.card}>
      <CardActionArea className={classes.root}>
        <CardMedia
          component="img"
          alt="Article Image"
          height="140"
          image={data?.imageId || '/square-image.svg'}
          title="Article Image"
          className={classes.img}
        />
        <CardContent style={{ padding: 0, marginTop: 12 }}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ fontWeight: 700, fontSize: 14 }}
          >
            {data.title}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="h2"
            style={{ color: '#798B9B', fontSize: 10, fontWeight: 300 }}
          >
            {createdDate}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            style={{
              color: '#798B9B',
              fontSize: 10,
              marginTop: 14,
              width: '100%',
            }}
            className="ellipsis"
          >
            {stripHtml(data.content || '')}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
